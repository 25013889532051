<template>
  <div @click="goPost" class="article-element">
    <div class="article-right">
      <h3 class="under">{{ item.title }}</h3>
      <span>{{ item.content.split(' ').slice(0, 10).join(' ') }}</span>
      <div class="f sb" style="padding-top: 1%">
        <div class="f g15">
          <div class="f align-center">
            <img class="small-icon" src="../../assets/images/tickets.png" />
            <div style="width: 5px"></div>
            <span>{{ item.net_votes }}</span>
          </div>
          <div class="f align-center">
            <img class="small-icon" src="../../assets/images/message.png" />
            <div style="width: 5px"></div>
            <span>{{ item.comment_count }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visibleMovieCount: null,
      filmBoxWidth: 220,
      showedCount: 0,
    }
  },
  methods: {
    getImageUrl(imageUrl) {
      const startIndex = imageUrl.indexOf('@.')
      let str = imageUrl.slice(0, startIndex + 1)
      str += '.jpg'
      return str
    },
    getDate(time) {
      const currentTime = moment()
      const differenceInDays = currentTime.diff(time, 'days')
      return differenceInDays
    },
    goPost() {
      this.$emit('go-post', this.item);
    }
  },
  mounted() { },
  created() { }
}
</script>

<style scoped>
.article-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.article-right {
  padding: 2%;
}

.small-icon {
  width: 28px;
}

.article-element {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
  max-width: 450px;
  background-color: #232323;
  border: 1px solid rgb(77, 76, 76);
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  padding: 3%;
  transition: 0.3s;
  cursor: pointer;
}

.article-element:hover {
  border: 1px #f8b632 solid;
}

.small-font-white {
  font-size: 14px;
  color: white;
}

@media screen and (max-width: 920px) {
  .article-element {
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
    background-color: #232323;
    border: none;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    padding: 0.3rem 0.1rem;
    transition: 0.3s;
    cursor: pointer;
    max-width: 100%;
  }

  .article-element:hover {
    border: none
  }
}
</style>

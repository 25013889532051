<template>
    <div class="featured_slider">
        <div class="slider_button_left">
            <VueIcon @click="prevSlide" style="color:rgb(237, 190, 50);" icon="mingcute:left-line" height="32"
                width="32"></VueIcon>
        </div>
        <div class="slides">
            <div v-for="(item, index) in items" :key="index" class="featured-movie"
                :class="{ 'featured-movie': true, 'next-slide': index === currentSlide + 1, 'prev-slide': index === currentSlide - 1 }"
                :style="{ 'background-image': `${getImageUrl(item.poster)}`, transform: 'translateX(' + currentSlide * -102 + '%)' }">
                <div class="row">
                    <div :key="currentSlide" class="col col1">
                        <h1>{{ item.title }}</h1>
                        <span class="overview">
                            {{ item.description }}
                        </span>
                        <div class="info_bar">
                            <span class="mid-bold">{{ getFormattedYear(item.released) }}</span>
                            <div class="f align-center">
                                <img class="info-icon" src="../../assets/images/imdb.png" />
                                <div style="width: 5px"></div>
                                <span class="overview">{{ item.imdbrating }}</span>
                            </div>
                            <div class="f align-center">
                                <img class="info-icon" src="../../assets/images/tickets.png" />
                                <div style="width: 5px"></div>
                                <span class="overview">{{ item._count.like }}</span>
                            </div>
                            <div class="f align-center">
                                <img class="info-icon" src="../../assets/images/message.png" />
                                <div style="width: 5px"></div>
                                <span class="overview">{{ item._count.comment }}</span>
                            </div>
                        </div>
                        <div class="f">
                            <button @click="toggleTrailer(item.trailer_id)" class="trailer_btn"
                                style="margin-top: 6px;">
                                <span class="mid-bold">Fragmanı İzle</span>
                                <img class="info-icon" src="../../assets/images/play.png" />
                            </button>
                        </div>

                    </div>
                    <div v-if="false" class="trailer_div">
                        <YoutubeWrapper :iframeWidth="'100%'" videoId="TNEkwcY7OI0" />
                    </div>
                </div>
            </div>
        </div>
        <div class="slider_button_right">
            <VueIcon @click="nextSlide" style="color:rgb(237, 190, 50);" icon="mingcute:right-line" height="32"
                width="32"></VueIcon>
        </div>
    </div>
</template>

<script>
import avatar from '../../assets/images/ippo.jpg';
import YoutubeWrapper from './YoutubeWrapper.vue';
import ImageUtil from '../../utils/ImageUtil.js';
import { getFormattedYear } from '../../utils/DateUtils.js';

export default {
    data() {
        return {
            currentSlide: 0,
            avat: avatar,
            showTrailer: false,
            getFormattedYear
        };
    },
    props: {
        items: {
            type: Object,
            required: true
        },
    },
    methods: {
        getImageUrl(imageUrl) {
            const str = ImageUtil.getImage(imageUrl, 'full');
            const bg = `linear-gradient(to right, rgba(0, 0, 0, 0.9) 15%, rgba(0, 0, 0, 0) 100%), url(${str})`
            return bg;
        },
        async setFeatured() {
            const delay = 10000
            setInterval(() => {
                this.nextSlide();
            }, delay)
        },
        nextSlide() {

            const totalSlides = this.items.length;

            if (this.currentSlide < totalSlides - 1) {
                this.currentSlide += 1;
            } else {
                this.currentSlide = 0;
            }
            this.$emit('setSlide', this.currentSlide);
        },
        prevSlide() {

            const totalSlides = this.items.length;

            if (this.currentSlide === 0) {
                this.currentSlide = totalSlides - 1;
            } else {
                this.currentSlide -= 1;
            }
            this.$emit('setSlide', this.currentSlide);
        },
        toggleTrailer(youtubeID) {
            window.open(`https://www.youtube.com/watch?v=${youtubeID}`, '_blank');
        }
    },
    created() {
        this.setFeatured()
    },
    components: { YoutubeWrapper }
};
</script>

<style scoped>
.row {
    display: flex;
}

.trailer_div {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25%;
    width: 50%;
    height: 60%;
    gap: 5px;
    padding-left: 0%;
    padding-top: 0%;
    padding-right: 0%;
    background-color: red;
}

.trailer_btn img {
    width: 30px;
    height: 30px;
}

h1 {
    color: white;
    font-size: 1.6em;
    font-weight: 800;
    margin-bottom: 0px;
    text-shadow: 2px 2px 2px black;
}

.overview {
    font-size: 14px;
}

.trailer_btn {
    background-color: #f8b632;
    height: 40px;
    max-width: 180px;
    width: 160px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    transition: 0.4s;
    cursor: pointer;
    border: 1px solid white;
}

.trailer_btn:hover {
    background-color: rgb(255, 171, 3);
}

.featured_slider {
    display: flex;
    position: relative;
    justify-content: center;
    overflow: hidden;
    height: 100%;
    min-height: 50vh;
    width: 100%;
}

.slides {
    display: grid;
    min-height: 40vh;
    height: 100%;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    grid-gap: 0 2%;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    overflow-x: hidden;
    align-items: center;
    justify-items: center;
}


.featured_slider .slider_button_left {
    cursor: pointer;
    position: absolute;
    left: 8px;
    top: 40%;
    height: 45px;
    width: 45px;
    background-color: rgba(56, 55, 55, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    transition: 0.1s;
}

.featured_slider .slider_button_right {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 40%;
    height: 45px;
    width: 45px;
    background-color: rgba(56, 55, 55, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    transition: 0.1s;
    display: flex;
}

.slider_button_right:hover {
    filter: drop-shadow(0px 0px 10px #5a5959);
    transition: 0.2s;
}

.slider_button_left:hover {
    filter: drop-shadow(0px 0px 10px #5a5959);
    transition: 0.2s;
}

.nav_arrow {
    background-color: red;
    outline: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: transparent;
    border-top: 4px #f8b632 solid;
    border-left: 4px #f8b632 solid;
    border-bottom: none;
    border-right: none;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.next-slide {
    opacity: 0.4;
    height: 100px;
}

.prev-slide {
    opacity: 0.4;
    height: 100px;
}

.featured-movie {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    border-radius: 0px;
    scroll-snap-align: start;
    transition: 0.8s;
}

.col1 {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 5px;
    padding-left: 10%;
    padding-top: 10%;
    padding-right: 2%;
    text-align: left;
    animation: col-title-animation 1s ease;
}

h2 {
    font-size: 2em;
    margin-bottom: 10px;
}

p {
    font-size: 1.2em;
}

.action_btn {
    display: grid;
    align-items: center;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: none;
}

.tooltip {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    animation: 0.5s ease-in-out;
}

.action_btn .tooltiptext {
    visibility: hidden;
    background-color: white;
    box-shadow: 0px 1px 1px 0px rgb(145, 144, 144);
    color: black;
    text-align: center;
    font-size: 1.8vh;
    border-radius: 6px;
    height: 22px;
    padding-top: 3px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    position: absolute;
    z-index: 5;
}

.action_btn .tooltiptext {
    visibility: visible;
    animation: 0.5s ease-in-out;
}

.list_icon {
    width: 40px;
    height: 40px;
}


@keyframes col-title-animation {
    from {
        transform: translateY(-500px);
    }

    to {
        transform: translateY(-0px);
    }
}
</style>
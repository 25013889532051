<template>
    <NavBar />
    <div class="wrapper">
        <div class="top_margin_div"></div>
        <div class="main_container">
            <FeaturedCommunity :item="featuredCommunity" @goLink="goToLink" />
            <div style="margin-top: 20px;" class="top_slider_wrapper">
                <span class="category-font">Top 10</span>
                <CommunityBar v-if="!topCommLoading" @goLink="goToLink" :items="topCommunities" />
            </div>
            <div class="main_middle">
                <div class="main_middle_left">
                    <span class="category-font">{{ texts.activity }}</span>
                    <CommunityPosts @goLink="goToPost" @filter="setFilter" :posts="posts" />
                </div>
                <div class="main_middle_right">
                    <span class="category-font">{{ texts.leaders }}</span>
                    <TopUsers :loading="leaderLoading" @filter-leaders="filterLeaders" :tabs="tabs" :items="leaders" />
                </div>
            </div>
            <div class="main_middle">
                <SearchCommunity @go="go" @action="showCreateCommunity" />
            </div>
            <div class="main_middle">
                <NewPost @action="showCreateCommunity" />
            </div>
        </div>
    </div>
    <CreateCommunity :loading="featuredLoading" @validate-title="checkCommunityName" @submit="createCommunity"
        :modalOpen="showCommunityModal" v-if="showCommunityModal" @close="closeCommunityModal" />
    <InfoModal :duration="'4s'" :type="infoType" v-if="showInfoModal" :message="infoMessage"
        @close="showInfoModal = false" />
    <FooterBar />
</template>

<script>
import { defineAsyncComponent } from 'vue'

import FeaturedCommunity from '../components/Community/FeaturedCommunity.vue';
import TopUsers from '../components/Community/TopUsers.vue';
import CommunityPosts from '../components/Community/CommunityPosts.vue'
import CommunityBar from '../components/Community/CommunityBar.vue';
import CreateCommunity from '../components/Community/CreateCommunity.vue';
import InfoModal from '../components/shared/MessageModal.vue';
import SearchCommunity from '../components/Community/SearchCommunity.vue';

const NewPost = defineAsyncComponent(() =>
    import('../components/Community/NewPost.vue')
)

import ippo from '../assets/images/ippo.jpg';
import avat from '../assets/images/avatar.png';
import anime from '../assets/images/anime.jpg';

import CommunityService from '../services/CommunityService';
import PostService from '../services/PostService';

import { useUserStore } from '../store/user.js'


export default {
    data() {
        return {
            texts: {
                activity: 'Topluluk Aktiviteleri',
                leaders: 'Liderlik Tablosu'
            },
            featuredLoading: false,
            postsLoading: false,
            topCommLoading: true,
            showInfoModal: false,
            leaderLoading: true,
            infoType: 'error',
            infoMessage: 'Please, try again later',
            errorDuration: 4000,
            communityBackgroundImage: anime,
            postFilters: {
                orderBy: 'latest',
                orderDir: 'desc',
                postLimit: 5,
            },
            communityFilters: {
                orderBy: 'rank',
                orderDir: 'desc'
            },
            leaderFilters: {
                limit: 5,
                range: 'weekly'
            },
            topCommunities: [],
            featuredCommunities: [],
            posts: [],
            moderators: [
                { name: 'numan', uid: 1, url: avat },
                { name: 'ippo', uid: 2, url: ippo },
            ],
            leaders: [],
            featuredCommunity: {
                members: 10,
                comment_count: 20,
            },
            tabs: [
                { name: 'Weekly', langTR: 'Haftalık', id: 1, code: 'weekly' },
                { name: 'Monthly', langTR: 'Aylık', id: 2, code: 'monthly' },
                { name: 'All', langTR: 'Yıllık', id: 3, code: 'all' },
            ],
            showCommunityModal: false,
            isNameValid: false,
            user: {}
        }
    },
    methods: {
        async setFeatured() {
            const delay = 15000
            setInterval(() => {
                const randomIndex = Math.floor(Math.random() * this.featuredCommunities.length)
                this.featuredCommunity = this.featuredCommunities[randomIndex];
                this.featuredCommunity.img = ippo;
            }, delay)
        },
        async setFilter(filter) {
            this.postFilters.orderBy = filter;
            return await this.getPosts();
        },
        async filterLeaders(filter) {
            this.leaderFilters.range = filter;
            return await this.getLeaders();
        },
        async getPosts() {
            this.postsLoading = true;
            const response = await PostService.getCommunityPosts({
                limit: this.postFilters.postLimit,
                order_by: this.postFilters.orderBy,
                order_dir: this.postFilters.orderDir
            })
            if (!response) return
            this.posts = response;
            this.postsLoading = false;
        },
        async getFeaturedCommunities() {
            this.featuredLoading = true;
            const response = await CommunityService.getAll({
                limit: 5,
                order_by: '',
                order_dir: this.communityFilters.orderDir
            })
            if (!response) return this.featuredLoading = false;
            this.featuredCommunities = response;
            this.featuredCommunity = this.featuredCommunities[0];
            this.featuredLoading = false;
        },
        async getTopCommunities() {
            this.topCommLoading = true;
            const response = await CommunityService.getAll({
                limit: 10,
                order_by: this.communityFilters.orderBy,
                order_dir: this.communityFilters.orderDir
            })
            if (!response) return
            this.topCommunities = response;
            this.topCommLoading = false;
        },
        async getLeaders() {
            this.leaderLoading = true;

            const response = await CommunityService.overAllActiveUsers({
                limit: this.leaderFilters.limit,
                range: this.leaderFilters.range
            });

            if (!response) return this.leaders = [];
            this.leaders = response;
            this.leaderLoading = false;
        },
        showCreateCommunity() {
            document.querySelector("body").style.overflowY = 'hidden';
            this.showCommunityModal = true;
        },
        closeCommunityModal() {
            this.showCommunityModal = false;
            document.querySelector("body").style.overflowY = 'auto';
        },
        async checkCommunityName(name) {

            const response = await CommunityService.checkCommmunityName({ name: name });

            if (!response) { this.isNameValid = false; return this.openInfoModal('Try again later.', 'error') };
            if (!response.valid) { this.isNameValid = false; return this.openInfoModal(response.message, 'error') };
            this.isNameValid = true;
            return this.openInfoModal(response.message, 'info');
        },
        async createCommunity(communityObject) {
            if (!communityObject) return this.openInfoModal('Fill in the required fields.', 'error');
            if (!this.isNameValid) return this.openInfoModal('Community name already taken!', 'error');

            this.featuredLoading = true;
            communityObject.user_id = this.user.user_id;

            const response = await CommunityService.createOneCommunity(communityObject);

            this.featuredLoading = false;
            if (!response) return this.openInfoModal('Try again later!', 'error');
            if (!response.community_id) return this.openInfoModal('Try again later!', 'error');

            this.featuredLoading = false;
            return this.goToLink(response.community_id);
        },
        async openInfoModal(msg, type) {

            this.showInfoModal = true;
            this.infoMessage = msg;
            this.infoType = type;

            setTimeout(() => {
                this.showInfoModal = false;
            }, this.errorDuration);
        },
        go() {
            this.$router.push({ name: 'community-search' })
        },
        goToLink(communityID) {
            return this.$router.push({ name: 'community-detail', params: { id: communityID } })
        },
        goToPost(postID, communityID) {
            return this.$router.push({ name: 'post', params: { communityId: communityID, postId: postID } })
        },
    },
    async created() {
        this.user = useUserStore().user;
        await this.getFeaturedCommunities();
        this.setFeatured();
        await this.getPosts();
        await this.getTopCommunities();
        await this.getLeaders();
    },
    components: {
        FeaturedCommunity,
        TopUsers,
        CommunityPosts,
        CommunityBar,
        NewPost,
        CreateCommunity,
        InfoModal,
        SearchCommunity
    }
}
</script>

<style scoped>
.category-font {
    padding-left: 0px;
    font-size: 28px;
}

.top_margin_div{
        height: 100px;
    }

.main_middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 2%;
    margin-top: 10px;
}

.top_slider_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    min-height: 230px;
}

.main_middle_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    gap: 10px;
}

.main_middle_right {
    display: flex;
    flex-direction: column;
    width: 25%;
    max-height: 420px;
    gap: 10px;
}

.wrapper {
    background-color: #030303;
    z-index: 3;
}

.main_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 2%;
}

@media screen and (max-width: 676px) {
    .main_middle {
        flex-direction: column;
        gap: 10px;
        margin-top: 5px;
    }

    .main_middle_left {
        width: 100%;
        gap: 0px;
    }

    .main_middle_right {
        width: 100%;
        gap: 0px;
    }

    .category-font {
        padding-left: 0px;
        font-size: 24px;
    }

    .top_margin_div{
        height: 1vh;
    }

}
</style>
import { apiInstance } from '../services/ApiService.js'

const API_URL = '/user'

const getUserCommunities = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + '/',
            {
                user_id: req.user_id,
            }
        );
        if (!response.data.success) return null
        return response.data.communities;
    } catch (error) {
        return null
    }
}

const getUserDetails = async () => {

    try {
        const response = await apiInstance.get(API_URL + '/detail', {});
        if (!response.data.success) return null;
        return response.data.user;
    } catch (error) {
        console.log(error)
        return null
    }
}

const getUserDeepDetails = async () => {

    try {
        const response = await apiInstance.get(API_URL + '/details', {});
        if (!response.data.success) return null;
        return response.data.user;
    } catch (error) {
        return null
    }
}


const setBiography = async (req) => {

    try {
        const response = await apiInstance.post(API_URL + '/bio', {
            text: req.text
        });

        if (!response.data) return false;

        return response.data.success;
    } catch (error) {
        return null
    }
}

const updateUser = async (req) => {

    try {
        const response = await apiInstance.put(API_URL + '/', {
            username: req.username,
            email: req.email,
            birthdate: req.birthdate,
        });

        if (!response.data) return false;

        return response.data.success;
    } catch (error) {
        return null
    }
}

const UserService = {
    getUserCommunities,
    getUserDetails,
    setBiography,
    getUserDeepDetails,
    updateUser
}

export default UserService;

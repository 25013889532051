<template>
  <NavBar />
  <div class="wrapper">
    <div v-if="!isMobile" class="stick_filter_bar">
      <h2>{{header}}</h2>
      <Search :placeholder="'Ara'" @searchMovie="searchMovie" />
      <div>
        <DropDown :refFunction="filterList" :options="filterOptions" :selectedItem="selectedOption" />
      </div>
    </div>
    <div v-if="isMobile" class="stick_filter_bar">
      <h4 style="margin-bottom: 5px;">{{header}}</h4>
      <div style="width: 100%; margin-bottom: 5px;">
        <Search :placeholder="'Ara'" @searchMovie="searchMovie" />
      </div>
      <div style="margin-top: 10px;">
        <DropDown :refFunction="filterList" :options="filterOptions" :selectedItem="selectedOption" />
      </div>
    </div>
    <div v-if="!user">
      <LoginTemplate :action="goLogin" :subMessage="'Login'" :message="loginMessage" />
    </div>
    <div class="container" v-if="listLoading">
      <ShimmerCard v-for="(index) in 25" :key="index" />
    </div>
    <div class="container" v-else>
      <MovieCard @goMovie="goMovieDetails" @deleteMovie="removeMovie" :item="item.movie" v-for="(item, index) in list"
        :key="index" />
    </div>
  </div>
  <FooterBar />
</template>

<script>
import avatar from '../assets/images/avatar.png'
import MovieCard from '../components/Watchlist/MovieCard.vue';
import ShimmerCard from '../components/Shimmer/MovieCard.vue';

import DropDown from '../components/Buttons/SelectorDropdown.vue';
import Search from '../components/Watchlist/SearchBar.vue';
import ListService from '../services/WatchlistService.js';
import router from '../router/index.js'
import { useUserStore } from '../store/user.js'

export default {
  data() {
    return {
      header: 'İzleme Listem',
      loginMessage: 'Please login to create or show your watchlist.',
      searchTimeout: null,
      avatar: avatar,
      list: [],
      listLoading: false,
      sortObject: {
        orderBy: 'imdb',
        order_dir: 'desc',
        limit: 50,
      },
      search: '',
      windowWidth: 1280,
      isMobile: false,
      filterOptions: [
        { id: 0, option: 'Most Recent', code: 'most_recent' },
        { id: 1, option: 'Release Date', code: 'release' },
        { id: 2, option: 'IMDB', code: 'imdb' },
      ],
      selectedOption: 'IMDB',
      user: {},
    }
  },
  methods: {
    async getListBySearchQuery() {
      if (!this.user) return;
      this.listLoading = true
      const response = await ListService.searchByQuery({
        limit: this.sortObject.limit,
        order_by: this.sortObject.orderBy,
        order_dir: this.sortObject.order_dir,
        search: this.search,
        user_id: this.user.user_id
      })
      if (!response) return
      this.list = response
      this.listLoading = false
    },
    filterList(select) {
      this.selectedOption = select.option;
      this.sortObject.orderBy = select.code;
      this.setURL();
      return this.getListBySearchQuery();
    },
    async searchMovie(searchTerm) {
      this.search = searchTerm;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getListBySearchQuery();
        this.setURL();
      }, 1000);
    },
    async removeMovie(movieID) {

      if (!movieID) return;
      const response = await ListService.deleteMovie({
        user_id: this.user.user_id,
        movie_id: movieID
      });

      if (!response) return

      const indexToRemove = this.list.findIndex(item => item.movie_id === movieID);

      if (indexToRemove !== -1) return this.list.splice(indexToRemove, 1);
    },
    goMovieDetails(movieId) {
      if (movieId == 0) return
      const id = movieId
      router.replace({ name: 'movie', params: { id } })
    },
    checkMobile() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 590) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setURL() {
      //this.$router.replace({ path: this.$route.path, query: { orderBy: this.orderBy, sort: this.order_dir }});
    },
    goLogin() {
      return this.$router.push({ name: 'login' });
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 590) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    })
  },
  async created() {
    this.user = useUserStore().user;
    await this.getListBySearchQuery();
    this.checkMobile();
    this.setURL();
  },
  components: { MovieCard, DropDown, Search, ShimmerCard }
}
</script>

<style scoped>

h2{
  color: white;
}

.top-title {
  font-size: 3.4vh;
}

.wrapper {
  padding-top: 7%;
  padding-left: 2%;
  padding-right: 2%;
  gap: 10px;
}

.stick_filter_bar {
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
  top: 12vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  margin-bottom: 30px;
  z-index: 1000;
  width: 100%;
}

.container {
  overflow-x: hidden;
  overflow-y: hidden;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  display: grid;
  min-height: 100vh;
  gap: 20px 20px;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 260px;
  justify-content: start;
}

.item {
  border: 1px white dashed;
  border-radius: 2px;
}

.container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.button_wrap {
  display: none;
}

.film-element:hover .button_wrap {
  top: 35%;
  left: 0%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15%;
  height: 60px;
  width: 100%;
}

@media (max-width: 1280px) {
  .container {
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 260px;
    justify-content: start;
  }
}

@media (max-width: 1050px) {
  .container {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 280px;
    justify-content: start;
  }
}

@media (max-width: 800px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 275px;
  }
}

@media (max-width: 600px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 260px;
  }
}

@media (max-width: 590px) {

  .wrapper {
  padding-top: 0%;
  padding-left: 2%;
  padding-right: 2%;
  gap: 10px;
}

  .container {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 260px;
  }

  .stick_filter_bar {
    display: block;
    flex-direction: column;
    position: static;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    margin-top: 10px;
    margin-bottom: 30px;
    z-index: 2;
  }

  .top-title {
    font-size: 3vh;
  }

  .container {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 260px;
    gap: 0px 20px;
  }

}
</style>
import { apiInstance } from './ApiService.js'

const API_URL = '/community';

const checkCommmunityName = async (req) => {

    try {

        const response = await apiInstance.post(API_URL + '/check', {
            name: req.name,
        });

        if (!response) return false;
        if (!response.data) return false;

        const data = response.data;

        if (data.code === 'COMMUNITY_EXISTS') return { valid: false, message: data.msg };
        if (data.code === 'ERROR') return { valid: false, message: data.msg };
        if (data.code === 'VALID') return { valid: true, message: data.msg };

    } catch (error) {
        return { valid: false, message: 'Try again later!' };;
    }
}

const createOneCommunity = async (req) => {

    try {
        const response = await apiInstance.post(API_URL + '/', {
            name: req.name,
            description: req.description,
            user_id: req.user_id
        });

        if (!response.data.success) return false;
        return response.data.meta_data;
    } catch (error) {
        return false;
    }
}

const reportCommunity = async (req) => {

    try {
        const response = await apiInstance.post(API_URL + '/report', {
            type: req.type,
            post_id: req.post_id,
            community_id: req.community_id,
            reason: req.reason
        });

        if (!response.data.success) return false;
        return response.data;
    } catch (error) {
        return false;
    }
}

const subscribeOneCommunity = async (req) => {

    try {
        const response = await apiInstance.post(API_URL + '/subscribe', {
            community_id: req.community_id,
            user_id: req.user_id
        });

        if (!response.data.success) return false;
        return response.data.community_id;
    } catch (error) {
        return false;
    }
}

const unsubscribeOneCommunity = async (req) => {

    try {
        const response = await apiInstance.put(API_URL + '/subscribe', {
            community_id: req.community_id,
            user_id: req.user_id
        });

        if (!response.data.success) return false;
        return response.data.community_id;
    } catch (error) {
        return false;
    }
}

const getOneCommunity = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/get', {
            params: {
                post_id: req.post_id,
                community_id: req.community_id
            }
        })
        if (!response.data.success) return {};
        return response.data.data
    } catch (error) {
        return {};
    }
}

const getMostActiveUsers = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + '/activeUsers', {
            community_id: req.community_id,
            limit: req.limit,
            range: req.range
        });
        if (!response.data.success) return {};
        return response.data.data
    } catch (error) {
        return {};
    }
}

const overAllActiveUsers = async (req) => {

    try {
        const response = await apiInstance.post(API_URL + '/overallActive', {

            limit: req.limit,
            range: req.range

        });
        if (!response.data.success) return [];
        return response.data.data
    } catch (error) {
        return [];
    }
}

const getUserAllCommunity = async (req) => {

    try {

        const response = await apiInstance.get(API_URL + '/userAll', {
            params: {
                limit: req.limit,
                order_by: req.order_by,
                order_dir: req.order_dir,
                user_id: req.user_id
            }
        });

        if (!response.data.success) return []
        return response.data;
    } catch (error) {
        return []
    }
}

const getAll = async (req) => {

    try {

        const response = await apiInstance.get(API_URL + '/getAll', {
            params: {
                limit: req.limit,
                order_by: req.order_by,
                order_dir: req.order_dir,
                q: req.search
            }
        })
        if (!response.data.success) return []
        return response.data.data;
    } catch (error) {
        return []
    }
}

const CommunityService = {
    getAll,
    getOneCommunity,
    getMostActiveUsers,
    checkCommmunityName,
    createOneCommunity,
    subscribeOneCommunity,
    unsubscribeOneCommunity,
    overAllActiveUsers,
    getUserAllCommunity,
    reportCommunity
}

export default CommunityService
<template>
    <NavBar />
    <div class="top_margin_div"></div>

    <div class="wrapper">
        <h2>Sana Özel Öneriler</h2>
        <div>
            <div class="f g5">
                <span class="category-font">{{ texts.new }}</span>
                <img class="category_icon" :src="icons.new" />
            </div>
            <div style="width: 100%; height: 250px; margin-top: 10px" v-if="interestGenreLoading">
                <ShimmerBar />
            </div>
            <div class="genre_bar_wrap" v-if="!interestGenreLoading && categoryItems.length > 0">
                <CategoryBar @go-routes="goMovieSearch" :items="categoryItems" />
            </div>
        </div>

        <div style="height: 30px"></div>

        <div>
            <div class="f g5">
                <span class="category-font">{{ texts.most_liked }}</span>
                <img class="category_icon" :src="icons.like" />
            </div>
            <div class="movie_bar_wrap" v-if="loading">
                <ShimmerBar />
            </div>
            <div class="movie_bar_wrap" v-if="!loading && mostLikedMovies.length > 0">
                <MovieBar :items="mostLikedMovies" />
            </div>
        </div>

        <div style="height: 10px"></div>

        <div>
            <div class="f g5">
                <span class="category-font">{{ texts.most_commented }}</span>
                <img class="category_icon" :src="icons.comment" />
            </div>
            <div style="width: 100%; height: 250px; margin-top: 10px" v-if="loading">
                <ShimmerBar />
            </div>
            <div class="genre_bar_wrap">
                <CategoryBar @go-routes="goMovieSearch" :items="interestedCategoryItems" />
            </div>
        </div>

        <div style="height: 30px"></div>

        <div>
            <div class="f g5">
                <span class="category-font">{{ texts.trend }}</span>
                <img class="category_icon" :src="icons.trend" />
            </div>
            <div class="movie_bar_wrap" v-if="loading">
                <ShimmerBar />
            </div>
            <div class="movie_bar_wrap" v-if="!loading && trendMovies.length > 0">
                <MovieBar :items="trendMovies" />
            </div>
        </div>

        <div style="height: 10px"></div>

        <div>
            <div class="f g5">
                <span class="category-font">{{ texts.for_you }}</span>
                <img class="category_icon" :src="icons.discover" />
            </div>
            <div class="movie_bar_wrap" v-if="recommendMovieLoading">
                <ShimmerBar />
            </div>
            <div class="movie_bar_wrap" v-if="!recommendMovieLoading && userRecommedMovies.length > 0">
                <RecommendBar :items="userRecommedMovies" />
            </div>
        </div>
    </div>
    <FooterBar />
</template>
<script>

import ShimmerBar from '../components/Shimmer/MovieBar.vue';
import MovieBar from '../components/HomeViewComponents/MovieBar.vue';
import RecommendBar from '../components/Recommendation/MovieBar.vue';

import CategoryBar from '../components/Recommendation/CategoryBar.vue';

import MovieService from '../services/MovieService.js'
import commentIcon from '../assets/images/message.png';
import trendIcon from '../assets/images/trending.png';
import discover from '../assets/images/discover.png';
import likeIcon from '../assets/images/like-heart.png';

import newIcon from '../assets/images/trending-topic.png';

import action from '../assets/genres/action.png';
import drama from '../assets/genres/drama.png';
import adventure from '../assets/genres/adventure.png';
import comedy from '../assets/genres/comedy.png';
import biography from '../assets/genres/biography.png';
import scifi from '../assets/genres/sci-fi.png';
import history from '../assets/genres/history.png';
import crime from '../assets/genres/gun.png';
import romance from '../assets/genres/romance.png';
import mystery from '../assets/genres/mystery.png';
import musical from '../assets/genres/musical.png';
import thriller from '../assets/genres/thriller.png';
import animation from '../assets/genres/animation.png';
import documentary from '../assets/genres/documentary.png';


export default {
    data() {
        return {
            categoryItems: [],
            interestedCategoryItems: [
                { name: 'Aksiyon', icon: action },
                { name: 'Animasyon', icon: animation },
                { name: 'Dram', icon: drama },
                { name: 'Macera', icon: adventure },
                { name: 'Komedi', icon: comedy },
                { name: 'Biyografi', icon: biography },
                { name: 'Bilim Kurgu', icon: scifi },
                { name: 'Tarih', icon: history },
                { name: 'Suç', icon: crime },
                { name: 'Romantik', icon: romance },
                { name: 'Gizem', icon: mystery },
                { name: 'Müzikal', icon: musical },
                { name: 'Gerilim', icon: thriller },
                { name: 'Belgesel', icon: documentary },
            ],
            interestGenres: [],
            interestGenreLoading: true,
            newMovies: [],
            topRateMovies: [],
            mostLikedMovies: [],
            mostCommentedMovies: [],
            trendMovies: [],
            userRecommedMovies: [],
            recommendMovieLoading: true,
            loading: false,
            topRatedLoading: true,
            newMoviesLoading: true,
            mostLikedCount: 15,
            isMobile: false,
            topRatedCount: 15,
            featuredIndex: 0,
            selectedMovie: null,
            isReadyFeatured: false,
            texts: {
                new: 'Sevdiğin Kategoriler',
                top_rated: 'Bunları Beğenebilirsin',
                most_liked: 'Bunları Beğenebilirsin',
                most_commented: 'Bu Kategorilere Bakabilirsin',
                trend: 'Trendler',
                for_you: 'Sana Özel Filmleri Keşfet'
            },
            icons: {
                comment: commentIcon,
                like: likeIcon,
                trend: trendIcon,
                new: newIcon,
                discover: discover
            },
        }
    },
    methods: {
        goMovieSearch() {
            this.$router.push({ name: 'movies' });
        },
        async setFeatured() {
            const delay = 15000;

            setInterval(() => {
                const randomIndex = Math.floor(Math.random() * this.topRatedCount)
                this.selectedMovie = this.topRateMovies[randomIndex]
            }, delay)
        },
        async getNewdMovies() {
            this.newMoviesLoading = true
            const response = await MovieService.getMovies({
                limit: this.topRatedCount,
                order_by: 'year',
                order_dir: 'desc'
            })
            if (!response) return this.newMoviesLoading = false;
            this.newMovies = response;
            this.newMoviesLoading = false;
        },
        async getTopRatedMovies() {
            this.topRatedLoading = true
            const response = await MovieService.getMovies({
                limit: this.topRatedCount,
                order_by: 'top_rated',
                order_dir: 'desc'
            })
            if (!response) return this.topRatedLoading = false;
            this.topRateMovies = response;
            this.topRatedLoading = false;
        },
        async getUserInteresMovie() {
            this.recommendMovieLoading = true
            const response = await MovieService.getUserInteresMovies()
            if (!response) return this.recommendMovieLoading = false;
            this.userRecommedMovies = response;
            this.recommendMovieLoading = false;
        },
        async getUserInterestGenres() {
            this.interestGenreLoading = true
            const response = await MovieService.getUserInterestCategories()
            if (!response) return this.interestGenreLoading = false;
            this.interestGenres = response;

            if (!this.interestGenres || this.interestGenres.length == 0)
                return this.interestGenreLoading = false;

            
            this.interestGenres.forEach(e => {
                const item = this.interestedCategoryItems.find(el => el.name == e.lang_tr);

                if (item) {
                    this.categoryItems.push({ name: item.name, icon: item.icon });
                }
            })

            this.interestGenreLoading = false;
        },
        async getMostLikedMovies() {
            this.loading = true
            const response = await MovieService.getMovies({
                limit: this.mostLikedCount,
                order_by: 'most_liked',
                order_dir: 'desc'
            })
            if (!response) return
            this.mostLikedMovies = response
            this.loading = false
        },
        async getMostCommentedMovies() {
            this.loading = true
            const response = await MovieService.getMovies({
                limit: this.mostLikedCount,
                order_by: 'most_commented',
                order_dir: 'desc'
            })
            if (!response) return
            this.mostCommentedMovies = response
            this.loading = false
        },
        async getTrendMovies() {
            this.loading = true
            const response = await MovieService.getMovies({
                limit: this.mostLikedCount,
                order_by: 'trend',
                order_dir: 'desc'
            })
            if (!response) return
            this.trendMovies = response
            this.loading = false
        },
        async getDatas() {
            await Promise.all([
                this.getNewdMovies(),
                this.getTopRatedMovies(),
                this.getMostLikedMovies(),
                this.getMostCommentedMovies(),
                this.getTrendMovies(),
                this.getUserInteresMovie(),
                this.getUserInterestGenres()
            ])
            this.selectedMovie = this.topRateMovies[0] || null;
            if (this.selectedMovie) this.isReadyFeatured = true;
            this.setFeatured();
        },
        checkMobile() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth < 920) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth < 920) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });
        this.checkMobile();
    },
    async created() {
        this.checkMobile();
        this.getDatas();

    },
    components: { MovieBar, ShimmerBar, CategoryBar, RecommendBar }
}
</script>

<style scoped>
.category-font {
    font-size: 20px;
    color: white;
    font-weight: 600;
    padding-left: 0px;
}

h2 {
    color: white;
    font-size: 2.5em;
    font-size: bold;
}

.top_margin_div {
    height: 12vh;
}

.category_icon {
    width: 32px;
}

.movie_bar_wrap {
    width: 100%;
    height: 400px;
    margin-top: 10px;
}

.genre_bar_wrap {
    width: 100%;
    height: 220px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 700px) {
    .movie_bar_wrap {
        width: 100%;
        height: 270px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .category-font {
        font-size: 16px;
    }

    .top_margin_div {
        height: 1vh;
    }

}
</style>

// /store/user.js
import UserService from "../services/UserService";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
    state: () => ({
        user: null,
    }),

    actions: {
        async fetchUserDetails() {
            const res = await UserService.getUserDetails();
            if (!res) return this.user = null;
            this.user = res;
        },
    },
});
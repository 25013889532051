<template>
    <div class="user_movie_element" @click="this.$emit('go-route')">
        <div class="go">
            <VueIcon style="color:white;" icon="material-symbols-light:arrow-circle-right-outline-rounded" height="60px"
                width="60px"></VueIcon>
            <div class="f justify-center yellow_title">
                Tümünü Gör
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showedCount: 0,
        }
    },
    methods: {
    },
    mounted() { },
    created() { },
    components: {}
}
</script>

<style scoped>
.small-icon {
    width: 28px;
}

.user_movie_element {
    height: 100%;
    width: 100%;
    background-color: white;
    position: relative;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user_movie_element:hover {
    transform: scale(1.05);
    transition: 0.2s ease-in-out;
    z-index: 1;
}

.user_movie_element:not(:hover) {
    transform: scale(1.0);
    transition: 0.2s ease-in-out;
    z-index: 1;
}

.user_movie_element:hover img {
    transition: 0.2s ease-in-out;
    border-radius: 0px;
}

.movie-info {
    position: absolute;
    bottom: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
    font-size: 20px;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    border-radius: 0;
}

.go {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bg_poster {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.small-font-white {
    font-size: 12px;
    color: white;
}

.yellow_title {
    font-size: 14px;
    font-weight: 600;
    color: #f8b632;
}


@media screen and (max-width: 500px) {
    .yellow_title {
        font-size: 11.5px;
    }

    .user_movie_element {
        height: 100%;
        width: 100%;
        background-color: white;
        position: relative;
        border-radius: 6px;
    }
}
</style>
<template>
  <NavBar />
  <div class="top_margin_div"></div>
  <div v-if="loading && !movie" style="height: 75vh;"></div>
  <FeaturedMovie @go-trailer="toggleTrailer" v-if="!loading && movie" :item="movie" />
  <div v-if="!isMobile && movie" class="rate_bar">
    <div class="rate_bar_left">
      <button @click="likeMovie" class="custom_button">
        <img class="small-icon" src="../assets/images/tickets.png" />
        <span class="lower">{{ userMovieInfo.isLiked ? 'Beğendim' : 'Beğen' }}</span>
      </button>
      <button @click="markAsWatched" class="custom_button">
        <span class="lower">{{ userMovieInfo.isWatched ? 'İzleyeceğim' : 'İzledim' }}</span>
      </button>
      <button @click="addWatchlist" class="custom_button">
        <span class="lower">{{ userMovieInfo.isAddedWatchlist ? 'İzleme Listemde' : 'Listeme Ekle' }}</span>
      </button>
    </div>
    <div class="rate_bar_left">
      <button class="custom_button">
        <img class="small-icon" src="../assets/images/view.png" />
        <span>{{ this.movie._count.view }}</span>
      </button>
    </div>
  </div>
  <div v-if="isMobile && movie" class="f sb mobile_btn_grp">
    <button @click="likeMovie" class="custom_button">
      <img class="small-icon" src="../assets/images/tickets.png" />
      <span class="lower">{{ this.movie.is_liked ? 'Beğendin' : 'Beğen' }}</span>
    </button>
    <button @click="markAsWatched" class="custom_button">
      <span class="lower">{{ this.movie.is_watched ? 'İzle' : 'Çoktan İzledim' }}</span>
    </button>
    <button @click="addWatchlist" class="custom_button">
      <span class="lower">{{ this.movie.added_watchlist ? 'Listemde' : 'Listeme Ekle' }}</span>
    </button>
    <button class="custom_button">
      <img class="small-icon" src="../assets/images/view.png" />
      <span>{{ this.movie._count.view }}</span>
    </button>
  </div>
  <div v-if="!isMobile" class="container">
    <div class="related-movies">
      <div class="left-title">
        <span class="mid-title">Bunları Da Beğenebilirsin</span>
      </div>
      <div class="f sb">
        <div class="reel-outside pl">
          <div v-for="index in reelCount" :key="index" class="reel-element"></div>
        </div>
        <div class="left-movie-bar" v-if="!relatedLoading && relatedMovies.length > 0">
          <MovieCard @go-to-movie="goMovieDetails" :item="item" v-for="(item, index) in relatedMovies" :key="index" />
        </div>
        <div class="reel-outside pr">
          <div v-for="index in reelCount" :key="index" class="reel-element"></div>
        </div>
      </div>
    </div>
    <div class="movie-comments">
      <div class="comment-title">
        <span class="mid-title">Yorumlar ({{ !loading && movie ? movie._count.comment : 0 }})</span>
      </div>
      <div>
        <ShareComment @submit="shareComment" :item="user" @go="toLogin" />
      </div>
      <div style="margin-top: 10px;">
        <FilterBar :items="filters" @sort="handleSort" />
      </div>
      <div>
        <div class="none-comment" v-if="loadingComments">
          <DotLoader :loading="loadingComments" />
        </div>
        <div v-else>
          <div v-if="comments && comments.length > 0">
            <CommentBar v-for="(comment) in comments" :item="comment" :key="comment.comment_id"
              @likeComment="likeOneComment" />
          </div>
          <div v-else class="none-comment">Henüz Yorum Yapılmamış</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isMobile" class="mobile_container">
    <div class="tab_outer">
      <div class="tab">
        <div v-for="(item, index) in tabs" :key="index" class="inner_tab"
          :class="{ 'inner_tab': true, 'active': index === activeTabIndex }" @click="setActiveTab(index)">
          {{ item.name }}
        </div>
      </div>
    </div>

    <div v-if="activeTabIndex == 0" class="movie-comments">
      <div style="margin-top: 5px;margin-bottom: 8px;">
        <ShareComment @submit="shareComment" :item="user" @go="toLogin" />
      </div>
      <div class="comment-title">
        <span class="mid-title">Yorumlar ({{ !loading && movie ? movie._count.comment : 0 }})</span>
      </div>
      <FilterBar :items="filters" @sort="handleSort" />
      <div>
        <div class="none-comment" v-if="loadingComments">
          <DotLoader :loading="loadingComments" />
        </div>
        <div v-else>
          <div v-if="comments && comments.length > 0">
            <CommentBar :item="comment" v-for="comment in comments" :key="comment.comment_id"
              @likeComment="likeOneComment" />
          </div>
          <div v-else class="none-comment">Yorum Yapılmamış. Hemen Yorum Yap </div>
        </div>
      </div>
    </div>
    <div v-if="activeTabIndex == 1" class="related-movies">
      <div class="left-title">
        <span class="mid-title">Bu Filmler İlgini Çekebilir</span>
      </div>
      <div class="f sb">
        <div class="reel-outside pl">
          <div v-for="index in reelCount" :key="index" class="reel-element"></div>
        </div>
        <div class="left-movie-bar" v-if="!relatedLoading && relatedMovies.length > 0">
          <MovieCard @go-to-movie="goMovieDetails" :item="item" v-for="(item, index) in relatedMovies" :key="index" />
        </div>
        <div class="reel-outside pr">
          <div v-for="index in reelCount" :key="index" class="reel-element"></div>
        </div>
      </div>
    </div>
    <div v-if="activeTabIndex == 2" class="movie-forum">
      <div class="article-title">
        <span class="mid-title">Toplulukları Keşfet</span>
      </div>
      <div v-if="!loadingArticles && relatedPosts" class="article-bar">
        <ArticleCard @go-post="goPost" :item="item" v-for="(item, index) in relatedPosts" :key="index" />
      </div>
    </div>

  </div>
  <FooterBar />
</template>

<script>

import MovieService from '../services/MovieService.js'
import CommentService from '../services/CommentService.js'
import WatchlistService from '../services/WatchlistService.js';
import PostService from '../services/PostService';

import router from '../router/index.js';

import MovieCard from '../components/MovieView/RelatedMovieCard.vue'
import CommentBar from '../components/MovieView/CommentBar.vue'
import ArticleCard from '../components/MovieView/ArticleCard.vue'
import ShareComment from '../components/MovieView/ShareComment.vue';
import FeaturedMovie from '../components/MovieView/FeaturedMovie.vue';
import FilterBar from '../components/PostComponents/FilterBar.vue';
import DotLoader from '../components/Loaders/DotLoader.vue';

import avatar from '../assets/images/avatar.png'
import newIcon from '../assets/images/new.png';
import riseIcon from '../assets/images/increase.png';

import { useUserStore } from '../store/user.js'

export default {
  data() {
    return {
      avatar: avatar,
      isMobile: false,
      comments: [],
      loading: false,
      limit: 6,
      topRatedCount: 6,
      userLogin: false,
      loadingComments: true,
      relatedLoading: true,
      movie: null,
      relatedMovies: [],
      relatedPosts: [],
      loadingArticles: false,
      userLoading: true,
      reelCount: 120,
      similarMovieLimit: 15,
      filters: [
        { name: 'New', langTR: 'En Yeniler', url: newIcon, type: 'latest' },
        { name: 'Top', langTR: 'En İyiler', url: riseIcon, type: 'top' },
      ],
      sortObject: {
        orderDir: 'desc',
        orderBy: 'latest',
        limit: 12
      },
      user: {},
      tabs: [
        { name: 'Yorumlar', id: 1 },
        { name: 'Alakalı Filmler', id: 2 },
        { name: 'Topluluklar', id: 3 },
      ],
      activeTabIndex: 0,
      userMovieInfo: {
        isLiked: false,
        isAddedWatchlist: false,
        isWatched: false
      }
    }
  },
  methods: {
    async handleSort(orderBy) {
      if (!orderBy) return;
      this.sortObject.orderBy = orderBy;
      await this.getComments();
    },
    toggleTrailer(youtubeID) {
      if (!youtubeID) return;
      window.open(`https://www.youtube.com/watch?v=${youtubeID}`, '_blank');
    },
    async getComments() {
      this.loadingComments = true;

      const response = await CommentService.commentByMovieId({
        limit: this.sortObject.limit,
        order_by: this.sortObject.orderBy,
        order_dir: this.sortObject.orderDir,
        movie_id: this.movieId
      });

      if (!response) return;
      this.comments = response;
      this.loadingComments = false;
    },
    async getOneMovie() {
      this.loading = true
      const response = await MovieService.getMovieById({
        movie_id: this.movieId,
        user_id: this.user.user_id
      })
      if (!response) return
      this.movie = response;
      this.loading = false
    },
    async getRelatedMovies() {
      if (!this.movie || !this.movie.genres || this.movie.genres.length == 0) return false;

      this.relatedLoading = true;
      const genresNames = this.movie.genres.map(e => e.name);

      const response = await MovieService.getSimilarMovies({
        movie_id: this.movieId,
        limit: this.similarMovieLimit,
        genres: genresNames,
        order_by: 'top_rated',
        order_dir: 'desc'
      });

      if (!response) return this.relatedLoading = false
      this.relatedMovies = response;

      this.setReelCount();
      this.relatedLoading = false
    },
    setReelCount() {
      if (!this.relatedMovies && this.relatedMovies.length == 0) return;
      if (this.isMobile)
        return this.reelCount = this.relatedMovies.length * 13;

      return this.reelCount = this.relatedMovies.length * 9;
    },
    async getUserMovieInfo() {
      this.relatedLoading = true
      const response = await MovieService.getMovieUserInfo({
        movie_id: this.movieId,
      })
      if (!response) return

      this.userMovieInfo = response.info
      this.relatedLoading = false
    },
    async getRelatedArticles() {
      this.loadingArticles = true
      const response = await PostService.getCommunityPosts({
        limit: this.limit,
        order_by: 'hot',
        order_dir: 'desc'
      })
      if (!response) return
      this.relatedPosts = response
      this.loadingArticles = false
    },
    async addWatchlist() {
      const response = await WatchlistService.addMovie({
        movie_id: this.movieId,
        user_id: this.user.user_id
      });
      if (!response) return this.userMovieInfo.isAddedWatchlist = false;
      return this.userMovieInfo.isAddedWatchlist = true;
    },
    async likeMovie() {
      const response = await MovieService.likeMovie({
        movie_id: this.movieId,
        user_id: this.user.user_id
      });

      if (!response) return this.userMovieInfo.isLiked = false;
      return this.userMovieInfo.isLiked = true;
    },
    async likeOneComment(commentID, movieID) {
      const response = await CommentService.movieCommentLike({
        movie_id: movieID,
        user_id: this.user.user_id,
        comment_id: commentID
      });

      if (!response) return;

      const comment = this.comments.find(e => e.comment_id === commentID);
      comment.like_count += 1;
    },
    async markAsWatched() {

      const response = await MovieService.watchedMovie({
        movie_id: this.movieId,
        user_id: this.user.user_id
      });

      if (!response) return this.userMovieInfo.isWatched = false;
      return this.userMovieInfo.isWatched = true;

    },
    async shareComment(comment) {
      const response = await MovieService.shareComment({
        movie_id: this.movieId,
        user_id: this.user.user_id,
        content: comment
      });
      if (!response) return;
      return await this.getComments();
    },
    setActiveTab(index) {
      this.activeTabIndex = index;
    },
    toLogin() {
      this.$router.push('/login')
    },
    goMovieDetails(movieId) {
      if (movieId == 0) return
      const id = movieId
      router.replace({ name: 'movie', params: { id } })
    },
    goPost(item) {
      return this.$router.push({ name: 'post', params: { communityId: item.community_id, postId: item.post_id } })
    },
    checkMobile() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    async getDatas() {
      await Promise.all([
        this.getOneMovie(),
        this.getComments(),
        this.isMobile ? this.getRelatedArticles() : null,
        this.getUserMovieInfo()
      ]);
      await this.getRelatedMovies();
    }
  },
  async created() {
    this.checkMobile();
    this.user = useUserStore().user;
    this.movieId = this.$route.params.id;
    await this.getDatas();
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      
    })
  },
  components: { FeaturedMovie, MovieCard, ArticleCard, CommentBar, ShareComment, FilterBar, DotLoader }
}
</script>

<style scoped>
.none-comment {
  color: white;
  background-color: #232323;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  margin-top: 10px;
}

.trailer_btn {
  background-color: #f8b632;
  height: 40px;
  max-width: 180px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  transition: 0.4s;
  cursor: pointer;
  box-shadow: 2px 2px 0px 0px rgba(232, 181, 80, 0.4), 5px 5px 0px 0px rgba(232, 181, 80, 0.3);
}

.trailer_btn:hover {
  background-color: rgb(255, 171, 3);
}

.rate_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1%;
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
  margin-top: 15px;
  margin-bottom: 8px;
  height: 50px;
}

.rate_bar_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3%;
  border-radius: 4px;
  margin: 5px;
  height: 50px;
}

.black_yellow_btn {
  height: 40px;
  display: flex;
  gap: 6px;
  align-items: center;
  appearance: none;
  background-color: black;
  border: 1px solid #f8b632;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.custom_button {
  height: 40px;
  display: flex;
  gap: 6px;
  align-items: center;
  appearance: none;
  background-color: black;
  border: 1px solid #f8b632;
  background-image: linear-gradient(1deg, #eb950b, #f8b632 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 4px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  font-weight: 600;
  text-shadow: 2px 1px 2px black;
  cursor: pointer;
  font-size: 0.9rem;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
  line-height: 1.5;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s, background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.custom_button:active,
.custom_button:focus {
  outline: none;
}

.custom_button:hover {
  background-position: -20px -20px;
}

.custom_button:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

.left-title {
  position: sticky;
  top: 0;
  z-index: 6;
  display: flex;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 4%;
  margin-bottom: 4%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.6);
}

.comment-title {
  position: sticky;
  top: 0;
  z-index: 6;
  display: flex;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 3%;
  padding-left: 2%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.6);
}

.article-title {
  position: sticky;
  top: 0;
  z-index: 6;
  display: flex;
  padding-top: 2%;
  padding-bottom: 1%;
  padding-left: 2%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.6);
}

.user-comment>textarea {
  grid-row: 1/3;
  grid-column: 2/3;
  resize: none;
  margin: 2%;
  height: 200px;
}

.to-login {
  text-decoration: underline;
  cursor: pointer;
}


.container {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  display: grid;
  grid-template-columns: 0.5% 17% 1.5% 80.5% 0.5%;
  height: 100vh;
  padding-left: 1%;
  padding-right: 1%
}

.container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.movie-comments {
  display: flex;
  flex-direction: column;
  grid-column: 4/5;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.movie-forum {
  grid-column: 6/7;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.related-movies {
  grid-column: 2/3;
  background: hsl(0 0% 100%/0.1);
  height: 100%;
  border-radius: 10px;
}

.left-movie-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: 100%;
  padding-bottom: 10%;
}

.article-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  height: 100%;
  padding-bottom: 10%;
  padding-right: 2%;
  padding-top: 0%;
}

.mid-title {
  font-size: 2.3vh;
  color: white;
  font-weight: 600;
}

.pl {
  padding-left: 0.5rem;
}

.pr {
  padding-right: 0.5rem;
}

.reel-outside {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1.5rem;
  background-color: transparent;
}

.reel-element {
  height: 0.5rem;
  background-color: transparent;
  width: 16px;
  border-radius: 4px;
  background-color: white;
}

@media(max-width: 500px) {

  .rate_bar {
    justify-content: space-between;
    gap: 1.5%;
    padding: 0px;
    margin: 8px;
  }

  .rate_bar_left {
    gap: 1.5%;
  }

  .lower {
    font-size: 14px;
  }

  .custom_button {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (max-width: 920px) {
  .mobile_container {
    margin-top: 10px;
    padding: 0.25em;
  }

  .comment-title {
    position: sticky;
    top: 0;
    z-index: 6;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-bottom: 8px;
    padding-left: 0%;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .tab_outer {
    width: 100%;
    padding: 0rem 0.2rem;
  }

  .tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
    border-radius: 0px;
    height: 40px;
    transition: 0.4s;
    border: 0.5px solid #f8b632;
    margin-bottom: 10px;
  }

  .inner_tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: white;
    border-radius: 0px;
    transition: 0.4s;
  }

  .inner_tab:hover {
    color: black;
    background-color: #f8b632;
  }

  .inner_tab.active {
    color: black;
    background-color: #f8b632;
  }

  .left-title {
    position: sticky;
    top: 0;
    z-index: 6;
    display: flex;
    justify-content: flex-start;
    padding-left: 0%;
    padding-top: 2%;
    padding-bottom: 1%;
    margin-bottom: 12px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .article-title {
    position: sticky;
    top: 0;
    z-index: 6;
    display: flex;
    padding-top: 2%;
    padding-bottom: 1%;
    padding-left: 0%;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .article-bar {
    gap: 8px;
    height: 100%;
    padding-bottom: 10%;
    padding-right: 0%;
    padding-top: 1%;
  }

  .mobile_btn_grp {
    padding: 0em 0.3em;
    margin-top: 10px;
  }

  .top_margin_div {
    display: none;
  }

}
</style>

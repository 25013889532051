<template>
    <div class="shimmer-container f sb">
        <div v-for="(index) in Math.ceil(visibleMovieCount)" class="card" :key="index"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            reelCount: 0,
            currentSlide: 0,
            windowWidth: 1280,
            visibleMovieCount: 9,
            filmBoxWidth: 170,
            showedCount: 0
        }
    },
    methods: {
        setVisibleCount() {
            this.windowWidth = window.innerWidth
            this.visibleMovieCount = (this.windowWidth / this.filmBoxWidth) - 2 
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
            this.visibleMovieCount = this.windowWidth / this.filmBoxWidth
        })
    },
    created() {
        this.windowWidth = window.innerWidth
        this.showedCount = this.windowWidth / this.filmBoxWidth
        this.setVisibleCount();
    }
}
</script>

<style scoped>
.film-top-bottom {
    display: flex;
    height: 2rem;
    width: 100%;
    gap: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    background-color: black;
}

.film-top-bottom-element {
    height: 1rem;
    background-color: transparent;
    width: 10px;
    border-radius: 2px;
    background-color: rgb(175, 31, 31);
}

.shimmer-container {
    background: #21242c;
    width: 100%;
    height: 100%;
    gap: 15px;
    padding: 0.8rem;
}

.card {
    height: 100%;
    width: 170px;
    background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
    background-size: 200% 100%;
    animation: shimmer 1.8s infinite;
    margin-bottom: 10px;
}

@keyframes shimmer {
    to {
        background-position: 200% 0;
    }
}
</style>
<template>
  <footer class="footer">
    <div class="row1">
      <div class="film-top-bottom">
        <div v-for="index in reelCount" :key="index" class="film-top-bottom-element"></div>
      </div>
    </div>
    <div class="col1 info1 row2">
      <a @click="this.$router.push(item.link)" v-for="(item, index) in leftItems" :key="index">{{ item.langTR }}</a>
    </div>
    <div class="col2 info1 row2">

    </div>
    <div class="col3 info1 row2">

    </div>
    <div class="col4 info1 row2">
      <a @click="this.$router.push(item.link)" v-for="(item, index) in rightItems" :key="index">{{ item.langTR }}</a>
    </div>
    <div class="row3">
      <a href="instagram.com"><img src="../../assets/images/instagram.png" alt="instagram"></a>
      <a href="youtube.com"><img src="../../assets/images/youtube.png" alt="youtube"></a>
      <a href="twitter.com"><img src="../../assets/images/twitter.png" alt="twitter"></a>
    </div>
    <div class="row4">
      <div>
        © 2024 mosecop.com, Tüm hakları saklıdır.
      </div>
    </div>
  </footer>
</template>

<script>
import avatar from '../../assets/images/avatar.png'

export default {
  data() {
    return {
      avatar: avatar,
      title: 'Mosecop',
      reelCount: 0,
      windowWidth: window.innerWidth,
      leftItems: [
        {
          name: 'Home',
          langTR:'Anasayfa',
          link: '/home'
        },
        {
          name: 'AI',
          langTR:'AI',
          link: '/ai'
        },
        {
          name: 'Movies',
          langTR:'Filmler',
          link: '/movies'
        },
      ],
      rightItems: [
        {
          name: 'Community',
          langTR:'Topluluk',
          link: '/community'
        },
        {
          name: 'Contact',
          langTR:'İletişim',
          link: '/contact'
        },
        {
          name: 'Watchlists',
          langTR:'İzleme Listeleri',
          link: '/watchlists'
        }
      ]
    }
  },
  methods: {
    setReelCount() {
      this.reelCount = Math.floor(this.windowWidth / 25)
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      this.reelCount = Math.floor(this.windowWidth / 25)
    })
  },
  created() {
    this.windowWidth = window.innerWidth
    this.setReelCount();
  }
}
</script>

<style scoped>
.footer {
  overflow: hidden;
  position: relative;
  z-index: 2;
  left: 0;
  bottom: 0;
  background-color: black;
  color: white;
  height: 40dvh;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: 20% 50% 29% 1%;
  padding: 2%;
}

.film-top-bottom-element {
  height: 1.2em;
  width: 0.65rem;
  background-color: transparent;
  border-radius: 3px;
  background-color: white;
}

.film-top-bottom {
  padding: 0;
}

.row1 {
  grid-row: 1/2;
  grid-column: 1/5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row2 {
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.row3 {
  grid-row: 3/4;
  grid-column: 1/5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row4 {
  grid-row: 4/5;
  grid-column: 1/5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 15px;
}

.col1 {
  padding-top: 10px;
  grid-column: 1/2;
}

.col2 {
  grid-column: 2/3;
}

.col3 {
  grid-column: 3/4;
}

.col4 {
  padding-top: 10px;
  grid-column: 4/5;
}

.info1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

p {
  color: black;
}

.row3>a>img {
  width: 50px;
}

.row3>a>img:hover {
  filter: brightness(50%);
}

.footer-button {
  min-width: 3rem;
  border-radius: 10px;
  background-color: white;
  color: black;
  padding: 1%;
}

.footer-button:hover {
  filter: brightness(50%);
}

.footer-button:active {
  background-color: black;
  color: white;
  filter: brightness(100%);
}

.info1>a {
  color: white;
  text-decoration: none;
}

.info1>a:hover {
  color: gray;
  cursor: pointer;
}
</style>
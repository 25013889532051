<template>
  <div class="create_post">
    <div class="post_avatar">
      <img class="post_avatar" alt="avatar" src="../../assets/images/avatar.png" />
    </div>
    <div @click="this.$emit('showModal', true)" class="post_section">
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text:'Gönderi Paylaş',
      topRateMovies: []
    }
  },
  methods: {},
  async created() { },
  components: {}
}
</script>

<style scoped>

.create_post {
  display: flex;
  align-items: center;
  gap: 1%;
  width: 100%;
  height: 4.5rem;
  padding: 1rem;
  background-color: #1a1a1b;
  border: 1px rgb(146, 146, 146) solid;
  border-radius: 6px;
  transition: 1s;
}

.post_section {
  border: 1px rgb(146, 146, 146) solid;
  width: 90%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 1%;
  cursor: pointer;
  color: white;
  background-color: #1a1a1b;
}

.post_section:hover {
  background-color: #2f2f30;
  transition: 0.5s;
}

.post_avatar {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: white;
}
</style>

<template>
    <div class="comm_right">
        <h4 class="small_font">{{ item.title || item.community_post.title || '' }}</h4>
        <div class="comment-content">
            {{ item.content.slice(0, 50) }}
        </div>
        <div style="width: 100%;" class="f sb">
            <div class="comment-content">{{ formatDate(item.created_at) }}</div>
        </div>
    </div>
    <div style="display: flex; align-items: center;">
        <button style="background-color: transparent; border: none;">
            <img class="small-icon" src="../../assets/images/right-arrow.png" />
        </button>
    </div>
</template>
    
<script>
import moment from 'moment';

export default {
    data() {
        return {
            tabs: [],
        }
    },
    emits: ['go'],
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    methods: {
        getImageUrl(imageUrl) {
            if (imageUrl === undefined) return
            const startIndex = imageUrl.indexOf('@.')
            let str = imageUrl.slice(0, startIndex + 1)
            str += '.jpg'
            return str
        },
        formatDate(time) {
            return moment(time).format('DD-MM-YYYY')
        },
        go(id) {
            this.$emit('go', id)
        }

    },
    created() {
    },
    components: {}
}
</script>
    
<style scoped>
.comment-content {
    color: rgb(126, 125, 125)
}

h4 {
    color: white;
    font-weight: 800;
}

.small-icon {
    width:15px;
}

.comment {
    width: 100%;
    max-height: 130px;
    border: 1px solid transparent;
    background-color: rgb(50, 50, 50);
    margin: 10px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.4s;
    cursor: pointer;
}

.comment:hover {
    border-color: #f8b632;
}

.com_left {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small {
    width: 60px;
    height: 90px;
    border-radius: 5px;
}

.comm_right {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.comm_right::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

@media screen and (max-width: 600px) {
    .comment {
        height: 50% !important;
        font-size: 0.7rem;
    }

}
</style>
    
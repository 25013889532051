<template>
    <div class="community_statistic">
        <div>
            <h3>{{header}}</h3>
        </div>
        <div class="user_wrapper">
            <div v-for="(item, index) in items" :key="index" class="info_wrapper">
                <div class="info_left">
                    <img class="active_avatar" alt="avatar" :src="item.url" />
                    <span class="">{{ item.name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            topRateMovies: [],
            header: 'Moderatörler'
        }
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    methods: {},
    async created() { },
    components: {}
}
</script>

<style scoped>
.community_statistic {
    background-color: #1A1A1B;
    color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 18%;
    padding: 0.5rem 1rem;
    border: 1px rgb(58 58 58) solid;
    border-radius: 6px;
    display: flex;
    gap: 15px;
}

.user_wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.info_wrapper span {
    font-weight: bold;
    font-size: 17px;
}

.info_wrapper:hover {
    background-color: #f8b632;
    cursor: pointer;
    cursor: pointer;
}

.community_statistic p {
    color: white;
}

.community_statistic h3,
h4 {
    font-weight: 700;
    color: rgb(170, 165, 165);
}

.info {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 5%;
}

.info_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.info_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0.3rem 0.4rem;
    border-radius: 5px;
    border: 1px solid transparent;
    transition: 0.5s;
}

.active_avatar {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: white;
}

.icon {
    height: 18px;
    width: 18px;
}

.send_button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 920px) {

    .community_statistic {
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 0px;
        display: flex;
        gap: 10px;
        border-bottom: 1px solid #f8b632;
    }

}
</style>
<template>
  <div class="film-top-bottom mb-6">
    <div v-for="(index) in reelCount" :key="index" class="film-top-bottom-element"></div>
  </div>

  <div class="slider-container">
    <div class="slider" :style="{ transform: 'translateX(' + currentSlide * -100 + '%)' }">
      <div v-for="(item, index) in items" :key="index" class="film-element" @click="goMovieDetails(item.id)">
        <img class="prevent-user-img poster" :alt="item.title" :src="getImageUrl(item.poster) || reel"
          @error="onImageError" />
        <div class="stats">
          <div class="f align-center">
            <img class="small-icon" src="../../assets/images/imdb.png" />
            <div style="width: 5px"></div>
            <span class="small-font-white">{{ item.imdbrating }}</span>
          </div>
          <div class="f align-center">
            <img class="small-icon" src="../../assets/images/metascore.png" />
            <div style="width: 5px"></div>
            <span class="small-font-white">{{ item.metascore }}</span>
          </div>
        </div>
        <div class="movie-info">
          <div class="f align-center" style="justify-content: center">
            <h6>{{ item.title }}</h6>
          </div>
          <div class="f sb">
            <div class="f align-center">
              <img class="small-icon" src="../../assets/images/tickets.png" />
              <div style="width: 2px"></div>
              <span class="small-font-white">{{ item._count.like || 0 }}</span>
            </div>
            <div class="f align-center">
              <img class="small-icon" src="../../assets/images/message.png" />
              <div style="width: 2px"></div>
              <span class="small-font-white">{{ item._count.comment || 0 }}</span>
            </div>
            <div class="f align-center">
              <img class="small-icon" src="../../assets/images/view-yellow.png" />
              <div style="width: 2px"></div>
              <span class="small-font-white">{{ item._count.view || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navigation">
      <div class="slider_button_left">
        <VueIcon @click="prevSlide" style="color:rgb(237, 190, 50);" icon="mingcute:left-line" height="32px"
          width="32px">
        </VueIcon>
      </div>
      <div class="slider_button_right">
        <VueIcon @click="nextSlide" style="color:rgb(237, 190, 50);" icon="mingcute:right-line" height="32px"
          width="32px">
        </VueIcon>
      </div>
    </div>
  </div>

  <div class="film-top-bottom mt-6">
    <div v-for="(index) in reelCount" :key="index" class="film-top-bottom-element"></div>
  </div>
</template>

<script>
import router from '../../router/index.js';
import reel from '../../assets/images/movie-reel.png';

export default {
  props: {
    items: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reelCount: 0,
      currentSlide: 0,
      windowWidth: 1280,
      visibleMovieCount: null,
      filmBoxWidth: 170,
      showedCount: 0,
      movieUpperLimit: 0,
      reel
    }
  },
  methods: {
    getImageUrl(imageUrl) {
      let str = imageUrl;
      if (!imageUrl) return reel;
      if (imageUrl.includes('@.')) {
        const startIndex = imageUrl.indexOf('@.')
        str = imageUrl.slice(0, startIndex + 1)
        str += '.jpg';
      }

      return str
    },
    onImageError(event) {
      event.target.src = this.reel;
    },
    nextSlide() {
      if (this.currentSlide + 1 >= Math.ceil(this.movieUpperLimit)) {
        this.currentSlide = 0
      } else {
        this.currentSlide += 1
      }
    },
    prevSlide() {
      if (this.currentSlide == 0) return (this.currentSlide = Math.floor(this.movieUpperLimit))
      this.currentSlide -= 1
    },
    checkVisibleMovie() {
      this.movieUpperLimit = this.items.length / this.visibleMovieCount;
    },
    setVisibleCount() {
      this.windowWidth = window.innerWidth
      this.visibleMovieCount = this.windowWidth / this.filmBoxWidth
    },
    setReelCount() {
      this.reelCount = Math.floor(this.windowWidth / 25)
    },
    goMovieDetails(movieId) {
      if (movieId == 0) return
      const id = movieId
      router.push({ name: 'movie', params: { id } })
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      this.visibleMovieCount = this.windowWidth / this.filmBoxWidth
      this.movieUpperLimit = this.items.length / this.visibleMovieCount
      this.reelCount = Math.floor(this.windowWidth / 25)
    })
  },
  created() {
    this.windowWidth = window.innerWidth
    this.showedCount = this.windowWidth / this.filmBoxWidth
    this.setVisibleCount()
    this.checkVisibleMovie()
    this.setReelCount()
  }
}
</script>

<style>
.slider-container {
  width: 100%;
  height: 70%;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.slider {
  display: grid;
  grid-auto-flow: column;
  transition: transform 0.6s ease-in-out;
}

.navigation {
  position: absolute;
  top: 40%;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  justify-content: space-between;
}


.small-icon {
  width: 20px;
}

.mb-6 {
  margin-bottom: 0.6rem;
}

.mt-6 {
  margin-top: 0.6rem;
}

.film-element {
  border-radius: 6px;
  margin-left: 5px;
  margin-right: 5px;
  width: 170px;
  height: 265px;
  background-color: white;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.film-element:hover {
  border-radius: 6px;
  transform: scale(1.08);
  transition: 0.3s ease-in-out;
  z-index: 999;
}

.film-element:not(:hover) {
  border-radius: 6px;
  transform: scale(1);
  transition: 0.3s ease-in-out;
  z-index: 1;
}

.film-element:hover img {
  border-radius: 6px;
  transition: 0.3s ease-in-out;
}

.movie-info {
  position: absolute;
  bottom: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 20px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.film-element:hover .stats {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: 0.3s ease-in;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  top: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 8%;
}

.stats {
  position: absolute;
  display: none;
  top: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.poster {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.film-top-bottom {
  display: flex;
  height: 2rem;
  width: 100%;
  gap: 1rem;
  padding-left: 5px;
  padding-right: 5px;
  background-color: black;
}

.film-top-bottom-element {
  height: 1rem;
  background-color: transparent;
  width: 10px;
  border-radius: 2px;
  background-color: white;
}

.small-font-white {
  font-size: 12px;
  color: white;
}

.nav_arrow {
  background-color: red;
  outline: none;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-top: 4px #f8b632 solid;
  border-left: 4px #f8b632 solid;
  border-bottom: none;
  border-right: none;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}


.slider_button_left {
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 40%;
  height: 45px;
  width: 45px;
  background-color: #08080899;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  transition: 0.1s;
}

.movie-info .small-icon {
  width: 24px;
}

.slider_button_right {
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 40%;
  height: 45px;
  width: 45px;
  background-color: #08080899;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  transition: 0.1s;
  display: flex;
}

.nav_arrow {
  background-color: red;
  outline: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-top: 4px #f8b632 solid;
  border-left: 4px #f8b632 solid;
  border-bottom: none;
  border-right: none;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

@media screen and (max-width: 700px) {

  h6 {
    font-size: 12px;
  }

  .mb-6 {
    margin-bottom: 0rem;
  }

  .mt-6 {
    margin-top: 0rem;
  }

  .slider-container {
    width: 100%;
    height: 90%;
    margin: 0;
    overflow: hidden;
    position: relative;
  }

  .movie-info .small-icon {
    width: 18px;
  }

  .small-font-white {
    font-size: 11px;
    color: white;
  }

  .film-element {
    border-radius: 6px;
    margin-left: 5px;
    margin-right: 5px;
    width: 140px;
    height: 85%;
    background-color: white;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .film-top-bottom {
    display: flex;
    height: 1.4rem;
    width: 100%;
    gap: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    background-color: black;
  }

  .film-top-bottom-element {
    height: 0.9rem;
    background-color: transparent;
    width: 10px;
    border-radius: 2px;
    background-color: white;
  }


}

@media screen and (max-width: 700px) {

  h6 {
    font-size: 12px;
  }

  .mb-6 {
    margin-bottom: 0rem;
  }

  .mt-6 {
    margin-top: 0rem;
  }

  .slider-container {
    width: 100%;
    height: 90%;
    margin: 0;
    overflow: hidden;
    position: relative;
  }

  .movie-info .small-icon {
    width: 18px;
  }

  .small-font-white {
    font-size: 11px;
    color: white;
  }

  .film-element {
    border-radius: 6px;
    margin-left: 5px;
    margin-right: 5px;
    width: 140px;
    height: 100%;
    background-color: white;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .film-top-bottom {
    display: flex;
    height: 1.4rem;
    width: 100%;
    gap: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    background-color: black;
  }

  .film-top-bottom-element {
    height: 0.9rem;
    background-color: transparent;
    width: 10px;
    border-radius: 2px;
    background-color: white;
  }

}
</style>

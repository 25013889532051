<template>
    <NavBar />
    <div class="modal" id="modal">
        <button @click="closeModal" class="modal-button">X</button>
        <div class="modal-top">
            <div class="modal-container">
                <VueIcon icon="iconamoon:search-light" width="28px" style="color: white"></VueIcon>
                <input type="text" placeholder="Ara" class="modal-search" v-model="term" @input="searchMovie" />
            </div>
            <hr />
            <div class="modal-text">Filmler</div>
        </div>

        <div v-if="searchMovies" class="moviesModal">
            <div v-for="(item, index) in searchMovies" :key="index" class="movieModal"
                :style="{ backgroundImage: `url(${item.poster})` }">
                <button class="movie-select" @click="modalAdd(item)">
                    <VueIcon :icon="getIcon(item.id)" width="50px" style="color: white"></VueIcon>
                </button>
                <div class="movie-name-modal">{{ item.title }}</div>
            </div>
        </div>
    </div>

    <div class="top_margin_div"></div>
    <main class="main flex">
        <div class="top_left flex-column g5">
            <div class="f sb">
                <h3>{{ watchlistData.name }}</h3>
                <button @click="create" class="create_list">Listeni Paylaş!
                    <VueIcon style="color:white" icon="mingcute:send-plane-line" height="26" width="26">
                    </VueIcon>
                </button>
            </div>
            <div class="f flex-end g5">
                <input :style="{ display: editingName ? 'flex' : 'none' }" type="text" class="input-name"
                    v-model="newName" id="watchlistName" />
                <button v-if="!editingName" class="edit-button" @click="changeName">
                    <VueIcon icon="lucide:edit" width="20px" style="color: white"></VueIcon>
                    İsimlendir
                </button>
                <button v-if="editingName" class="edit-button" @click="sendName">
                    <VueIcon icon="mdi:success-bold" width="24px" style="color: white"></VueIcon>
                </button>
                <button v-if="editingName" class="edit-button" @click="editingName = false">
                    <VueIcon icon="mdi:cancel-bold" width="24px" style="color: white"></VueIcon>
                </button>
            </div>
        </div>
        <div class="main-top">
            <div class="watchlist-name"></div>
            <button class="search-bar" @click="openModal">Film Ara</button>
        </div>
        <div class="main-bottom flex">
            <div class="watchlists">
                <div v-if="movieList && movieList.length > 0" class="watchlist flex" id="watchlist">
                    <div v-for="(item, index) in movieList" :key="index"
                        :style="{ backgroundImage: `url(${item.poster})` }" class="movie">
                        <button class="movie-select" @click="deleteElement(item)">
                            <VueIcon icon="clarity:remove-line" width="50px" style="color: white"></VueIcon>
                        </button>
                        <div class="movie-name">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <InfoModal :duration="'3s'" :type="'info'" v-if="showInfoModal" :message="infoMessage"
        @close="showInfoModal = false" />
    <FooterBar />
</template>
<script>
import PublicWatchlistService from '../services/PublicWatchlistService.js'
import MovieService from '../services/MovieService.js'

export default {
    data() {
        return {
            isMobile: false,
            postID: null,
            communityID: null,
            limit: 20,
            listLoading: true,
            loading: true,
            movies: [],
            communityInfo: {},
            sortObject: {
                orderBy: 'imdb',
                order_dir: 'desc',
                limit: 50,
                searchTerm: ''
            },
            searchMovies: [],
            searchTimeout: null,
            term: '',
            watchlistID: null,
            states: [],
            header: 'Başlık Burada',
            newName: '',
            editingName: false,
            showInfoModal: false,
            infoMessage: 'İşlem başarılı.',
            modalDuration: 2000,
            movieList: [],
            watchlistData: {
                name: 'Lütfen Listenizi İsimlendirin',
                id: null
            },
        }
    },
    methods: {
        async handleSort(sortBy) {
            if (!sortBy) return
            this.commentOrderBy = sortBy
        },
        checkMobile() {
            this.windowWidth = window.innerWidth
            if (this.windowWidth < 920) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        async getListBySearchQuery() {
            this.listLoading = true
            const response = await PublicWatchlistService.getWatchlist({
                id: this.watchlistID
            })
            if (!response) return
            this.movies = response.movies;
            this.watchlistData.id = response.watchlist_id;
            this.watchlistData.name = response.name;
            this.listLoading = false
        },
        async getTrendMovies() {
            this.loading = true
            const response = await MovieService.getMoviesAdvanced({
                limit: this.limit,
                order_by: this.sortObject.orderBy,
                order_dir: 'desc',
                search: this.sortObject.searchTerm,
                genres: [],
                imdb: 10,
                meta: 100
            });
            if (!response) return
            this.searchMovies = response;
            this.loading = false
        },
        openInfoModal(msg) {
            this.infoMessage = msg
            this.showInfoModal = true
            setTimeout(() => {
                this.showInfoModal = false
                this.$router.push({ name: 'watchlists' });
            }, this.modalDuration)
        },
        closeModal() {
            const modal = document.getElementById('modal')
            const mainPart = document.querySelector('.main')
            document.querySelector('body').style.overflowY = 'auto'
            modal.classList.add('modal-out')
            modal.classList.remove('modal-in')
            setTimeout(() => {
                modal.style.display = 'none'
                mainPart.style.filter = 'blur(0px)'
                mainPart.style.userSelect = ''
            }, 160)
        },
        openModal() {
            const modal = document.getElementById('modal')
            const mainPart = document.querySelector('.main')
            document.querySelector('body').style.overflowY = 'hidden'
            mainPart.style.filter = 'blur(50px)'
            mainPart.style.userSelect = 'none'
            modal.classList.remove('modal-out')
            modal.classList.add('modal-in')
            modal.style.display = 'block'
        },
        getIcon(id) {
            const find = this.states.find((e) => e.id === id)
            if (!find) return 'gala:add'
            if (find.value) return 'clarity:remove-line'
            return 'gala:add'
        },
        async modalAdd(item) {
            if (!item) return;
            const find = this.states.find((e) => e.id === item.id);
            const findMovieIdx = this.movieList.findIndex((e) => e.id === item.id);

            if (findMovieIdx != -1) {
                find.value = false;
                return this.movieList.splice(findMovieIdx, 1)
            }

            if (findMovieIdx == -1) {
                find.value = true;
                this.movieList.push(item);
                return;
            }
        },
        async deleteElement(item) {
            const index = this.movieList.findIndex((e) => e.id === item.id)
            if (index === -1) return

            const find = this.states.find((e) => e.id === item.movie_id)
            if (find) find.value = true
            return this.movieList.splice(index, 1)
        },
        async searchMovie() {
            this.sortObject.searchTerm = this.term
            clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(async () => {
                await this.getTrendMovies()
            }, 1000)
        },
        changeName() {
            this.editingName = true
            var editButtonControl = 0
            let watchlistName = document.getElementById('watchlistName')
            if (editButtonControl == 0) {
                watchlistName.style.pointerEvents = 'auto'
                watchlistName.focus()
                editButtonControl = 1
                this.name = this.header
            } else {
                watchlistName.style.pointerEvents = 'none'
                editButtonControl = 0
            }
        },
        async sendName() {
            if (!this.newName) return;
            this.watchlistData.name = this.newName;
            this.editingName = false
        },
        setStates() {
            if (!this.searchMovies) return

            this.searchMovies.forEach((e) => {
                this.states.push({ id: e.id, value: false })
            })

            if (this.movieList) {
                this.movieList.forEach((e) => {
                    this.states.forEach((e2) => {
                        if (e2.id == e.id) return (e2.value = true)
                    })
                })
            }
        },
        async create() {
            const movies = this.movieList.map(e => e.id);
            if (!movies || movies.length == 0) return;

            const response = await PublicWatchlistService.createWatchlist({
                name: this.watchlistData.name,
                movies: movies
            });

            if(!response || !response.success) return this.openInfoModal('İzleme Listesi oluşturulurken hata oluştu. Lütfen daha sonra tekrar deneyin.')

            this.openInfoModal(`İzleme listesi '${response.name}' oluşturuldu.`);

        }
    },
    async created() {
        this.checkMobile()
        await this.getTrendMovies()
        this.setStates();
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
            if (this.windowWidth < 920) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        })
        const modal = document.getElementById('modal')
        modal.style.display = 'none'
    },
    components: {}
}
</script>

<style scoped>
h3 {
    color: white;
    font-weight: 900;
    font-size: 18px;
}

.create_list {
    color: #f8b632;
    background-color: black;
    font-size: 1.1rem;
    border: 1px solid #f8b632;
    border-radius: 2px;
    height: 40px;
    min-width: 180px;
    aspect-ratio: auto;
    cursor: pointer;
    display: flex;
    gap: 0.3rem;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.create_list:hover {
    transition: 0.2s;
    background-color: #f8b632;
    color: white;
}

::-webkit-scrollbar {
    width: 7px;
    border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #f8b632;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: rgb(237, 190, 50);
}

.flex {
    display: flex;
}

.top_margin_div {
    height: 14vh;
}

.g5 {
    gap: 0.5em;
}

.flex-column {
    flex-direction: column;
}

.top_left {
    width: 100%;
    display: flex;
    padding-left: 3%;
    padding-right: 3%;
}

.search-bar {
    width: 200px;
    padding: 5%;
    border-radius: 1px;
    font-size: 15px;
    background-color: #f8b632;
    border: none;
    cursor: pointer;
}

.search-bar:hover {
    background-color: #db9406;
    transition: 0.2s;
}

.main {
    flex-direction: column;
    height: 100%;
    justify-content: start;
    align-items: center;
}

.main-bottom {
    width: 100%;
    height: 100%;
    align-items: start;
    flex-wrap: wrap;
}

.watchlists {

    width: 100%;
    height: 100%;
    margin: 1%;
    padding: 1%;
}

.watchlist {
    overflow-x: hidden;
    overflow-y: hidden;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    display: grid;
    min-height: 100vh;
    gap: 20px 20px;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 260px;
    justify-content: start;
    width: 100%;
    gap: 25px 10px;
}

.movie {
    padding: 2%;
    margin: 2%;
    box-shadow: 0px 1px 2px #f8b63289;
    height: 80%;
    text-align: center;
    width: 10%;
    min-width: 150px;
    border-radius: 10px;
    position: relative;
    background-size: cover;
    background-position: center;
    transition: transform 0.2s ease;
}

body {
    height: auto;
}

.modal {
    display: hidden;
    position: absolute;
    top: 5vh;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 70%;
    max-width: 800px;
    height: 85%;
    border-radius: 12px;
    background-color: black;
    z-index: 999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #f8b632;
    border: 0.2px solid transparent;
}

.moviesModal {
    overflow-y: scroll;
    margin-inline: 2%;
    height: 65%;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    align-items: center;
    margin-bottom: 1%;
    flex-wrap: wrap;
    row-gap: 3rem;
    padding: 2%;
}

.movieModal {
    cursor: pointer;
    border-radius: 10px;
    width: 28%;
    height: 80%;
    transition: transform 0.2s ease;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 5px 10px rgba(245, 245, 245, 0.37);
}

.movieModal:hover,
.movie:hover {
    transform: scale(0.97);
}

.movieModal:not(:hover),
.movie:not(:hover) {
    transform: scale(1);
}

.modal-in {
    animation: modal-anim-in 0.15s ease-in both;
}

.modal-out {
    animation: modal-anim-out 0.15s ease-out both;
}

@keyframes modal-anim-in {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes modal-anim-out {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(0);
    }
}

.modal-text {
    margin: 2%;
    font-size: 1.5rem;
}

.modal-button {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #f8b632;
    z-index: 999;
    width: 30px;
    aspect-ratio: 1;
    border-radius: 15px;
}

.modal-button:hover {
    background-color: black;
    color: #f8b632;
    border-color: #f8b632;
    cursor: pointer;
}

.modal-search {
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    height: 40px;
    color: #f8b632;
    width: 95%;
    font-size: 1.5rem;
    border: none;
    background-color: transparent;

    outline: none;
}

.modal-container {
    margin-top: 2%;
    padding: 1% 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-icon {
    scale: 1.3;
    margin-left: 1%;
}

.watchlist-top {
    width: 100%;
    text-align: center;
}

.add-to-watchlist {
    margin-left: auto;
    border-radius: 15px;
    padding: 0.8%;
    background-color: #f8b632;
}

.watchlist-name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.main-top {
    padding-top: 0%;
}

.movie-name,
.movie-name-modal {
    position: absolute;
    bottom: -35px;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 210px;
    text-align: center;
}

.movie-select {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1;
    width: 60px;
    border-radius: 0;
    background-color: transparent;
    font-size: 2rem;
    z-index: 2;
    border: none;
}

.movie:hover>.movie-select {
    display: block;
    cursor: pointer;
}

.movieModal:hover>.movie-select {
    display: block;
    cursor: pointer;
}

.input-name {
    height: 2.4rem;
    padding-left: 5px;
    border-radius: 2px;
    text-align: flex-start;
    justify-content: center;
    background-color: transparent;
    color: #f8b632;
    outline-color: #f8b632;
    border: none;
    min-width: 250px;
    pointer-events: none;
    border: 1px solid #f8b632;
}

.edit-button {
    font-size: 14px;
    height: 2.4rem;
    background-color: transparent;
    color: white;
    min-width: 50px;
    border: 1px solid #f8b632;
    border-radius: 2px;
    padding: 0.5%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.edit-button:active {
    filter: brightness(50%);
}

@media only screen and (max-width: 1300px) {
    .watchlist {
        grid-template-columns: repeat(7, 1fr);
        grid-auto-rows: 260px;
        justify-content: start;
        width: 100%;
        gap: 25px 10px;
    }
}

@media only screen and (max-width: 1170px) {
    .watchlist {
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: 260px;
        justify-content: start;
        width: 100%;
        gap: 25px 10px;
    }
}

@media only screen and (max-width: 1070px) {
    .watchlist {
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 260px;
        justify-content: start;
        width: 100%;
        gap: 25px 10px;
    }
}

@media only screen and (max-width: 830px) {
    .watchlist {
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
        gap: 25px 10px;
    }
}

@media only screen and (max-width: 670px) {
    .watchlist {
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: 25px 10px;
    }
}


@media only screen and (max-width: 600px) {
    .movieModal {
        width: 40%;
        height: 40%;
        border-radius: 10px;
    }

    .watchlist {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 280px;
        width: 100%;
        gap: 10px 10px;
    }

    .moviesModal {
        overflow-y: scroll;
        margin-inline: 0%;
        height: 75%;
        display: flex;
        justify-content: center;
        gap: 2rem;
        align-items: center;
        margin-bottom: 1%;
        flex-wrap: wrap;
        row-gap: 3rem;
        padding: 2%;
    }

    .modal {
        width: 95%;
        height: 100%;
    }

    .movie {
        height: 85%;
        border-radius: 10px;
    }

    .movie-name,
    .movie-name-modal {
        bottom: -20px;
        width: 170px;
        font-size: 12px;
    }

    .top_margin_div {
        height: 1vh;
    }

    .main-top {
        margin-top: 10px;
    }

    .input-name {
        height: 2.4rem;
        padding-left: 5px;
        border-radius: 2px;
        text-align: flex-start;
        justify-content: center;
        background-color: transparent;
        color: #f8b632;
        outline-color: #f8b632;
        border: none;
        min-width: 200px;
        pointer-events: none;
        border: 1px solid #f8b632;
    }

}
</style>
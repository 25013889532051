<template>
  <div class="movie-featured-movie"
    :style="{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', background: `${getImageUrl(item.poster)}` }">
    <div class="row">
      <div class="col col1">
        <h1>{{ item.title }}</h1>
        <span class="overview">
          {{ item.description }}
        </span>
        <div class="info_bar">
          <span class="mid-bold">{{ item.year }}</span>
          <div class="f align-center">
            <img class="info-icon" src="../../assets/images/imdb.png" />
            <div style="width: 5px"></div>
            <span class="mid-bold">{{ item.imdbrating }}</span>
          </div>
          <div class="f align-center">
            <img class="info-icon" src="../../assets/images/tickets.png" />
            <div style="width: 5px"></div>
            <span class="mid-bold">{{ item._count.like }}</span>
          </div>
          <div class="f align-center">
            <img class="info-icon" src="../../assets/images/message.png" />
            <div style="width: 5px"></div>
            <span class="mid-bold">{{ item._count.comment }}</span>
          </div>
        </div>
        <div v-if="this.item.directors" class="f align-center">
          <VueIcon class="info-icon" heigth="32px" width="32px" icon="iconoir:director-chair" style="color: #F8B631">
          </VueIcon>
          <div style="width: 5px"></div>
          <span class="mid-bold">{{ getDirectors() }}&nbsp;</span>
        </div>
        <div v-if="item.genres" class="f align-center" style="margin-top: 6px;">
          <VueIcon class="info-icon" heigth="32px" width="32px" icon="solar:reel-outline" style="color: #F8B631">
          </VueIcon>
          <div style="width: 5px"></div>
          <div>
            <span v-for="(item, index) in item.genres" :key="index" class="mid-bold">{{ item.lang_tr }} &nbsp;</span>
          </div>
        </div>
        <div v-if="item.actors" class="f align-center">
          <VueIcon class="info-icon" heigth="32px" width="32px" icon="material-symbols:star-outline"
            style="color: #F8B631"></VueIcon>
          <div style="width: 5px"></div>
          <span class="mid-bold">{{ getActors() }}</span>
        </div>
        <div class="f">
          <button @click="toggleTrailer(item.trailer_id)" class="trailer_btn" style="margin-top: 6px;">
            <span class="mid-bold">Fragmanı İzle</span>
            <img class="info-icon" src="../../assets/images/play.png" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtil from '../../utils/ImageUtil.js';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    getImageUrl(imageUrl) {
      const str = ImageUtil.getImage(imageUrl, 'full');
      const bg = `linear-gradient(to right, rgba(0, 0, 0, 0.8) 15%, rgba(0, 0, 0, 0) 100%), url(${str}) fixed`
      return bg;
    },
    startAnimation() {
      const col1Element = this.$el.querySelector('.col1')
      col1Element.style.animation = 'title-animation 3s ease'

      setTimeout(() => {
        col1Element.style.animation = 'none'
        void col1Element.offsetWidth
        col1Element.style.animation = 'title-animation 3s ease'
      }, 0)
    },
    getDirectors() {
      if (this.item.director && this.item.director.length > 0) {
        return this.item.director.map(director => director.name).join(", ")
      }
      else return "";
    },
    getActors() {
      if (this.item.actors && this.item.actors.length > 0) {
        return this.item.actors.map(actor => actor.name).join(", ")
      }
      else return "";
    },
    toggleTrailer(id) {
      this.$emit('go-trailer', id);
    }
  },
  watch: {
    item() {
      this.startAnimation();
    }
  },
}
</script>

<style scoped>
h1 {
  color: white;
  font-size: 2.5em;
  font-weight: 800;
  margin-bottom: 0px;
  text-shadow: 2px 2px 2px black;
}

h1 .mid-bold {
  font-size: 24px;
  text-shadow: 2px 2px 2px black;
}

.overview {
  color: white;
  font-size: 18px;
  text-shadow: 2px 2px 2px black;
}

.line-vertical {
  border: 1px solid white;
  color: white;
  height: 25px;
}

.mid-bold {
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  text-shadow: 2px 2px 2px black;
}

.col1 {
  width: 60%;
  padding-top: 8%;
  padding-right: 2%;
  text-align: left;
  animation: title-animation 3s ease;
}

.col1 p {
  font-size: 1.5em;
  text-shadow: 2px 2px 2px black;
}

.movie-featured-movie {
  border-radius: 6px;
  position: relative;
  display: flex;
  min-height: 75vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: featured-anim 70s ease-in infinite;
  overflow: hidden;
  padding-bottom: 1%;
  padding-left: 3%;
}

.movie-featured-movie h1 {
  position: relative;
  z-index: 10;
  text-shadow: 2px 2px 2px black;
}

.featured-movie-info {
  position: relative;
  display: flex;
  background-color: #5c82bc;
  color: white;
  margin: 10px 10px 10px 0px;
  padding: 10px;
  border-radius: 10px;
}

.info-icon {
  height: 32px;
  width: 32px;
}

.trailer_btn {
  background-color: #f8b632;
  height: 45px;
  max-width: 180px;
  width: auto;
  gap: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  transition: 0.4s;
  cursor: pointer;
  box-shadow: 2px 2px 0px 0px rgba(232, 181, 80, 0.4);
}

.trailer_btn:hover {
  background-color: rgb(255, 171, 3);
}

@keyframes title-animation {
  from {
    transform: translateY(-500px);
  }

  to {
    transform: translateY(-0px);
  }
}

@keyframes movie-info-anim {
  from {
    transform: translateY(20px);
  }

  to {
    transform: translateY(-10px);
  }
}

@media (max-width: 35em) {
  @keyframes anim-in {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  @keyframes anim-out {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(100%);
    }
  }
}

@keyframes featured-anim {

  0%,
  100% {
    background-position: center;
  }

  50% {
    background-position: top;
  }
}

@media (max-width: 500px) {
  .col1 {
    width: 75%;
  }

  h1 {
    font-size: 1.5em;
  }

  .overview {
    font-size: 16px;
  }

  .mid-bold {
    font-size: 1rem;
  }

  .info-icon {
    width: 30px;
    height: 30px;
  }

  .movie-featured-movie {
    height: 60vh !important;
    min-height: 60vh !important;
    padding-bottom: 1%;
    padding-left: 3%;
  }
}
</style>

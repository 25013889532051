<template>
    <div v-if="isVisible" class="modal-backdrop">
        <div class="report-modal" :style="{ backgroundColor: modalBackgroundColor }">
            <div class="report-modal-content">
                <h3 class="modal-title">{{ header }}</h3>
                <p class="modal-text">{{ message }}</p>
                <div class="report-options">
                    <label v-for="reason in reasons" :key="reason">
                        <input type="radio" :value="reason" v-model="selectedReason" /> {{ reason }}
                    </label>
                </div>
                <div class="f g5">
                    <button class="report-button" @click="submit">Gönder</button>
                    <button class="close-button" @click="$emit('close')">Kapat</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        duration: {
            type: String,
            required: true,
            default: '4s'
        },
        reasons: {
            type: Array,
            required: true
        },
        isVisible: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            greenColor: '#76ff03',
            redColor: '#cb0000',
            greenAccent: 'rgba(76, 175, 80, 0.95)',
            redAccent: 'rgba(230, 62, 62, 0.95)',
            selectedReason: '',
        }
    },
    computed: {
        modalBackgroundColor() {
            return this.type === 'info' ? '#1A1A1B' : this.redAccent;
        }
    },
    methods: {
        submit() {
            if (this.selectedReason) {
                this.$emit('submitReport', this.selectedReason);
                this.$emit('close');
            } else {
                alert('Please select a reason for reporting.');
            }
        }
    }
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.report-modal {
    width: 350px;
    height: auto;
    background-color: #333;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.report-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.modal-text {
    color: white;
    padding-top: 8px;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
}

.report-options {
    margin-bottom: 1rem;
    text-align: left;
}

.report-options label {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 10px;
}

.report-button,
.close-button {
    color: #f8b632;
    background-color: black;
    font-size: 1rem;
    border: 1px solid #f8b632;
    height: 35px;
    min-width: 91px;
    aspect-ratio: auto;
    cursor: pointer;
    display: flex;
    gap: 0.3rem;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.report-button:hover {
    transition: 0.2s;
    background-color: #f8b632;
    color: black;
}

.close-button:hover {
    background-color: #cb0000;
    color: white;
}
</style>

<template>
    <div class="community_info">
        <div>
            <h3>Hakkımda</h3>
        </div>
        <div class="bottom_info">
            <a>
                <span class="info">Takipçiler: &nbsp; <span class="inner"> {{ item.followers }}</span></span>
            </a>
            <a>
                <span class="info">Takip Edilenler: &nbsp;<span class="inner">{{ item.following }}</span> </span>
            </a>
            <a>
                <span class="info">Beğeniler: &nbsp;<span class="inner">{{ item.total_like }}</span> </span>
            </a>
            <a>
                <span class="info">Yorumlar: &nbsp;<span class="inner">{{ item.total_comment }}</span> </span>
            </a>
            <div>
                <span class="info">Üye Olma Tarihi: {{ getDate(item.created_time) }}</span>
            </div>
        </div>
        <div class="bio" v-if="!bioEditing">
            <h3>Biyografi</h3>
            <p>
                {{ item.bio }}
            </p>
        </div>
        <div class="text_area" v-else>
            <h3>Biyografi</h3>
            <textarea class="title" v-model="editText" style="padding: 0.2em; border: none;" rows="20" required cols="2"
                placeholder="" />
            <div v-if="bioEditing" class="btn_grp">
                <button @click="doEdit" class="a subs">
                    Düzenle
                </button>
                <button @click="startEdit" class="b subs">
                    İptal Et
                </button>
            </div>
        </div>
        <button v-if="!bioEditing" @click="startEdit" class="subs">
            {{ text }}
        </button>
    </div>
</template>

<script>
import { getFormattedDate } from '../../utils/DateUtils.js';

export default {
    data() {
        return {
            join: 'Topluluğa Katıl',
            exit: 'Topluluktan Ayrıl',
            text: 'Edit Your Bio',
            isMember: false,
            bioEditing: false,
            editText: ''
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    methods: {
        getDate() {
            return getFormattedDate(this.item.joined_at)
        },
        startEdit() {
            if (!this.bioEditing) this.editText = this.item.bio;
            this.bioEditing = !this.bioEditing;
        },
        doEdit() {
            if(!this.editText) return;
            this.$emit('edit-bio', this.editText);
            this.bioEditing = false;
        }
    },
    components: {}
}
</script>

<style scoped>
.subs {
    border: none;
    height: 30px;
    border-radius: 2px;
    border: 1px solid #f8b632;
    background-color: #1A1A1B;
    width: 100%;
    color: #f8b632;
    transition: 0.4s;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.subs:hover {
    background-color: #f8b632;
    color: black;
}

.btn_grp {
    display: flex;
    gap: 4px;
}

.a {
    width: 70%;
}

.b {
    width: 30%;
}

.community_info {
    background-color: #1A1A1B;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.8rem 1rem;

    border-radius: 4px;
    display: flex;
    gap: 10px;
    width: 100%;
    min-height: 200px;
}

.community_info p {
    color: rgb(190, 187, 187);
    text-align: left;
    font-size: 13px;
}

.text_area {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 1em;
}

.community_info h3,
h4 {
    font-weight: 700;
    color: rgb(170, 165, 165);
}

.bottom_info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    border-bottom: 1px solid #f8b632;
    padding-bottom: 1em;
}

.info {
    font-size: 14px;
    color: rgb(168, 167, 167);
}

.bio {
    padding-top: 10px;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inner {
    color: #f8b632;
}
</style>
// eslint-disable-next-line vue/multi-word-component-names
<template>
    <div class="outoutside">
        <div class="outside2">
            <div class="loader-clapper">
                <div class="loader-clapper-top">
                    <div class="loader-clapper-top-item"></div>
                    <div class="loader-clapper-top-item"></div>
                    <div class="loader-clapper-top-item"></div>
                </div>
                <div class="loader-clapper-connection">
                    <div class="loader-clapper-dot"></div>
                    <div class="loader-clapper-dot"></div>
                </div>
                <div class="loader-clapper-bottom-top">
                    <div class="loader-clapper-top-item2"></div>
                    <div class="loader-clapper-top-item2"></div>
                    <div class="loader-clapper-top-item2"></div>
                </div>
                <div class="loader-clapper-bottom">
                    <div class="loader-clapper-info"></div>
                    <div class="loader-clapper-info"></div>
                    <div class="loader-clapper-info"></div>
                    <div class="loader-clapper-info"></div>
                    <label for="email"></label>
                    <input type="text" id="email">
                    <label for="password"></label>
                    <input type="password" id="password">
                    <button class="loader-clapper-button-sign"> </button>
                    <button class="loader-clapper-button-register"></button>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.outoutside {
    user-select: none;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.outside2 {
    overflow: hidden;
    display: flex;
    height: 100dvh;
    width: 100dvh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    scale: 0.2;
    position: absolute;
    z-index: 999;
    left: 25%;
}



.loader-clapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0;
}

.loader-clapper-top {
    display: grid;
    width: max(32%, 320px);
    height: 8%;
    background-color: black;
    rotate: -10deg;
    transform-origin: 0;
    padding: 10px;
    grid-template-columns: 20% repeat(3, 20%) 20%;
    grid-template-rows: 100%;
    gap: 1rem;
    animation: loader-clapper 0.65s ease-in-out infinite;

}

.loader-clapper-top-item {
    background-color: white;
    transform: skew(30deg);
    width: 100%;
    height: 100%;
}

.loader-clapper-top>.loader-clapper-top-item:nth-child(1) {
    grid-column: 2/3;
}

.loader-clapper-top>.loader-clapper-top-item:nth-child(2) {
    grid-column: 3/4;
}

.loader-clapper-top>.loader-clapper-top-item:nth-child(3) {
    grid-column: 4/5;

}

.loader-clapper-bottom-top {
    display: grid;
    width: max(32%, 320px);
    height: 8%;
    background-color: black;
    padding: 10px;
    grid-template-columns: 20% repeat(3, 20%) 20%;
    grid-template-rows: 100%;
    gap: 1rem;
}

.loader-clapper-connection {
    position: absolute;
    display: grid;
    grid-template-rows: repeat(3, 33%);
    width: 2rem;
    height: 5rem;
    background-color: black;
    top: 23%;
    left: 30%;
    border-radius: 10px;
    border: 1px solid white;
}

.loader-clapper-dot {
    background-color: gray;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    margin: 8%;
}

.loader-clapper-connection>.loader-clapper-dot:nth-child(1) {
    grid-row: 1/2;
}

.loader-clapper-connection>.loader-clapper-dot:nth-child(2) {
    grid-row: 3/4;
}

.loader-clapper-top-item2 {
    background-color: white;
    transform: skew(-30deg);
    width: 100%;
    height: 100%;

}

.loader-clapper-bottom-top>.loader-clapper-top-item2:nth-child(1) {
    grid-column: 2/3;
}

.loader-clapper-bottom-top>.loader-clapper-top-item2:nth-child(2) {
    grid-column: 3/4;
}

.loader-clapper-bottom-top>.loader-clapper-top-item2:nth-child(3) {
    grid-column: 4/5;

}

.loader-clapper-bottom {
    width: max(30%, 300px);
    height: 300px;
    background-color: black;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: repeat(10, 10%);
    padding: 10px;
    border-radius: 0% 0% 10% 10%;
}

.loader-clapper-bottom>label,
input {
    border: 1px solid white;
    background-color: black;
    color: white;
    height: 25px;
    padding: 0;
    padding-left: 10px;

}

.loader-clapper-bottom>label {
    grid-column: 1/2;
}

.loader-clapper-bottom>input {
    grid-column: 2/4;
}

.loader-clapper-info,
.loader-clapper-info-register {
    border: 1px solid white;
    grid-row: 2/7;

}


.loader-clapper-info-register {
    grid-row: 2/5;
}

.loader-clapper-info-register:nth-child(1) {
    grid-row: 1/2;
    grid-column: 1/4;
    border: none;
}

.loader-clapper-bottom>.loader-clapper-info:nth-child(1) {
    grid-row: 1/2;
    grid-column: 1/4;
    border: none;
}

.loader-clapper-button-sign {
    grid-column: 2/4;
    grid-row: 9/10;
}

.loader-clapper-button-register {
    grid-column: 2/4;
    grid-row: 10/11;
}

.loader-clapper-button-sign,
.loader-clapper-button-register {
    background-color: black;
    border: 1px solid white;
    color: white;
    border-radius: 5px;
    height: 80%;
}



.loader-clapper-top-animation-in {
    animation: loader-clapper 1s ease-in-out infinite;
}



.loader-clapper-top,
.loader-clapper-bottom,
.loader-clapper-bottom-top {
    box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.5);
}

@keyframes loader-clapper {

    0%,
    100% {
        rotate: -10deg;
    }

    50% {
        rotate: 0deg;
    }

}




@media (max-width: 35em) {
    .loader-clapper-connection {


        top: 9rem;
        left: 3rem;

    }
}
</style>
<template>
    <div class="chair-wrapper">
        <div class="chair-top">
            <div class="left-stick">
            </div>
            <div class="user-name">
                {{item}}
            </div>
            <div class="right-stick">
            </div>
        </div>
        <div class="chair-middle">
            <div class="handler-left"></div>
            <div class="seat"></div>
            <div class="handler-right"></div>
        </div>
        <div class="chair-bottom">
            <div class="left-to-right-stick"></div>
            <div class="right-to-left-stick"></div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    props: {
        item: {
            type: String,
            required: true,
            default: ''
        }
    },
}
</script>

<style scoped>


:root{
    --chair-color: pink;
}
.chair-wrapper,
.chair-top,
.user-name,
.chair-middle,
.chair-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chair-wrapper {
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0);
    flex-direction: column;
    height: 70%;
    width: 100%;
}

.chair-top {
    height: 60px;
    width: 140px;
    

}

.left-stick,
.right-stick {
    background-color: #A1662F;
    width: 5%;
    height: 110%;
    border: 1px solid brown;
}

.user-name {
    background-color: black;
    color: white;
    height: 100%;
    width: 90%;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
}

.chair-middle {
    width: 170px;
    height: 130px;
}

.handler-left,
.handler-right {
    position: relative;
    height: 53%;
    border: 8px solid #A1662F;
    border-radius: 5px;
    bottom: 35px;
    width: 30%;
}

.handler-left {
    transform: perspective(100px) rotateY(50deg);
}

.handler-right {
    transform: perspective(100px) rotateY(-50deg);
}


.seat {
    width: 130%;
    height: 60%;
    position: relative;
    top: -8px;
    background-color: black;
    transform: perspective(120px) rotateX(78deg) translateY(10px);
    z-index: 3;
}

.seat::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 27%;
    background-color: black;
    bottom: -10px;
    transform: perspective(120px) rotateX(123deg) translateY(-5px);
    z-index: 3;
    border-radius: 10px;
}

.chair-bottom {
    height: 160px;
    position: relative;
    width: 100%;
    position: relative;
    bottom: 83px;
}

.left-to-right-stick,
.right-to-left-stick {

    border: 1px solid brown;
    transform: skew(50deg);
    border-radius: 20px;
}

.left-to-right-stick {
    position: relative;
    left: 5px;
    width: 3%;
    background-color: #A1662F;
    z-index: 2;
    height: 58%;
}

.right-to-left-stick {
    height: 58%;
    width: 3%;
    background-color: #58381b;
    position: relative;
    bottom: 20px;
    right: 10px;
}

.left-to-right-stick::after {
    content: ' ';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #A1662F;
    border: 1px solid brown;
    transform: skew(113deg);
    border-radius: 20px;

}

.right-to-left-stick::after {
    content: ' ';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #58381b;
    border: 1px solid brown;
    transform: skew(113deg);
    border-radius: 20px;
}
</style>
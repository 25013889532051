<template>
    <NavBar />
    <div class="top_margin_div"></div>
    <div class="wrapper">
        <div class="settings_left">
            <div class="left-top">
                <h1 class="left-title">Ayarlar</h1>
            </div>
            <div class="left-bottom">
                <button @click="setActiveTabPersonal(0)" class="settings-option">
                    <h3 class="title_bottom">Profil Ayarları</h3>
                </button>
                <button @click="setActiveTabPersonal(1)" class="settings-option">
                    <h3 class="title_bottom">Seviye Ayarları</h3>
                </button>
                <button @click="setActiveTabPersonal(2)" class="settings-option">
                    <h3 class="title_bottom">Rozet Ayarları</h3>
                </button>
                <button @click="setActiveTabPersonal(3)" class="settings-option">
                    <h3 class="title_bottom">Bildirimler</h3>
                </button>
            </div>

        </div>
        <div class="settings_right">
            <div v-if="selectedTab == 0">
                <h1 class="right-title">Profil Ayarları</h1>
                <div class="input-wrappers">
                    <div class="input-wrapper">
                        <img :src="this.user.img" alt="" class="profile-picture">
                    </div>
                    <div class="input-wrapper">
                        <label for="username">Kullanıcı Adı:</label>
                        <input type="text" v-model="userUpdateInfo.username" name="username" id="username">
                    </div>
                    <div class="input-wrapper">
                        <label for="email">Email:</label>
                        <input type="text" v-model="userUpdateInfo.email" name="email" id="email">
                    </div>
                    <div class="input-wrapper">
                        <label for="password">Şifre:</label>
                        <input type="password" name="password" id="password">
                    </div>
                    <div class="input-wrapper">
                        <label for="birthdate">Doğum Tarihi:</label>
                        <input type="date" v-model="userUpdateInfo.birthday" name="birthdate" id="birthdate">
                    </div>
                    <button @click="updateFields" class="settings-option">KAYDET</button>
                </div>

            </div>

            <div v-if="selectedTab == 1">
                <div class="right-top">
                    <h1 class="right-title">Seviyeler</h1>
                </div>

                <div class="right-bottom">
                    <div v-if="badges && badges.length > 0" class="progress-bar">
                        <div v-for="(item, index) in badges" :key="index" class="progress-stick">
                            <img :src="item.image" alt="" class="badge">
                            <div class="badge-text">{{ item.name }}</div>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="selectedTab == 2">
                <h1 class="right-title">Rozet Ayarları</h1>
                <div class="badges-wrapper">
                    <div v-for="(item, index) in badges" :key="index" class="badge-wrapper">
                        <h3 class="badge-title">{{ item.name }}</h3>
                        <img :src="item.image" alt="" class="badge-image">
                        <div class="badge-description">{{ item.info }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <InfoModal :duration="'2s'" :type="'info'" v-if="showInfoModal" :message="infoMessage"
        @close="showInfoModal = false" />
    <FooterBar />
</template>

<script>

import avatar from '../assets/images/avatar.png';

import { useUserStore } from '../store/user.js'
import { getFormatYearMonth } from '../utils/DateUtils.js';

import UserService from '../services/UserService';
import ImageUtil from '../utils/ImageUtil';

export default {
    data() {
        return {
            loginMessage: 'Please login to view your user details.',
            avatarImage: avatar,
            communities: [],
            showInfoModal: false,
            modalDuration: 2000,
            infoMessage: 'Bilgileriniz güncellendi.',
            user: {
                username: 'numan',
                point: 50,
                joined_date: '2022',
                rank: '',
            },
            selectedTab: 0,
            badges: [],
            userUpdateInfo: {
                username: '',
                email: '',
                password: '',
                birthday: ''
            }
        }
    },
    methods: {
        openInfoModal(msg) {
            this.showInfoModal = true;
            this.infoMessage = msg;
            setTimeout(() => {
                this.showInfoModal = false;
            }, this.modalDuration);
        },
        getImageUrl(imageUrl) {
            if (!imageUrl) return;
            const startIndex = imageUrl.indexOf('@.')
            let str = imageUrl.slice(0, startIndex + 1)
            str += '.jpg'
            return str
        },
        async setActiveTabPersonal(tab) {
            this.selectedTab = tab;
        },
        async getUserDetails() {
            const response = await UserService.getUserDeepDetails();
            if (!response) return;
            this.getBadge();
            this.user = response;
        },
        getBadge() {
            if (!this.user.rank) return ImageUtil.discover;
            this.user.rank.badge = ImageUtil.getBadge(this.user.rank.id);
        },
        async updateFields() {

            const response = await UserService.updateUser({
                username: this.userUpdateInfo.username,
                email: this.userUpdateInfo.email,
                birthdate: this.userUpdateInfo.birthday,
            });

            if (!response) return this.openInfoModal('Hata oluştu.');
            return this.openInfoModal('Bilgileriniz başarıyla güncellendi.');;
        }
    },
    async created() {
        this.user = useUserStore().user;
        await this.getUserDetails();
        if (!this.user) return;
        this.badges = ImageUtil.iconMappings;
        this.userUpdateInfo.username = this.user.username;
        this.userUpdateInfo.email = this.user.email;
        this.userUpdateInfo.birthday = getFormatYearMonth(this.user.birthday)
    },
    components: {}
}
</script>

<style scoped>
.wrapper {
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: row;
    border-radius: 3px;
    padding-left: 2%;
    padding-right: 2%;
}

.badges-wrapper::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari ve Opera */
}

.settings_left {
    width: 25%;
}

.left-title {}

.left-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20%;
    width: 100%;
}

.left-bottom {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
}

.settings-option {
    cursor: pointer;
    background-color: #f8b632;
    border: none;
    padding: 3% 4%;
    border-radius: 1px;
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: bold;
}

.settings-option:hover {
    background-color: black;
    border: 1px solid #f8b632;
    transition: 0.2s;
}

.settings-option:hover .title_bottom {
    color: white;
}

.settings_right {
    display: flex;
    flex-direction: column;
    width: 75%;
    gap: 1rem;
    padding: 1%;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
}

input {
    width: 50%;
    padding: 1%;
    border-radius: 5px;
}

.save-button {
    align-self: flex-start;
    padding: 1%;
    width: 10%;
    cursor: pointer;
}

.profile-picture {
    width: 10%;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid black;
    outline: 1px solid black;
    outline-offset: 5px;
}

.progress-bar {
    margin-top: 20%;
    height: 20px;
    background-color: #f8b632;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.progress-stick {
    height: 150%;
    width: 5px;
    background-color: #fbd384;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.badge {
    position: absolute;
    aspect-ratio: 1;
    width: 50px;
    bottom: 30px;
    border-radius: 30%;
}

.badge-text {
    position: absolute;
    top: 30px;

}

.badges-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 550px;
    width: 100%;
    gap: 1rem;
    overflow-y: scroll;
    padding: 0.2rem;
}

.badge-wrapper {
    border: 1px solid #f8b632;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 1%;
    gap: 10px;
}

.badge-title {
    text-align: center;
    color: #f8b632;
}

.badge-image {
    width: 100px;
    height: 100px;
}

hr {

    border: 3px solid #fbd384;
}

.badge-description {
    text-align: center;
}

.input-wrappers {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2%;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 20px;
    }

    .settings_left {
        width: 30%;
    }

    .left-bottom {}

    .settings-option {
        padding: 1%;
        font-size: 12px;
    }

    .right-top {
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .right-bottom {
        display: flex;
        height: 400px;
        align-items: center;
        width: 100%;
    }

    .progress-bar {
        transform: rotateZ(90deg);
        gap: 2.5rem;
        width: 300%;
        position: relative;
        right: 100px;
        margin-top: 100%;
    }

    .badge,
    .badge-text {
        transform: rotateZ(270deg);

    }

    .badge-text {
        top: 50px;
    }

    .badge-wrapper {
        width: 40%;

    }

    .badge-image {
        width: 70%;
    }

    .badge-title {
        font-size: 1rem;
    }

    .badge-description {
        font-size: 0.8rem;
    }

    .profile-picture {
        width: 30%;
    }

    .save-button {
        align-self: flex-end;
        width: 60%;
        padding: 3%;
        background-color: #fbd384;
    }

    .input-wrappers {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2%;
    }

    input {
        width: 70%;
    }
}
</style>
<template>
    <div class="main_wrap">
        <div class="film-top-bottom">
            <div v-for="(index) in reelCount" :key="index" class="film-top-bottom-element"></div>
        </div>

        <div class="slider-container">
            <div class="slider" :style="{ transform: 'translateX(' + currentSlide * -100 + '%)' }">
                <div v-for="(item, index) in items" :key="index" class="community-item"
                    :style="{ backgroundImage: `url(${item.img === '' ? defaultIcon : item.img})` }" @click="goCommunity(item.community_id)">
                    <div class="community-info">
                        <h5>{{ item.name }}</h5>
                    </div>
                    <div class="rank"><span class="rank_number">{{ index + 1 }}</span></div>
                </div>

            </div>
            <div class="navigation">
                <button @click="prevSlide" class="nav_btn">
                    <div class="nav_arrow left"></div>
                </button>
                <button class="nav_btn" @click="nextSlide">
                    <div class="nav_arrow right">
                    </div>
                </button>
            </div>
        </div>

        <div class="film-top-bottom">
            <div v-for="(index) in reelCount" :key="index" class="film-top-bottom-element"></div>
        </div>
    </div>
</template>
  
<script>
import defaultIcon from '../../assets/images/focus-group.png';

export default {
    props: {
        items: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            reelCount: 0,
            currentSlide: 0,
            windowWidth: 1280,
            visibleMovieCount: null,
            filmBoxWidth: 230,
            showedCount: 0,
            defaultIcon
        }
    },
    methods: {
        nextSlide() {
            if (this.currentSlide + 1 >= Math.ceil(this.movieUpperLimit)) {
                this.currentSlide = 0
            } else {
                this.currentSlide += 1
            }
        },
        prevSlide() {
            if (this.currentSlide == 0) return (this.currentSlide = Math.floor(this.movieUpperLimit))
            this.currentSlide -= 1
        },
        checkVisibleMovie() {
            this.movieUpperLimit = this.items.length / this.visibleMovieCount
        },
        setVisibleCount() {
            this.windowWidth = window.innerWidth
            this.visibleMovieCount = this.windowWidth / this.filmBoxWidth
        },
        setReelCount() {
            this.reelCount = Math.floor(this.windowWidth / 25)
        },
        goCommunity(communityID) {
            if (communityID == 0) return;
            return this.$emit('goLink', communityID);
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
            this.visibleMovieCount = this.windowWidth / this.filmBoxWidth
            this.movieUpperLimit = this.items.length / this.visibleMovieCount
            this.reelCount = Math.floor(this.windowWidth / 25)
        })
    },
    created() {
        this.windowWidth = window.innerWidth
        this.showedCount = this.windowWidth / this.filmBoxWidth
        this.setVisibleCount()
        this.checkVisibleMovie()
        this.setReelCount()
    }
}
</script>
  
<style scoped>
.film-top-bottom {
    display: flex;
    align-items: center;
    height: 2rem;
    width: 100%;
    gap: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    background-color: black;
}

.main_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

h5 {
    text-shadow: 3px 3px 3px black;
    font-weight: 600;
}

.rank {
    position: relative;
    top: 0px;
    left: 5px;
}

.rank_number {
    color: white;
    font-weight: 950;
    font-size: 32px;
    text-shadow: 4px 3px 4px black;
}

.slider {
    gap: 1%;
}

.navigation {
    position: absolute;
    top: 40%;
    width: 100%;
    height: 10px;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.community-item {
    border-radius: 4px;
    width: 230px;
    height: 160px;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.community-item:hover {
    border-radius: 6px;
    transform: scale(1.2);
    transition: 0.3s ease-in-out;
    z-index: 999;
}

.community-item:not(:hover) {
    border-radius: 6px;
    transform: scale(1);
    transition: 0.3s ease-in-out;
    z-index: 1;
}

.community-item:hover img {
    border-radius: 6px;
    transition: 0.3s ease-in-out;
}

.community-info {
    position: absolute;
    bottom: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0);
    font-size: 20px;
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 2%;
    padding-top: 2%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.community-item:hover .community-info {
    transition: 0.3s ease-in-out;
    padding-bottom: 8%;
}

.nav_arrow {
    background-color: red;
    outline: none;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border-top: 4px #f8b632 solid;
    border-left: 4px #f8b632 solid;
    border-bottom: none;
    border-right: none;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.nav_arrow.left {
    transform: rotate(-45deg);
}

.nav_arrow.right {
    transform: rotate(135deg);
}

.nav_arrow.left:hover {
    transform: rotate(-45deg);
}

.nav_arrow.right:hover {
    transform: rotate(135deg);
}

.nav_btn {
    display: flex;
    align-items: center;
    height: 100px;
    background-color: transparent;
    border: none;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.nav_btn:hover {
    transform: scale(1.2);
}
</style>
  
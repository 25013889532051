import { apiInstance } from '../services/ApiService.js'

const API_URL = '/watchlist';

const getWatchlist = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/', {
            params: {
                id: req.id,
            }
        })
        if (!response.data.success) return []
        return response.data.data
    } catch (error) {
        return []
    }
}

const deleteWatchlistMovie = async (req) => {
    try {
        const response = await apiInstance.delete(API_URL + '/', {
            params: {
                movie_id: req.movie_id,
                watchlist_id: req.watchlist_id
            }
        })
        if (!response.data.success) return false;
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

const addWatchlistMovie = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + '/add', {

            movie_id: req.movie_id,
            watchlist_id: req.watchlist_id

        });
        if (!response.data.success) return false;
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

const editWatchlistName = async (req) => {
    try {
        const response = await apiInstance.put(API_URL + '/', {
            name: req.name,
            watchlist_id: req.watchlist_id
        });
        if (!response.data.success) return false;
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

const getWatchlistAll = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/get-all', {
            params: {
                sortBy: req.sortBy,
                dir: req.dir
            }
        })
        if (!response.data.success) return []
        return response.data.data
    } catch (error) {
        return []
    }
}

const likeWatchlist = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/like', {
            params: {
                watchlist_id: req.watchlist_id
            }
        });
        if (!response.data.success) return false;
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

const commentWatchlist = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + '/comment', {
            watchlist_id: req.watchlist_id,
            comment: req.comment
        });
        if (!response.data.success) return false;
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

const createWatchlist = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + '/create', {
            name: req.name,
            movies: req.movies
        });
        if (!response.data.success) return false;
        return response.data
    } catch (error) {
        console.log(error)
        return false
    }
}

const getWatchlistComments = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/comments', {
            params: {
                sortBy: req.sortBy,
                dir: req.dir,
                watchlist_id : req.watchlist_id,
                limit: req.limit,
                page: req.page
            }
        })
        if (!response.data.success) return []
        return response.data.data
    } catch (error) {
        return []
    }
}

const getUserAllWatchlist = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/user/watchlists', {
            params: {
                sortBy: req.sortBy,
                dir: req.dir
            }
        })
        if (!response.data.success) return []
        return response.data.data
    } catch (error) {
        return []
    }
}

const PublicWatchlistService = {
    getWatchlist,
    deleteWatchlistMovie,
    addWatchlistMovie,
    editWatchlistName,
    getWatchlistAll,
    likeWatchlist,
    commentWatchlist,
    getUserAllWatchlist,
    getWatchlistComments,
    createWatchlist
}

export default PublicWatchlistService

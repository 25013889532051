import { apiInstance } from './ApiService.js'

const API_URL = '/auth'

const google = async (req) => {

    try {

        const response = await apiInstance.post(API_URL + '/google', {
            code: req.code
        });

        if (!response.data) return false;

        return response.data.success;
    } catch (error) {
        return false;
    }
}

const registerWithMail = async (req) => {

    try {

        const response = await apiInstance.post(API_URL + '/register', {
            user: req.user
        });

        if (!response.data) return false;

        return response.data.success;
    } catch (error) {
        return false;
    }
}

const signWithMail = async (req) => {

    try {

        const response = await apiInstance.post(API_URL + '/sign-in', {
            mail: req.mail,
            password: req.passcode,
        });

        if (!response.data) return false;
        return response.data.success;
    } catch (error) {
        return false;
    }
}

const AuthService = {
    google,
    registerWithMail,
    signWithMail
}

export default AuthService;
import { apiInstance } from './ApiService.js'

const API_URL = '/post'

const getOnePost = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/', {
            params: {
                post_id: req.post_id,
                community_id: req.community_id
            }
        })
        if (!response.data.success) return null
        return response.data.post
    } catch (error) {
        return null
    }
}

const getPosts = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/all', {
            params: {
                limit: req.limit,
                order_by: req.order_by,
                order_dir: req.order_dir,
                community_id: req.community_id
            }
        })
        if (!response.data.success) return []
        return response.data.posts
    } catch (error) {
        return []
    }
}

const getCommunityPosts = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/getAllByParam', {
            params: {
                limit: req.limit,
                order_by: req.order_by,
                order_dir: req.order_dir,
            }
        })
        if (!response.data.success) return []
        return response.data.posts
    } catch (error) {
        return []
    }
}

const createPost = async (req) => {
    try {
        const response = await apiInstance.post(API_URL + '/', {
            community_id: req.community_id,
            user_id: req.user_id,
            title: req.title,
            content: req.content
        });
        
        if (!response.data.success) return false;

        return response.data.meta_data;
    } catch (error) {
        return false
    }
}

const getUserPosts = async (req) => {

    try {

        const response = await apiInstance.get(API_URL + '/user', {
            params: {
                limit: req.limit,
                order_by: req.order_by,
                order_dir: req.order_dir,
                user_id: req.user_id
            }
        })
        if (!response.data.success) return []
        return response.data
    } catch (error) {
        return []
    }
}

const votePost = async (req) => {

    try {

        const response = await apiInstance.get(API_URL + '/vote', {
            params: {
                community_id: req.community_id,
                user_id: req.user_id,
                post_id: req.post_id,
                vote: req.vote
            }
        });

        return response.data.success;
    } catch (error) {
        return false;
    }
}

const PostService = {
    getCommunityPosts,
    getPosts,
    getOnePost,
    createPost,
    votePost,
    getUserPosts
}

export default PostService
<template>
  <div class="filter_bar">
    <div @click="sortBy(item.type)" v-for="(item, index) in items" :key="index" class="filter_item">
      <img alt="icon" class="filter_icon" :src="item.url" />
      <span>{{ item.langTR }}</span>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    sortBy(type) {
      this.$emit('sort', type);
    },
  },
  async created() { },
  components: {}
}
</script>

<style scoped>
.filter_bar {
  display: flex;
  align-items: center;
  gap: 4%;
  width: 100%;
  height: 3rem;
  padding: 1rem;
  background-color: #1A1A1B;
  border: 1px rgb(58 58 58) solid;
  border-radius: 6px;
  color: white;
  z-index: 10;
}

.filter_icon {
  width: 24px;
  height: 24px;
}

.filter_item {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  padding: 0.4rem 0.6rem;
  border-radius: 4px;
  cursor: pointer;
}

.filter_item:hover {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  background-color: #f8b632;
  color: black;
}
</style>

import discover from '../assets/badges/discover.png';
import drama from '../assets/badges/drama.png';
import writer from '../assets/badges/screenplay.png';
import criticism from '../assets/badges/criticism.png';
import director from '../assets/badges/directors-chair.png';
import star from '../assets/badges/hollywood.png';
import oscar from '../assets/badges/oscar.png';
import classic from '../assets/badges/classic.png';
import popcorn from '../assets/badges/popcorn.png';
import sinefil from '../assets/badges/action.png';
import mafia from '../assets/badges/mafioso.png';


const iconMappings = [
    {
        id: 1,
        name: "Film Kaşifi",
        icon: "iconamoon:discover",
        image: discover,
        info: "Topluluğa yeni katılan ve film dünyasını keşfetmeye başlayan üyeler."
    },
    {
        id: 2,
        name: "Sahne Sanatçısı",
        icon: "lucide:drama",
        image: drama,
        info: "Düzenli olarak film izleyen ve yorumlarını paylaşan aktif üyeler."
    },
    {
        id: 3,
        name: "Senarist",
        icon: "mingcute:paper-line",
        image: writer,
        info: "Film analizi ve senaryo tartışmalarında deneyimli üyeler."
    },
    {
        id: 4,
        name: "Eleştirmen",
        icon: "material-symbols:person-outline",
        image: criticism,
        info: "Film eleştirileri ve incelemelerinde uzmanlaşmış üyeler."
    },
    {
        id: 5,
        name: "Yönetmen",
        icon: "iconoir:director-chair",
        image: director,
        info: "Topluluk düzenini sağlayan ve etkinlikleri organize eden moderatörler."
    },
    {
        id: 6,
        name: "Film Yıldızı",
        icon: "material-symbols:star-outline",
        image: star,
        info: "Topluluğun en aktif ve tanınmış üyeleri."
    },
    {
        id: 7,
        name: "Oscar Adayı",
        icon: "healthicons:award-trophy-outline",
        image: oscar,
        info: "Topluluk içinde ödüle değer bulunan üyeler."
    },
    {
        id: 8,
        name: "Kült Klasik",
        icon: "wpf:pas",
        image: classic,
        info: "Topluluk tarafından en çok sevilen ve referans gösterilen üyeler."
    },
    {
        id: 9,
        name: "Popcorn Patronu",
        icon: "ph:popcorn",
        image: popcorn,
        info: "Topluluğun en çok film önerisi yapan üyeleri."
    },
    {
        id: 10,
        name: "Sinefil Savaşçı",
        icon: "solar:reel-broken",
        image: sinefil,
        info: "Film tartışmalarında cesurca görüşlerini savunan üyeler."
    },
    {
        id: 11,
        name: "Patron",
        icon: "solar:reel-broken",
        image: mafia,
        info: "Topluluğun lideri ve karar verici."
    }
];


function getImage(imageUrl, resolution) {
    let str = '';
    if (!imageUrl) return '';
    if (resolution === 'full') {

        if (imageUrl && imageUrl.includes('@.')) {
            const startIndex = imageUrl.indexOf('@.')
            str = imageUrl.slice(0, startIndex + 1)
            str += '.jpg';
        }
        if (imageUrl.includes('._')) {
            const startIndex = imageUrl.indexOf('._');

            str = imageUrl.slice(0, startIndex) + '.jpg';
        }

    }

    return str;
}

function getBadge(rankID) {

    if (!rankID) return discover;

    const item = iconMappings.find(e => e.id == rankID);

    if (!item) return discover;
    return item.image;

}

const ImageUtil = {
    getImage,
    getBadge,
    discover,
    iconMappings
}

export default ImageUtil;
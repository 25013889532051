<template>
    <div class="shimmer-container">
        <div class="card"></div>
    </div>
</template>

<style scoped>

.shimmer-container {
    background: #21242c;
    width: 100%;
    height: 100%;
    gap: 15px;
    padding: 0.8rem;
}

.card {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, #a6a4a4, transparent);
    background-size: 200% 100%;
    animation: shimmer 1.8s infinite;
    margin-bottom: 10px;
}

@keyframes shimmer {
    to {
        background-position: 200% 0;
    }
}
</style>
<template>
    <div class="pagination">
        <a @click="$emit('movePage', 'left_right', -1)" class="previous">
            <VueIcon icon="mdi:arrow-left" width="20px" color="white" />
        </a>
        <a v-for="(item, index) in numbers" :key="index" @click="$emit('movePage', 'normal', item)"
        class="btn" :class="{ 'active': pageNumber === item }">{{ item }}</a>
        <a @click="$emit('movePage', 'left_right', 1)" class="next">
            <VueIcon icon="mdi:arrow-right" width="20px" color="white" />
        </a>
    </div>
</template>

<script>

export default {
    name: 'PaginationBar',
    props: {
        pageNumber: {
            type: Number,
            required: true
        },
        numbers: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isActive: false
        }
    },
    methods: {

    }

};
</script>

<style scoped>
.pagination {
    text-align: center;
    display: flex;
    gap: 2px;
}

.pagination a {
    font-family: "Open Sans", sans-serif;
    color: white;
    height: 40px;
    width: 40px;
    text-decoration: none;
    position: relative;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 400;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
}

.pagination a:hover {
    border: 1px solid #f8b632;
    transition-duration: 0.1s;
    cursor: pointer;
}

.active {
    background: #f8b632;
    transition-duration: 0.1s;
}
</style>
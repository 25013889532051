<template>
    <div class="go_login">
        <div class="info-modal-content">
           <p>{{ message }}</p>
        </div>
        <CustomButton :message="subMessage" @action="action" />
    </div>
</template>
  
<script>
import CustomButton from './CustomButton.vue';

export default {
    props: {
        message: {
            type: String,
            required: true,
            default: ''
        },
        subMessage: {
            type: String,
            required: true,
            default: ''
        },
        action: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            greenColor: '#76ff03',
            redColor: '#cb0000',
            greenAccent: 'rgba(76, 175, 80, 0.95)',
            redAccent: 'rgba(230, 62, 62, 0.95)'
        };
    },
    components: { CustomButton }
};
</script>
  
<style scoped>

.go_login {
    width: 100%;
    background-color: #1A1A1B;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
    gap: 10%;
}
.info-modal {
    width: 300px;
    height: 100px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    background-color: rgba(76, 175, 80, 0.95);
    color: white;
    font-weight: 600;
    border-radius: 5px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0.8rem 1rem 0.8rem;
}

.info-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    color: white;
    padding-top: 8px;
    font-weight: 700;
}

.loading-bar {
    position: relative;
    height: 12px;
    background-color: #f0f0f0;
    overflow: hidden;
    border-radius: 6px;
}

.fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #76ff03;
    animation: fillAnimation normal;
}

@keyframes fillAnimation {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
</style>
  
<template>
    <div class="wrap" @mouseenter="toggleBorder" @mouseleave="toggleBorder" :style="{ 'border-color': clicked ? '#f8b632' : '#5a5959' }">
        <button type="submit" class="searchButton">
            <img class="small-icon" src="../../assets/images/search.png" />
        </button>
        <div class="search">
            <input type="text" class="searchTerm" :placeholder="placeholder" v-model="searchTerm" @input="onInput">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        refFunction: Function,
        placeholder: {
            type: String,
            default: 'Ara...'
        }
    },
    data() {
        return {
            searchTerm: '',
            clicked: false
        };
    },
    methods: {
        onInput() {
            this.$emit('searchMovie', this.searchTerm)
        },
        toggleBorder() {
            this.clicked = !this.clicked;
        },
    },
};
</script>


<style scoped>
.wrap {
    border: 1px solid #f8b632;
    transition: transform 0.1s ease-in-out;
}

.search {
    width: 100%;
    display: flex;
}

.searchTerm {
    width: 100%;
    border: none;
    padding: 10px;
    height: 40px;
    border-radius: 10px;
    outline: none;
    background-color: transparent;
    color: #f8b632;
    font-weight: 700;
}

.searchButton {
    width: 30px;
    height: 30px;
    background-color: transparent;
    text-align: center;
    border-radius: 40%;
    cursor: pointer;
    font-size: 20px;
    border: none;
    display: grid;
    align-items: center;
}

.searchTerm {
    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-left: none;
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;

}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange;
    }
}

.searchButton:hover {
    transform: scale(1.05);
    transition: transform 0.1s ease-in-out;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 2px solid #5a5959;
    padding: 10px;
    height: 40px;
    border-radius: 10px;
}

.wrap img {
    height: 18px;
    width: 18px;
}

.wrap:hover {
    border-color: #f8b632;
    transition: transform 0.1s ease-in-out;
}

@media (max-width: 590px) {
    .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 2px solid rgb(90, 89, 89);
        padding: 10px;
        height: 35px;
        border-radius: 10px;
    }
}
</style>
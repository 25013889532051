<template>
    <NavBar />
    <div class="wrapper">
        <div class="top_margin_div"></div>
        <div style="width: 100%; min-height: 50vh;margin-top: 30px;">
            <FeaturedCommunity v-if="!featureLoading" :item="featuredCommunities[6]" @goLink="goCommunity"
                :key="featuredIndex" />
        </div>
        <div class="search_div">
            <div class="left">
                <h3>Toplulukları Keşfet</h3>
                <div style="height: 10px;"></div>
                <SearchBar :placeholder="'Topluluk ara...'" @searchMovie="searchCommunity" />
                <div class="container" :key="animateMovies">
                    <CommunityCard @action="null" @go="goCommunity" :item="item" v-for="(item, index) in communities"
                        :key="index" />
                </div>
            </div>
            <div class="right">
                <h3>Popüler Topluluklar</h3>
                <div style="height: 10px;"></div>
                <CommunityBlock @go="goCommunity" :tabs="tabs" :items="featuredCommunities" />
                <div style="height: 30px;"></div>
                <div>
                    <h3>Bunlar İlgini Çekebilir</h3>
                </div>
                <div style="height: 10px;"></div>
                <UserInterest :items="featuredCommunities" />
            </div>
        </div>
    </div>
    <FooterBar />
</template>

<script>
import SearchBar from '../components/Movies/SearchBar.vue';
import CommunityCard from '../components/CommunitySearch/CommunityCard.vue';
import CommunityService from '../services/CommunityService';

import { defineAsyncComponent } from 'vue'

const FeaturedCommunity = defineAsyncComponent(() => import('../components/Community/FeaturedCommunity.vue'))
const CommunityBlock = defineAsyncComponent(() => import('../components/CommunitySearch/CommunityBlock.vue'))
const UserInterest = defineAsyncComponent(() => import('../components/CommunitySearch/UserInterest.vue'))

export default {
    data() {
        return {
            featuredIndex: 0,
            animateMovies: true,
            search: '',
            searchTimeout: null,
            selectedMovie: {},
            selectedGenres: [],
            scoreItems: [
                { label: 'IMDB', value: 5, min: 0, max: 10, step: 0.1 },
                { label: 'Community Score', value: 5, min: 0, max: 10, step: 0.1 },
                { label: 'Meta Score', value: 5, min: 0, max: 10, step: 0.1 },
            ],
            sortOptions: [
                { id: 0, option: 'Release Date' },
                { id: 1, option: 'IMDB' },
                { id: 2, option: 'Community Score' },
                { id: 3, option: 'Duration' },
            ],
            communityFilters: {
                orderBy: 'top',
                orderDir: 'desc'
            },
            selectedSort: 'IMDB',
            orderBy: 'imdb',
            currentSlide: 0,
            topCommLoading: false,
            featureLoading: true,
            communities: [],
            featuredCommunities: [],
            tabs: [
                { name: 'Trendler', id: 1 },
                { name: 'Öne Çıkanlar', id: 2 },
                { name: 'En Aktifler', id: 3 },
            ],
            communityLimit: 10,
            joinLoading: false
        }
    },
    methods: {
        async getCommunitiesBySearch() {
            this.topCommLoading = true;
            const response = await CommunityService.getAll({
                limit: this.communityLimit,
                order_by: this.communityFilters.orderBy,
                order_dir: this.communityFilters.orderDir,
                search: this.search
            })
            if (!response) return
            this.communities = response;
            this.topCommLoading = false;
        },
        async getFeaturedCommunities() {
            this.featureLoading = true;
            const response = await CommunityService.getAll({
                limit: this.communityLimit,
                order_by: this.communityFilters.orderBy,
                order_dir: this.communityFilters.orderDir,
                search: this.search
            })
            if (!response) return
            this.featuredCommunities = response;
            this.featureLoading = false;
        },
        async searchCommunity(searchTerm) {
            this.search = searchTerm;
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(async () => {
                this.getCommunitiesBySearch();
            }, 1000);
        },
        goCommunity(communityID) {
            if (communityID == -1) return
            this.$router.push({ name: 'community-detail', params: { id: communityID } })
        },
        async setFeatured() {
            const delay = 8000;
            setInterval(() => {
                if (this.featuredIndex + 1 === this.featuredCommunities.length) {
                    this.featuredIndex = 0;
                } else {
                    this.featuredIndex += 1;
                }
            }, delay)
        },
    },
    async created() {
        await this.getCommunitiesBySearch();
        await this.getFeaturedCommunities();
        this.setFeatured();
    },
    components:
    {
        SearchBar,
        CommunityCard,
        FeaturedCommunity,
        CommunityBlock,
        UserInterest
    }
}
</script>

<style scoped>
h3 {
    font-weight: bold;
    color: azure;
}

.wrapper {
    overflow-x: hidden;
    padding: 1rem;
}

.left {
    width: 75%;
    display: flex;
    flex-direction: column;
}

.right {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 140vh;
}

.container {
    padding-top: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: grid;
    height: auto;
    gap: 15px 15px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 350px;
    justify-content: flex-start;
    animation: fade-movie 0.5s ease 1s forwards;
    transition: 0.2s ease-in-out;
}

.container:not(hover) {
    transition: 0.5s ease-in-out;
}

.top_margin_div{
        height: 100px;
    }

@keyframes fade-movie {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.mid-font {
    font-size: 2.4vh;
    color: white;
    font-weight: 600;
    padding-left: 0px;
}

.body-font {
    font-size: 2vh;
    color: white;
    font-weight: 600;
    padding-left: 0px;
}

.gray {
    font-size: 2vh;
    color: gray;
    font-weight: 600;
}

.bold {
    font-weight: bold;
}

.search_div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0rem 0rem;
    gap: 2%;
    width: 100%;
    min-height: 100vh;
}

.filters {
    width: 20%;
    min-height: 20vh;
    max-height: 100vh;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 10px;
    background-color: rgb(40, 37, 37);
}

.genre-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow-y: scroll;
    max-height: 45vh;
}

.genre-list li {
    cursor: pointer;
    padding: 3px 5px;
    border-radius: 0px;
}

.genre-list li:hover {
    color: #f8b632;
}

.genre-list li.active {
    color: #f8b632;
}

.range input {
    width: 100%;
}

.range input[type="range"] {
    accent-color: #f8b632;
}

.score {
    margin-top: 10px;
}

.filter_options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 70%;
}

.genre {
    background-color: #f8b632;
    color: white;
    min-width: 24px;
    height: 24px;
    border-radius: 15px;
    padding: 3px 6.85px;
    font-size: 13px;
    font-weight: 400;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop_wrap {
    width: 30%;
    justify-content: flex-end;
    gap: 10px;
}

@media screen and (max-width: 768px) {
    .search_div {
        flex-direction: column;
    }

    .left,
    .right {
        width: 100%;
    }

    .container {
        padding-top: 10px;
        gap: 8px;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 300px;
        margin-bottom: auto;
    }

    .wrapper {
        padding: 0.4rem;
    }

    .top_margin_div{
        height: 0vh;
    }
}
</style>
<template>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
    <NavBar />
    <div class="contact-top">
        <div class="contact-top-image" :style="{ backgroundImage: `url(${contactbackground})` }">
        </div>
        <div class="image-text">CONTACT WITH US</div>
    </div>


    <div class="contact">
        <div class="contact-left">
            <h1>LET US KNOW</h1>
            <p>We would like to improve ourselves in this field so don't hesitate contacting with us!</p>
        </div>
        <div class="contact-right">
            <h1>Your Message:</h1>
            <textarea></textarea>
            <button class="send-button">SEND</button>
        </div>
    </div>
    <div class="socials-wrapper">
        <div class="socials-text">FOLLOW US ON SOCIALS</div>
        <div class="socials">
            <ul>
                <li>
                    <a href="">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="fa fa-instagram"></span>
                    </a>
                </li>
                <li>
                    <a href="">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="fa fa-youtube-play"></span>
                    </a>
                </li>
                <li>
                    <a href="">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="fa fa-twitter"></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="links">
        <div class="numan">
            <img src="../assets/images/avatar.png" alt="numan" class="numan-img">
            <h1 class="numan-name">Numan KILINÇOĞLU</h1>
            <h2 class="numan-role">Developer</h2>
            <a href=""><img src="../assets/images/github.png" class="numan-github"></a>
            <a href=""><img src="../assets/images/linkedin.png" alt="" class="numan-linkedin"></a>
        </div>
        <div class="mali">
            <img src="../assets/images/avatar.png" alt="mali" class="mali-img">
            <h1 class="mali-name">Muhammed Ali GÜLCEMAL</h1>
            <h2 class="mali-role">Developer</h2>
            <a href=""><img src="../assets/images/github.png" alt="" class="mali-github"></a>
            <a href=""><img src="../assets/images/linkedin.png" alt="" class="mali-linkedin"></a>
        </div>
    </div>
    <FooterBar />
</template>

<script>
import contactbackground from '../assets/images/contactbackground.jpeg'


export default {
    data() {
        return {
            movies: [],
            loading: false,
            title: 'Most Viewed',
            signClick: true,
            registerClick: false,
            registering: false,
            signed: false,
            registered: true,
            signCounter: 1,
            registerCounter: 0,
            contactbackground: contactbackground,

        }
    },
    methods: {

    },

    components: {}
}
</script>
<style>

h2 {
    color: black;
}
.socials-wrapper {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #222;
    width: 100%;
}

.socials {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
    width: 100%;
}

.socials-text {
    position: relative;
    min-height: 10dvh;
    color: white;
    top: 50px;
}

.socials ul {
    position: relative;
    display: flex;
    justify-content: center;
    transform-style: preserve-3d;
    transform: rotateX(-25deg) skew(25deg);
}

.socials ul li {
    position: relative;
    list-style: none;
    width: 60px;
    aspect-ratio: 1;
    margin: 0 30px;
}

.socials ul li:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: #2a2a2a;
    transform-origin: top;
    transform: skewX(-41deg);
}

.socials ul li:after {
    content: '';
    position: absolute;
    top: 0;
    left: -9px;
    width: 9px;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform-origin: right;
    transform: skewY(-49deg);
}
.socials ul li span {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: #333;
    color: rgba(255, 255, 255, 0.2);
    font-size: 30px !important;
}

.socials ul li:hover span {
    z-index: 1000;
    transition: 0.2s;
    color: #fff;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.05);
}

.socials ul li:hover span:nth-child(5) {
    transform: translate(40px, -40px);
    opacity: 1;
}

.socials ul li:hover span:nth-child(4) {
    transform: translate(30px, -30px);
    opacity: 0.8;
}

.socials ul li:hover span:nth-child(3) {
    transform: translate(20px, -20px);
    opacity: 0.6;
}

.socials ul li:hover span:nth-child(2) {
    transform: translate(10px, -10px);
    opacity: 0.4;
}

.socials ul li:hover span:nth-child(1) {
    transform: translate(0px, 0px);
    opacity: 0.2;
}

.socials ul li:nth-child(1):hover span {
    background: #E1306C;
}

.socials ul li:nth-child(2):hover span {
    background: #FF0000;

}

.socials ul li:nth-child(3):hover span {
    background: #55acee;
}

.links {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 3%;
    background-color: white;

}

.numan>a>img,
.mali>a>img {
    width: 50px;
}

.numan>h1,
.mali>h1 {
    font-size: 30px;
    color: gray;
}

.contact-top {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-top-image {
    height: 70dvh;
    width: 100%;
    background-position: center;
    background-size: cover;
    filter: blur(3px);
}

.image-text {
    position: absolute;
    z-index: 2;
    color: white;
    text-shadow: 10px 10px 10px black;
    font-size: 5rem;
}

.contact {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    width: 100%;
    background-color: gray;
    height: 50dvh;
    padding: 2%;
}

.contact-left {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.contact-right {
    position: relative;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

textarea {
    resize: none;
    height: 40%;
    width: 100%;
}

.send-button {
    position: relative;
    margin-top: 2%;
    width: 30%;
    padding: 2%;
    align-self: end;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
}

.send-button:hover {
    filter: brightness(70%);
}

.send-button:active {
    filter: none;
    background-color: black;
    color: white;
    border: 1px solid white;
}



@media (max-width: 35em) {
    h1 {
        font-size: 30px;
    }

    .image-text {
        font-size: 30px;
    }

    .contact-top-image {
        height: 30dvh;
    }

    .contact-left>p {
        font-size: 20px;
    }

    .contact {
        height: 40dvh;
    }

    .numan>h1,
    .mali>h1 {
        font-size: 1.5rem;
    }


}</style>
<template>
    <NavBar />
    <div class="top_margin_div"></div>
    <div class="container">
        <div class="top">
            <div class="dropdown" style="float: right;">
                <button class="dropbtn">Filtele
                </button>
                <div class="dropdown-content">
                    <a @click="handleSort(1)">En Beğenilenler</a>
                    <a @click="handleSort(2)">En Çok Yorumlananlar</a>
                </div>
            </div>
        </div>
        <div v-for="(item, index) in watchlists" :key="index" class="watchlist">
            <div class="f sb">
                <h2 class="watchlist-top">{{ item.name }} </h2>
                <a @click="goList(item.watchlist_id)">Listeni Düzenle</a>
            </div>
            <div v-if="item.movies" class="watchlist-middle">
                <div v-for="(movie, index) in item.movies" :key="index"
                    :style="{ backgroundImage: `url(${movie.movie.poster})` }" @click="goMovie(movie.movie.id)"
                    class="movie">
                    <div class="movie-name">{{ movie.movie.title }}</div>
                </div>
            </div>
            <div class="watchlist-bottom">
                <div class="watchlist-bottom-left">
                    <button class="button-comment">
                        <img class="small-icon" :src="ticket" />
                        <span class="lower">{{ item.total_likes }} Beğeni</span>
                    </button>
                    <button class="button-like">
                        <img class="small-icon" :src="comment" />
                        <span class="lower">{{ item.total_comments }} Yorum </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <InfoModal :duration="'2s'" :type="'info'" v-if="showInfoModal" :message="infoMessage"
        @close="showInfoModal = false" />
    <FooterBar />
</template>
<script>
import PublicWatchlistService from '../services/PublicWatchlistService.js'

import ticket from '../assets/images/tickets.png';
import comment from '../assets/images/message.png';
export default {
    data() {
        return {
            isMobile: false,
            postID: null,
            communityID: null,
            limit: 20,
            listLoading: true,
            loading: true,
            movies: [],
            communityInfo: {},
            showInfoModal: false,
            infoMessage: 'İşlem başarılı.',
            modalDuration: 2000,
            sortObject: {
                orderBy: 'most_liked',
                order_dir: 'desc',
                limit: 50,
                searchTerm: ''
            },
            searchMovies: [],
            watchlists: [],
            searchTimeout: null,
            term: '',
            watchlistID: null,
            states: [],
            header: 'Başlık Burada',
            newName: '',
            editingName: false,
            watchlistData: {
                name: '',
                id: null
            },
            isDragging: false,
            startX: 0,
            scrollLeft: 0,
            ticket,
            comment
        }
    },
    methods: {
        async handleSort(sortBy) {
            if (!sortBy) return;
            if (sortBy == 1) {
                this.sortObject.orderBy = 'most_liked';
            }
            if (sortBy == 2) {
                this.sortObject.orderBy = 'most_commented';
            }
            await this.getListBySearchQuery()

        },
        checkMobile() {
            this.windowWidth = window.innerWidth
            if (this.windowWidth < 920) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        async getListBySearchQuery() {
            this.listLoading = true;
            const response = await PublicWatchlistService.getUserAllWatchlist({
                sortBy: this.sortObject.orderBy,
                dir: this.sortObject.order_dir
            });
            if (!response) return;
            this.watchlists = response;
            this.listLoading = false
        },
        startDrag(event) {
            this.isDragging = true;
            this.startX = event.type === 'mousedown' ? event.pageX - this.searchMovies.offsetLeft : event.touches[0].clientX - this.searchMovies.offsetLeft;
            this.scrollLeft = this.searchMovies.scrollLeft;
            this.searchMovies.style.cursor = 'grabbing';

            this.searchMovies.addEventListener('mousemove', this.drag());
            this.searchMovies.addEventListener('touchmove', this.drag());

            this.searchMovies.addEventListener('mouseup', this.stopDrag());
            this.searchMovies.addEventListener('touchend', this.stopDrag());

            this.searchMovies.addEventListener('mouseleave', this.stopDrag());
            this.searchMovies.addEventListener('touchcancel', this.stopDrag());
        },
        drag(event) {
            if (!this.isDragging) return;
            const x = event.type === 'mousemove' ? event.pageX - this.searchMovies.offsetLeft : event.touches[0].clientX - this.searchMovies.offsetLeft;
            const walk = (x - this.startX) * 1; // Adjust scroll speed
            this.searchMovies.scrollLeft = this.scrollLeft - walk;
        },
        stopDrag() {
            this.isDragging = false;
            this.searchMovies.style.cursor = 'grab';

            this.searchMovies.removeEventListener('mousemove', this.drag());
            this.searchMovies.removeEventListener('touchmove', this.drag());

            this.searchMovies.removeEventListener('mouseup', this.stopDrag());
            this.searchMovies.removeEventListener('touchend', this.stopDrag());

            this.searchMovies.removeEventListener('mouseleave', this.stopDrag());
            this.searchMovies.removeEventListener('touchcancel', this.stopDrag());
        },
        openInfoModal(msg) {
            this.showInfoModal = true
            this.infoMessage = msg
            setTimeout(() => {
                this.showInfoModal = false
            }, this.modalDuration)
        },
        goRoute(watchlist_id) {
            if (!watchlist_id) return
            this.$router.replace({ name: 'movie', params: { id: watchlist_id } })
        },
        goList(watchlist_id) {
            if (!watchlist_id) return;
            this.$router.push({
                name: 'edit-watchlist',
                params: { id: watchlist_id }
            });
        },
        goMovie(movieID) {
            if (!movieID) return
            this.$router.replace({ name: 'movie', params: { id: movieID } })
        }
    },
    async created() {
        this.watchlistID = this.$route.params.id
        this.checkMobile()
        await this.getListBySearchQuery()
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
            if (this.windowWidth < 920) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        })
    },
    components: {}
}
</script>

<style scoped>
.top_margin_div {
    height: 12vh;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: black;
    padding-left: 3%;

    padding-right: 3%;
}

.watchlist {
    display: flex;
    flex-direction: column;
    padding: 2%;
}

.watchlist-top {
    text-align: start;
    padding-left: 1%;
}

.watchlist-middle {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    height: 350px;
    padding: 1%;
    gap: 1rem;
    overflow-y: hidden;
    user-select: none;
}

.watchlist-bottom {
    padding-top: 0%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;

}

.watchlist-bottom-left,
.watchlist-bottom-right {
    display: flex;
    gap: 1rem;
    width: 50%;
    margin-inline: 2%;
}

.watchlist-bottom-left {
    justify-self: start;
}

.watchlist-bottom-right {

    justify-content: end;

}

.movie {
    padding: 2%;
    margin: 0%;
    box-shadow: 0px 1px 1px #f8b63289;
    height: 80%;
    text-align: center;
    width: 10%;
    min-width: 150px;
    border-radius: 10px;
    position: relative;
    background-size: cover;
    background-position: center;
    transition: transform 0.2s ease;
    cursor: pointer;
}

.movie:hover {
    transition: 0.2;
    transform: scale(1.05);
}

.movie-name {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 172px;
    text-align: center;
    font-size: 13px;
    font-weight: 800;
}

.button-comment,
.button-like,
.button-follow {
    color: #f8b632;
    background-color: black;
    font-size: 1rem;
    border: 1px solid #f8b632;
    border-radius: 2px;
    height: 35px;
    min-width: 91px;
    aspect-ratio: auto;
    cursor: pointer;
    display: flex;
    gap: 0.3rem;
    text-align: center;
    justify-content: center;
    align-items: center;
}

a {
    color: #f8b632;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.button-comment:active,
.button-like:active,
.button-follow:active {
    transition: 0.2s;
    background-color: #f8b632;
    color: black;
}

.button-comment:hover,
.button-like:hover,
.button-follow:hover {
    transition: 0.2s;
    background-color: #f8b632;
    color: black;
}

hr {
    border-color: #f8b632;
}

.hr-anim {
    animation: hr-anim 2s ease-in-out forwards;

}

@keyframes hr-anim {
    from {
        width: 0%;
    }

    to {
        width: 95%;
    }
}

.top {
    padding: 0% 3%;
    width: 100%;

}

.dropbtn {
    background-color: black;
    color: #f8b632;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    height: 35px;
    border: none;
    cursor: pointer;
    border: 1px solid #f8b632;
    outline-color: #f8b632;
    display: flex;
    align-items: center;
    border-radius: 2px;
}

.dropdown {
    position: relative;
    display: inline-block;
    height: 35px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    right: 0;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #f8b632;

}

.dropdown-content a {
    color: #f8b632;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f8b632;
    color: black;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: black;
}

.dropdown:hover .filter-arrow {
    animation: arrow-anim2 0.2s linear forwards;
}

.dropdown:not(:hover) .filter-arrow {
    animation: arrow-anim 0.2s linear forwards;
}

@keyframes arrow-anim {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@keyframes arrow-anim2 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

@media only screen and (max-width: 600px) {

    .watchlist-middle {
        height: 300px;
    }

    .button-comment,
    .button-like {
        font-size: 0.7rem;
        height: 30px;
        min-width: 85px;
    }

    .watchlist-bottom {
        flex-direction: row;
        width: 100%;
        gap: 2px;
    }

    .watchlist-bottom-left,
    .watchlist-bottom-right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-inline: 0px;
        gap: 4px;
    }


    .button-follow {
        display: none;
    }

    .top_margin_div {
    height: 4vh;
}

}
</style>
<template>
  <meta name="referrer" content="no-referrer" />
  <nav v-if="!isMobileScreen">
    <ul class="navbar align-center flex">
      <div class="nav-item logo flex" @click="this.$router.push('/home')">
        <img :src="logo" class="logo prevent-user-img" />
      </div>
      <li v-for="(item, index) in items" :key="index">
        <a class="nav-item" :href="item.link">{{ item.name }}</a>
      </li>
      <div @click="toggleDropdown" class="nav-item user-logo flex">
        <img alt=icon v-if="user === undefined" class="avatar-size" :src="avatar" />
        <img alt=icon v-if="!user.img" class="avatar-size" :src="avatar" />
        <img alt=icon v-else class="avatar-size" :src="user.img" />
      </div>
    </ul>
    <transition name="fade">
      <div v-if="isDropdownOpen" class="dropdown-menu">
        <ul class="drop-ul">
          <li @click="goRoute(item.link)" v-for="(item, index) in userItems" :key="index"><a>{{ item.name }}</a></li>
          <li @click="logout"><a>Çıkış Yap</a></li>
        </ul>
      </div>
    </transition>
  </nav>

  <nav v-else>
    <div class="mobile_top_navbar">
      <div class="left">
        <button @click="this.$router.push('/home')" class="mobile_nav_btn">
          <img :src="logo" class="logo prevent-user-img" />
        </button>
      </div>
      <div class="right">
        <button @click="this.$router.push('/details')" class="mobile_nav_btn">
          <img alt=icon v-if="user.img" class="avatar-size" :src="user.img" />
        </button>
        <button @click="toggleClapper" class="mobile_nav_btn">
          <VueIcon icon="material-symbols:menu" style="color: white" width="50px" heigth="50px"></VueIcon>
        </button>
      </div>

    </div>
    <ul ref="mobileNav" class="navbar align-center flex">
      <div class="nav-item logo flex">
        <span class="title-font">{{ title }}</span>
      </div>

      <div class="nav-item logo flex" @click="this.$router.push('/home')">
        <img :src="logo" class="logo prevent-user-img" />
      </div>
      <div style="margin-bottom: 15px;">
        <div class="close_wrap">
          <button @click="toggleClapper" class="mobile_nav_btn">
            <VueIcon icon="material-symbols:menu" style="color: white" width="50px" heigth="50px"></VueIcon>
          </button>
        </div>
        <div @click="this.$router.push('/details')" class="mobile_user_div">
          <img alt=icon v-if="user.img" class="avatar-size" :src="user.img" />
          <img alt=icon v-else class="avatar-size" :src="avatar" />
          <div class="user_info">
            <div class="badge_div">
              <img v-if="user.rank.badge" style="height: 20px; width: 20px" :src="user.rank.badge" />
              <span class="badge_rank">{{ user.rank.name }}</span>
            </div>
            <span class="user_name">{{ user.username }}</span>
          </div>
        </div>
      </div>
      <li v-for="(item, index) in mobileItems" :key="index">
        <a class="nav-item" @click="goRoute(item.link)">{{ item.name }}</a>
      </li>
      <div class="site_info">
        <span>
          {{ siteInfo.name }}
        </span>
        <span>
          {{ siteInfo.year }}
        </span>
      </div>
    </ul>
  </nav>

</template>

<script>
import avatar from '../../assets/images/avatar.png';
import logo from '../../assets/images/logo.png';

import discover from '../../assets/badges/discover.png';

import { useUserStore } from '../../store/user.js'
import ImageUtil from '../../utils/ImageUtil';
export default {
  data() {
    return {
      avatar: avatar,
      title: 'Mosecop',
      isDropdownOpen: false,
      logo,
      items: [
        {
          name: 'Anasayfa',
          link: '/home'
        },
        {
          name: 'AI',
          link: '/ai'
        },
        {
          name: 'Filmler',
          link: '/movies',
        },
        {
          name: 'İzleme Listeleri',
          link: '/watchlists'
        },
        {
          name: 'Topluluk',
          link: '/community'
        },
      ],
      mobileItems: [
      {
          name: 'Ayarlar',
          link: '/settings'
        },
        {
          name: 'Anasayfa',
          link: '/home'
        },
        {
          name: 'AI',
          link: '/ai'
        },
        {
          name: 'Filmler',
          link: '/movies',
        },
        {
          name: 'İzleme Listeleri',
          link: '/watchlists'
        },
        {
          name: 'İzleme Listem',
          link: '/list'
        },
        {
          name: 'İzleme Listelerim',
          link: '/my-watchlists'
        },
        {
          name: 'Topluluk',
          link: '/community'
        },
      ],
      userItems: [
        {
          name: 'Profil',
          link: '/details'
        },
        {
          name: 'Ayarlar',
          link: '/settings'
        },
        {
          name: 'İzleme Listelerim',
          link: '/my-watchlists'
        },
        {
          name: 'Kişisel Öneriler',
          link: '/recommendations'
        },
        /*
        {
          name: 'Settings',
          link: '/settings'
        },*/
      ],
      isMobileNavOpen: false,
      isMobileScreen: false,
      user: {
        img: avatar,
        rank: '',
      },
      siteInfo: {
        name: 'Mosecop.com ©',
        year: '2024'
      },
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    toggleClapper() {

      var mobileNav = this.$refs.mobileNav;
      const htmlElement = document.querySelector('html');
      const bodyOverflow = document.querySelector('html');

      if (this.isMobileNavOpen) {
        mobileNav.classList.remove('navbar-animation-in')
        mobileNav.classList.add('navbar-animation-out');
        mobileNav.style.display = "none";
        this.isMobileNavOpen = false;
        htmlElement.style.overflowY = 'auto';
        bodyOverflow.style.overflowY = 'auto';
        return;
      }

      mobileNav.classList.remove('navbar-animation-out')
      mobileNav.classList.add('navbar-animation-in');
      mobileNav.style.display = "block";
      this.isMobileNavOpen = true;
      htmlElement.style.overflowY = 'hidden';
      bodyOverflow.style.overflowY = 'hidden';

      return;
    },
    goRoute(route) {
      this.$router.push(route);
    },
    getBadge(){
      if(!this.user.rank) return discover;
      this.user.rank.badge = ImageUtil.getBadge(this.user.rank.id);
    },
    logout() {
      // Çıkış işlemleri burada yapılabilir
      alert("Çıkış Yapıldı!");
    },
  },
  created() {
    this.isMobileScreen = window.innerWidth < 600;

    window.addEventListener('resize', () => {
      this.isMobileScreen = window.innerWidth < 600;
    });

    this.user = useUserStore().$state.user;
    this.getBadge();
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.dropdown-menu {
  position: fixed;
  z-index: 99999;
  top: 10.5vh;
  right: 15px;
  background-color: black;
  box-shadow: 0 1px 3px #f8b632;
  border: 1px solid #f8b632;
  border-radius: 4px;
  padding: 14px;
  min-width: 220px;
}

.drop-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.drop-ul a {
  color: gray;
  text-decoration: none;
  font-weight: 600;
}


.drop-ul li:hover a {
  color: #f8b632;
  text-decoration: none;
  font-weight: 600;
  color: black;
}


.drop-ul li {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;

}

.drop-ul li:hover {
  background-color: #f8b632;
}

.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

:root {
  --gap: 3rem;
  --font-size: 20px;
  --nav-item-margin: 1rem;
  --logo-position: 10px;
  --logo-size: 70px;
  --avatar-size: 40px;
}

.avatar-size {
  width: 90%;
  border-radius: 50%;
}

.title-font {
  font-size: 2rem;
}

.navbar {
  display: flex;
  top: 0;
  width: 100%;
  background-color: #313156;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  height: 12vh;
  list-style: none;
  padding: 0.6rem 0.4rem;
  margin: 0;
  font-size: var(--font-size);
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1rem);
  z-index: 1000;
}

.navbar li {
  flex-grow: 1;
  flex-shrink: 1;
}

.nav-item:hover {
  color: #f8b632;
}

.nav-item {
  position: relative;
  text-decoration: none;
  margin: var(--nav-item-margin);
  color: white;
  padding: 0;
  text-shadow: 1px 1px 1px black;
}

.nav-item.user-logo {
  position: relative;
  width: 50px;
  aspect-ratio: 1;
  background-color: white;
  border-radius: 50%;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
}

.nav-item.user-logo:hover {
  background-color: #f8b632;
}

.nav-item.logo {
  position: relative;
  aspect-ratio: 1;
  width: 65px;
  border-radius: 50%;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.nav-item.logo img {
  width: 100%;
  cursor: pointer;
}


@media (max-width: 600px) {
  .navbar {
    right: 0;
    width: 80%;
    /* veya istediğiniz genişlik */
    height: 100%;
    flex-direction: column;
    height: 100%;

    justify-content: left;
    align-items: normal;
    padding: 0.8rem 2%;
    padding-left: 10%;
    padding-right: 2%;
    padding-top: 0.8rem;
    display: none;
    transition: 0.2s;
  }

  .mobile_top_navbar {
    height: 10vh;
    width: 100%;
    background-color: rgb(13, 13, 13);
    position: -webkit-sticky;
    padding-left: 3%;
    /* Safari */
    z-index: 999;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile_top_navbar .left {
    display: flex;
    align-items: center;
  }

  .mobile_top_navbar .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mobile_nav_btn {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .close_wrap {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .mobile_nav_btn_close {
    display: flex;
    justify-content: flex-end;
  }

  .badge_div {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: flex-end;
  }

  .badge_rank {
    font-size: 13px;
    color: #f8b632;
  }

  .user_info {
    display: flex;
    flex-direction: column;

  }


  .nav-item {
    margin: 0px;
  }

  .mobile_top_navbar img {
    width: 50px;
    height: 40px;
  }

  .navbar li {
    flex-grow: 0;
    margin-block: 5px;
    padding-left: 2%;
  }

  .mobile_user_div {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    gap: 10px;
  }

  .user_name {
    font-size: 13px;
    color: white;
  }

  .site_info {
    color: white;
    font-size: 14px;
    position: absolute;
    bottom: 60px;
    display: inline-grid;
  }

  .mobile_user_div img {
    display: flex;
    flex-direction: column;
    width: 45px;
  }

  .navbar-animation-in {
    animation: anim-in 0.2s ease-out both;
  }

  .navbar-animation-out {
    animation: anim-out 0.2s ease-out both;
  }

  @keyframes anim-in {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  @keyframes anim-out {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(50%);
    }
  }

  .nav-item.user-logo {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }

  .nav-item.logo {
    display: none;
  }

  .nav-item.logo::before {
    font-size: 2rem;
  }

  .navbar-button {
    display: block;
  }

  .nav-item.logo.outside {
    position: absolute;
    top: 10;
    width: 70px;
    display: flex;
    z-index: 999;
  }

}
</style>

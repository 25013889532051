<template>
    <NavBar />
    <div class="wrapper">
        <div v-if="!isMobile" style="height: 12vh;"></div>
        <div v-if="trendLoading" :style="{ height: faturedHeight }">
            <FeaturedShimmer />
        </div>
        <div v-if="isMobile && !trendLoading" style="width: 100%;height: 50vh;">
            <MobileCarousel @setSlide="setIndicator" :item="trendMovies[0]" :items="trendMovies" />
        </div>
        <div v-else>
            <FeaturedCarousel @setSlide="setIndicator" :item="trendMovies[0]" :items="trendMovies" />
        </div>
        <div v-if="!trendLoading" class="indicator">
            <div class="indicator_wrap">
                <div class="circle-div" v-for="(movie, index) in trendMovies" :key="index"
                    :style="{ backgroundColor: index === this.currentSlide ? '#f8b632' : 'black' }">
                </div>
            </div>
        </div>
        <div class="search_div">
            <div v-if="!isMobile" class="filters">
                <div style="height: 10px"></div>
                <span class="category-font bold">Filtrele</span>
                <div style="height: 5px"></div>
                <div class="line"></div>
                <div style="height: 15px"></div>
                <div>
                    <div class="tab_outer">
                        <div class="tab">
                            <div v-for="(item, index) in tabs" :key="index" class="inner_tab"
                                :class="{ 'inner_tab': true, 'active': index === activeTabIndex }"
                                @click="setActiveTab(index)">
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 5px"></div>
                <div class="line"></div>
                <div style="height: 20px"></div>
                <div>
                    <span class="category-font">Tür</span>
                    <ul class="genre-list">
                        <li v-for="(item, index) in genres" :key="index" @click="toggleGenre(item)"
                            :class="{ 'active': selectedGenres.includes(item) }">
                            {{ item.lang_tr }}
                        </li>
                    </ul>
                </div>
                <div style="height: 20px"></div>
                <div class="line"></div>
                <div style="height: 20px"></div>
                <div>
                    <span class="category-font">Dil</span>
                    <ul class="genre-list">
                        <li v-for="(item, index) in languages" :key="index" @click="toggleLanguage(item)"
                            :class="{ 'active': selectedLanguage.name === item.name }">
                            {{ item.lang_tr }}
                        </li>
                    </ul>
                </div>
                <div style="height: 20px"></div>
                <div class="line"></div>
                <div style="height: 20px"></div>
                <div>
                    <span class="category-font">Puanlar</span>
                    <div v-for="(item, index) in scoreItems" :key="index" class="score">
                        <div class="f sb">
                            <span class="body-font">{{ item.label }}</span>
                            <span class="body-font">{{ item.value }}</span>
                        </div>
                        <div class="range">
                            <input type="range" class="range_slider" v-model="item.value" :min="item.min"
                                :max="item.max" :step="item.step" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isMobile" class="right">
                <SearchBar :placeholder="'Film, yönetmen, oyuncu ara ...'" @searchMovie="searchMovie" />
                <div class="filter_options">
                    <div class="tags">
                        <div style="display: flex; flex-direction: column;">
                            <span class="mid-font">{{ this.movies.length }} {{ selectedCategory == 'movie' ? 'Film' :
                                'Dizi' }} </span>
                            <span style="color: gray;" class="mid-font">Toplam: {{ selectedCategory == 'movie' ?
                                totalCount.movie:totalCount.series}} </span>
                        </div>
                        <div class="genre" v-for="(item, index) in selectedGenres" :key="index">
                            <span class="body-font"> {{ item.lang_tr }}</span>
                            <div style="width: 5px;"></div>
                            <span class="gray">x</span>
                        </div>
                    </div>
                    <div class="drop_wrap">
                        <span class="mid-font">Sırala</span>
                        <SelectorDropdown :refFunction="filterList" :options="sortOptions"
                            :selectedItem="selectedSort" />
                    </div>
                </div>
                <div v-if="loading" class="container">
                    <ShimmerCard v-for="(index) in 12" :key="index" />
                </div>
                <div v-else class="container" :key="animateMovies">
                    <MovieCard @info="goMovieDetails" @add="addWatchlist" :item="item" v-for="(item, index) in movies"
                        :key="index" />
                </div>
                <div class="page_row pt-2 pb-3">
                    <PaginationBar :numbers="pageNumbers" @move-page="movePage" :pageNumber="page" />
                </div>
            </div>
            <div v-if="isMobile" class="right">
                <div class="f g2">
                    <button @click="toggleFilter" class="filter_toggle_wrap">
                        <VueIcon style="cursor: pointer;" icon="mdi:filter-outline" width="30px" color="white">
                        </VueIcon>
                    </button>
                    <SearchBar :placeholder="'Film, yönetmen, oyuncu ara ...'" @searchMovie="searchMovie" />
                </div>
                <div v-if="loading" class="container">
                    <ShimmerCard v-for="(index) in 12" :key="index" />
                </div>
                <div v-else class="container" :key="animateMovies">
                    <MovieCard @info="goMovieDetails" @add="addWatchlist" :item="item" v-for="(item, index) in movies"
                        :key="index" />
                </div>
                <div class="page_row pt-2 pb-3">
                    <PaginationBar :numbers="pageNumbers" @move-page="movePage" :pageNumber="page" />
                </div>
            </div>
        </div>
        <div v-if="mobileFilterOpen && isMobile" class="bottom_modal">
            <div class="f sb align-center header">
                <h3>Filtrele</h3>
                <div class="close_btn" @click="toggleFilter">&times;</div>
            </div>
            <div class="filter_options">
                <div class="drop_wrap">
                    <h4>Sırala</h4>
                    <SelectorDropdown :refFunction="filterList" :options="sortOptions" :selectedItem="selectedSort" />
                </div>
            </div>
            <div>
                <div class="tab_outer">
                    <div class="tab">
                        <div v-for="(item, index) in tabs" :key="index" class="inner_tab"
                            :class="{ 'inner_tab': true, 'active': index === activeTabIndex }"
                            @click="setActiveTab(index)">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h4>Film Türü</h4>
                <ul class="genre-list">
                    <li v-for="(item) in genres" :key="item.name" @click="toggleGenre(item)"
                        :class="{ 'active': selectedGenres.includes(item) }">
                        {{ item.lang_tr }}
                    </li>
                </ul>
            </div>
            <div class="line"></div>
            <div style="height: 20px"></div>
            <div>
                <span class="category-font">Dil</span>
                <ul class="genre-list">
                    <li v-for="(item, index) in languages" :key="index" @click="toggleLanguage(item)"
                        :class="{ 'active': selectedLanguage.name === item.name }">
                        {{ item.lang_tr }}
                    </li>
                </ul>
            </div>
            <div style="height: 10px"></div>
            <div class="line"></div>
            <div>
                <h4>Puanlar</h4>
                <div v-for="(item, index) in scoreItems" :key="index" class="score">
                    <div class="f sb">
                        <span class="body-font">{{ item.label }}</span>
                        <span class="body-font">{{ item.value }}</span>
                    </div>
                    <div class="range">
                        <input type="range" class="range_slider" v-model="item.value" :min="item.min" :max="item.max"
                            :step="item.step" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <InfoModal :duration="'2s'" :type="'info'" v-if="showInfoModal" :message="infoMessage"
        @close="showInfoModal = false" />
    <FooterBar />
</template>

<script>

import MovieService from '../services/MovieService.js';
import WatchlistService from '../services/WatchlistService.js';
import FeaturedShimmer from '../components/Shimmer/FeaturedCard.vue'

import FeaturedCarousel from '../components/Movies/FeaturedCarousel.vue';
import MobileCarousel from '../components/Movies/MobileFeaturedCarousel.vue';

import SearchBar from '../components/Movies/SearchBar.vue';
import SelectorDropdown from '../components/Buttons/SelectorDropdown.vue';
import MovieCard from '../components/Movies/MovieCardCustom.vue';
import ShimmerCard from '../components/Shimmer/MovieCard.vue';
import { useUserStore } from '../store/user.js'

export default {
    data() {
        return {
            animateMovies: false,
            animateContainer: false,
            isMobile: false,
            mobileFilterOpen: false,
            faturedHeight: null,
            topRateMovies: [],
            mostLikedMovies: [],
            movies: [],
            trendMovies: [],
            featuredMovies: [],
            loading: false,
            showInfoModal: false,
            modalDuration: 2000,
            infoMessage: 'Please, try again later',
            topRatedLoading: false,
            trendLoading: false,
            search: '',
            searchTimeout: null,
            mostLikedCount: 10,
            totalCount: {
                movie: 17318,
                series: 287
            },
            movieLimit: 20,
            selectedMovie: {},
            genres: [
                { id: 1, name: 'Action', lang_tr: 'Aksiyon' },
                { id: 2, name: 'Adventure', lang_tr: 'Macera' },
                { id: 3, name: 'Drama', lang_tr: 'Dram' },
                { id: 4, name: 'Comedy', lang_tr: 'Komedi' },
                { id: 5, name: 'Thriller', lang_tr: 'Gerilim' },
                { id: 6, name: 'Mystery', lang_tr: 'Gizem' },
                { id: 7, name: 'Family', lang_tr: 'Aile' },
                { id: 8, name: 'Fantasy', lang_tr: 'Fantastik' },
                { id: 9, name: 'Romance', lang_tr: 'Romantik' },
                { id: 10, name: 'Biography', lang_tr: 'Biyografi' },
                { id: 11, name: 'History', lang_tr: 'Tarih' },
                { id: 12, name: 'Sport', lang_tr: 'Spor' },
                { id: 13, name: 'Crime', lang_tr: 'Suç' },
                { id: 14, name: 'Musical', lang_tr: 'Müzikal' },
                { id: 15, name: 'Sci-Fi', lang_tr: 'Bilim Kurgu' },
                { id: 16, name: 'Horror', lang_tr: 'Korku' },
                { id: 17, name: 'Animation', lang_tr: 'Animasyon' },
                { id: 18, name: 'Music', lang_tr: 'Müzik' },
                { id: 19, name: 'War', lang_tr: 'Savaş' },
                { id: 20, name: 'Documentary', lang_tr: 'Belgesel' },
                { id: 21, name: 'Western', lang_tr: 'Western' },
                { id: 22, name: 'Film', lang_tr: 'Film' },
                { id: 23, name: 'Noir', lang_tr: 'Kara Film' },
                { id: 24, name: 'News', lang_tr: 'Haber' }
            ],
            languages: [
                { id: 1, name: 'Turkish', lang_tr: 'Türkçe' },
                { id: 2, name: 'English', lang_tr: 'İngilizce' },
                { id: 3, name: 'Spanish', lang_tr: 'İspanyolca' },
                { id: 4, name: 'French', lang_tr: 'Fransızca' },
                { id: 5, name: 'German', lang_tr: 'Almanca' },
                { id: 6, name: 'Russian', lang_tr: 'Rusça' },
                { id: 7, name: 'Arabic', lang_tr: 'Arapça' },
                { id: 8, name: 'Chinese', lang_tr: 'Çince' },
                { id: 9, name: 'Italian', lang_tr: 'İtalyanca' },
                { id: 10, name: 'Japanese', lang_tr: 'Japonca' },
                { id: 11, name: 'Korean', lang_tr: 'Korece' },
                { id: 12, name: 'Danish', lang_tr: 'Danca' },
                { id: 11, name: 'Indian', lang_tr: 'Hintçe' },
                { id: 12, name: 'Dutch', lang_tr: 'Flekemenkçe' },
            ],
            selectedLanguage: { id: 2, name: 'English', lang_tr: 'İngilizce' },
            tabs: [
                { name: 'Film', id: 1 },
                { name: 'Dizi', id: 2 },
            ],
            activeTabIndex: 0,
            selectedCategory: 'movie',
            selectedGenres: [],
            scoreItems: [
                { label: 'IMDB', value: 10, min: 0, max: 10, step: 0.1 },
                { label: 'Community Score', value: 4, min: 0, max: 5, step: 0.1 },
                { label: 'Meta Score', value: 100, min: 0, max: 100, step: 10 },
            ],
            sortOptions: [
                { id: 0, option: 'Çıkış Tarihi' },
                { id: 1, option: 'IMDB' },
                { id: 2, option: 'Mosecop Puanı' },
                { id: 3, option: 'Süre' },
            ],
            selectedSort: 'IMDB',
            orderBy: 'imdbrating',
            currentSlide: 0,
            featuredMovieLimit: 15,
            user: {},
            pageNumbers: [1, 2, 3, 4, 5, 6],
            page: 1
        }
    },
    methods: {
        setActiveTab(index) {
            this.activeTabIndex = index;
            if (index === 0) this.selectedCategory = 'movie';
            if (index === 1) this.selectedCategory = 'series';
            this.searchMovie();
        },
        async getMoviesByParam() {

            this.loading = true;

            let genres = [];
            if (this.selectedGenres && this.selectedGenres.length > 0) {
                genres = this.selectedGenres.map(e => e.id);
            }

            const response = await MovieService.getMoviesAdvanced({
                page: this.page,
                limit: this.movieLimit,
                order_by: this.orderBy,
                order_dir: 'desc',
                search: this.search,
                genres: genres,
                imdb: this.scoreItems[0].value,
                meta: this.scoreItems[2].value,
                category: this.selectedCategory,
                language: this.selectedLanguage.name
            });

            if (!response) return
            this.movies = response;
            this.loading = false
        },
        async searchMovie(searchTerm) {
            this.search = searchTerm;
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(async () => {
                await this.getMoviesByParam();
            }, 1000);
        },
        async getTrendMovies() {
            this.trendLoading = true
            if(this.isMobile) this.featuredMovieLimit = 10;
            const response = await MovieService.getMovies({
                limit: this.featuredMovieLimit,
                order_by: 'trend',
                order_dir: 'desc'
            })
            if (!response) return
            this.trendMovies = this.featuredMovies = response;
            this.trendLoading = false
        },
        toggleLanguage(item) {
            this.selectedLanguage = item;
            this.getMoviesByParam();
        },
        toggleGenre(item) {
            if (this.selectedGenres.includes(item)) {
                this.selectedGenres = this.selectedGenres.filter((genre) => genre.id !== item.id);

            } else {
                this.selectedGenres.push(item);
            }
            this.getMoviesByParam();
        },
        filterList(select) {
            this.selectedSort = select.option;

            if (select.id === 0) {
                this.orderBy = 'year';
            }
            if (select.id === 1) {
                this.orderBy = 'imdbrating';
            }
            if (select.id === 2) {
                this.orderBy = 'community_score';
            }
            if (select.id === 3) {
                this.orderBy = 'runtime';
            }
            this.searchMovie();
        },
        setIndicator(number) {
            this.currentSlide = number;
        },
        goMovieDetails(movieId) {
            if (movieId == 0) return
            const id = movieId
            this.$router.replace({ name: 'movie', params: { id } })
        },
        async addWatchlist(movieID) {
            const response = await WatchlistService.addMovie({
                movie_id: movieID,
                user_id: this.user.user_id2
            });
            if (!response) return this.openInfoModal('Try again later.');
            return this.openInfoModal('Movie added to your watchlist.');
        },
        openInfoModal(msg) {
            this.showInfoModal = true;
            this.infoMessage = msg;
            setTimeout(() => {
                this.showInfoModal = false;
            }, this.modalDuration);
        },
        toggleFilter() {
            const htmlElement = document.querySelector('html');
            if (this.isMobile && this.mobileFilterOpen) {
                htmlElement.style.overflowY = 'auto';
            }
            if (this.isMobile && !this.mobileFilterOpen) {
                htmlElement.style.overflowY = 'hidden';
            }
            this.mobileFilterOpen = !this.mobileFilterOpen;
        },
        checkMobile() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth < 920) {
                this.isMobile = true;
                this.faturedHeight = '40vh';
            } else {
                this.isMobile = false;
                this.faturedHeight = '70vh';
            }
        },
        async movePage(type, number) {
            if (type === 'left_right') {
                this.page = Math.max(1, this.page + number);
            } else if (type === 'normal') {
                this.page = number;
            }

            this.updatePageNumbers();
            await this.getMoviesByParam();
        },
        updatePageNumbers() {
            const currentPage = this.page;
            const maxPage = this.pageNumbers[this.pageNumbers.length - 1];
            const minPage = this.pageNumbers[0];

            if (currentPage > maxPage) {
                const diff = currentPage - maxPage;
                this.pageNumbers = this.pageNumbers.map(num => num + diff);
            } else if (currentPage < minPage) {
                const diff = minPage - currentPage;
                this.pageNumbers = this.pageNumbers.map(num => num - diff);
            }
        },
    },
    watch: {
        scoreItems: {
            handler() {
                this.searchMovie();
            },
            deep: true,
        },
    },
    async created() {
        this.checkMobile();
        this.user = useUserStore().user;
        await this.getTrendMovies();
        await this.getMoviesByParam();
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth < 920) {
                this.isMobile = true;
                this.faturedHeight = '40vh';
            } else {
                this.isMobile = false;
                this.faturedHeight = '70vh';
            }
        })
    },
    components: { SearchBar, FeaturedCarousel, MobileCarousel, SelectorDropdown, MovieCard, FeaturedShimmer, ShimmerCard }
}
</script>

<style scoped>
h3,
h4,
h5 {
    color: white;
    font-weight: 600;
}

.tab_outer {
    width: 100%;
    padding: 0rem 0.2rem;
}

.page_row {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
}

.tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
    border-radius: 0px;
    height: 40px;
    transition: 0.4s;
    border: 0.5px solid #f8b632;
    margin-bottom: 10px;
}

.inner_tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: white;
    border-radius: 0px;
    transition: 0.4s;
}

.inner_tab:hover {
    color: black;
    background-color: #f8b632;
}

.inner_tab.active {
    color: black;
    background-color: #f8b632;
}

.circle-div {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 1px solid #f8b632;
    transition: 0.8s;
}

.indicator_wrap {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    margin-top: 10px;
}

.wrapper {
    overflow-x: hidden;
}

.container {
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: grid;
    height: auto;
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 280px;
    justify-content: flex-start;
}


.line {
    border: 0.5px solid #f8b632;
}

.category-font {
    font-size: 2.8vh;
    color: white;
    font-weight: 600;
    padding-left: 0px;
}

.mid-font {
    font-size: 18px;
    color: white;
    font-weight: 600;
    padding-left: 0px;
}

.body-font {
    font-size: 2vh;
    color: white;
    font-weight: 600;
    padding-left: 0px;
}

.gray {
    font-size: 2vh;
    color: gray;
    font-weight: 600;
}

.bold {
    font-weight: bold;
}

.search_div {
    display: flex;
    margin-top: 20px;
    padding: 0rem 0rem;
    padding-right: 1rem;
    gap: 2%;
    min-height: 100vh;
}

.right {
    width: 80%;
}

.filters {
    width: 20%;
    min-height: 20vh;
    max-height: 200vh;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 10px;
    background-color: rgb(40, 37, 37);
    border: 1px solid #f8b632;
}

.genre-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow-y: scroll;
    max-height: 45vh;
}

.genre-list li {
    cursor: pointer;
    padding: 3px 5px;
    border-radius: 0px;
}

.genre-list li:hover {
    color: #f8b632;
}

.genre-list li.active {
    color: #f8b632;
}

.range input {
    width: 100%;
}

.range input[type="range"] {
    accent-color: #f8b632;
}

.score {
    margin-top: 10px;
}

.filter_options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 70%;
}

.genre {
    background-color: #f8b632;
    color: white;
    min-width: 24px;
    height: 24px;
    border-radius: 15px;
    padding: 3px 6.85px;
    font-size: 13px;
    font-weight: 400;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop_wrap {
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1px;
}

@media screen and (max-width: 1030px) {
    .container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 920px) {
    .right {
        width: 100%;
    }

    .filters {
        display: none;
        width: 0%;
    }

    .search_div {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }

    .filter_options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        width: 50%;
    }

    .filter_options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0px;
    }

    .mid-font {
        font-size: 13px;
    }

    .drop_wrap {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 4px;
    }

    .genre-list {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 2px;
        overflow-y: hidden;
        max-height: 100vh;
    }

    .header {
        border-bottom: 1px solid #f8b632;
    }
}

@media screen and (max-width: 768px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 576px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}

.filter_toggle_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    width: 40px;
    height: 35px;
    border-radius: 2px;
    background-color: transparent;
    transition: 0.4s;
}

.filter_toggle_wrap:hover {
    transition: 0.3s;
    border: 1px solid #f8b632;
}

.bottom_modal {
    height: 85vh;
    background-color: #11101d;
    gap: 20px;
    position: fixed;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    top: auto;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    animation: animatebottom 0.3s;
}

.close_btn {
    font-size: 40px;
    cursor: pointer;
    color: white;
    transition: 0.4s;
}

@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0;
    }

    to {
        bottom: 0;
        opacity: 1;
    }
}

.bottom_modal::-webkit-scrollbar {
    display: none;
}
</style>
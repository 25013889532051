<template>
    <NavBar />
    <div class="top_margin_div"></div>
    <div class="out">
        <div class="container">
            <div class="filter">
                <div class="filter-name">
                    Kategori
                </div>
                <div class="filter-buttons" id="first">
                    <button v-for="(item, index) in filterArr1" :key="index" vfor class="filter-button btn1"
                        @click="changeColor(this, 1)">{{ item }}</button>
                </div>
            </div>
            <div class="filter">
                <div class="filter-name">
                    Tür
                </div>
                <div class="filter-buttons" id="second">
                    <button v-for="(item, index) in filterArr2" :key="index" vfor class="filter-button btn1"
                        @click="changeColor(this, 2)">{{ item }}</button>
                </div>
            </div>
            <div class="filter">
                <div class="filter-name">
                    Mood
                </div>
                <div class="filter-buttons" id="third">
                    <button v-for="(item, index) in filterArr3" :key="index" vfor class="filter-button btn1"
                        @click="changeColor(this, 3)">{{ item }}</button>
                </div>
            </div>
            <div class="filter">
                <div class="filter-name">
                    Süre
                </div>
                <div class="filter-buttons" id="forth">
                    <button v-for="(item, index) in filterArr4" :key="index" vfor class="filter-button btn1"
                        @click="changeColor(this, 4)">{{ item }}</button>
                </div>
            </div>
            <div class="filter">
                <div class="filter-name">
                    Ekstra (Opsiyonel)
                </div>
                <div class="filter-buttons">
                    <input type="text" placeholder="Christopher Nolan filmlerini severim...">
                </div>
            </div>
            <button class="filter-button find-button">Film Öner</button>
        </div>
    </div>

    <FooterBar />
</template>
<script>

export default {
    data() {
        return {
            isMobile: false,
            postID: null,
            communityID: null,
            limit: 20,
            filterArr1: ["Hepsi", "Film", "Dizi"],
            filterArr2: ["Hepsi", "Aksiyon", "Macera", "Animasyon", "Komedi", "Suç", "Belgesel", "Dram", "Aile", "Fantastik", "Tarih", "Korku", "Müzik", "Gizem", "Romantik", "Bilim Kurgu", "Savaş", "Western"],
            filterArr3: ["Mutlu", "Üzgün", "Karmaşık"],
            filterArr4: ["Kısafilm (30 Dakika)", "Orta (1 Saat)", "Uzun (2+ Saat)"],
        }
    },
    methods: {
        async handleSort(sortBy) {
            if (!sortBy) return;
            if (sortBy == 1) {
                this.sortObject.orderBy = 'most_liked';
            }
            if (sortBy == 2) {
                this.sortObject.orderBy = 'most_commented';
            }

        },
        checkMobile() {
            this.windowWidth = window.innerWidth
            if (this.windowWidth < 920) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        goRoute(watchlist_id) {
            if (!watchlist_id) return
            this.$router.replace({ name: 'watchlist', params: { id: watchlist_id } })
        },
        goMovie(movieID) {
            if (!movieID) return
            this.$router.replace({ name: 'movie', params: { id: movieID } })
        },
        changeColor() {

            var objArr = {
                Type: "Any",
                Genre: "Any",
                Mood: "Happy",
                AvailableTime: "Short (30 Minutes)"
            }
            return objArr;



        }
    },
    async created() {
        this.checkMobile()
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
            if (this.windowWidth < 920) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        })
    },
    components: {}
}
</script>

<style scoped>
.out {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    background-color: black;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 50%;
    padding: 2%;
    background-color: black;
    border-radius: 0.5rem;
    position: relative;
    color: #f8b632;
    border: 2px solid #f8b632;
}


.filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1rem;
    color: #f8b632;
    padding: 10px;
}

.filter-name {
    color: white;
    font-size: 15px;
    font-weight: bold;
}



.filter-buttons {
    display: flex;
    width: 100%;
    gap: 1rem;
    color: #f8b632;
    flex-wrap: wrap;
}

.filter-button {
    padding: 1%;
    min-width: 80px;
    border-radius: 0px;
    background-color: transparent;
    color: #f8b632;
    border: 1px solid #f8b632;
    font-weight: 300;
}

.filter-button:hover {
    background-color: #f8b632;
    color: black
}

.container::after,
.container::before {
    content: "";
    position: absolute;

    z-index: -1;
    inset: -0.3rem;
    background: conic-gradient(from var(--gradient-angle),
            #a97d23,
            #5e4513,
            #1d1506,
            #5e4513,
            #a97d23);
    border-radius: inherit;
    animation: rotation 20s linear infinite;
}

:root {
    --clr-1: #f8b632;
    --clr-2: #e0a52e;
    --clr-3: #a97d23;
    --clr-4: #5e4513;
    --clr-5: #1d1506;
}


.container::after {
    filter: blur(3.5rem);
}

@property --gradient-angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

@keyframes rotation {
    0% {
        --gradient-angle: 0deg;
    }

    100% {
        --gradient-angle: 360deg
    }
}


.top_margin_div {
    height: 15vh;
}

input {
    width: 100%;
    border-radius: 5px;
    height: 2rem;
    background-color: black;
    border: 2px solid #f8b632;
    color: #f8b632;
    padding: 1%;
}

input:focus {
    outline: 2px solid #a97d23;
    box-shadow: 0px 5px 10px 3px #5e4513;
}

.active,
.find-button:active {
    animation: button-color 0.5s ease-out forwards;
}

@keyframes button-color {
    from {
        color: #f8b632;
        background-color: black;
    }

    to {
        color: black;
        background-color: #f8b632;
    }
}





button:hover {
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .container {
        width: 90%;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .filter-button {
        font-size: 12px;
    }

    .out {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
    }


    .top_margin_div {
        height: 0vh;
    }

}
</style>
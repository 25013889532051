<template>
    <div class="community_info">
        <div>
            <h3>{{ item.name }}</h3>
        </div>
        <div>
            <p>
                {{ item.description }}
            </p>
        </div>
        <div class="bottom_info">
            <div>
                <span> Oluşturulma Tarihi: {{ getDate(item.created_time) }}</span>
            </div>
            <div>
                <a>
                    <span>Kurucu: </span>
                    <span style="font-weight: bold;"> {{ item.username }}</span>
                </a>
            </div>
            <div v-if="member">
                <span>Bu Tarihte Katıldın: {{ getDate(item.created_time) }}</span>
            </div>
        </div>
        <button @click="communityAction" @mouseenter="changeText" class="subs">
            {{ text }}
        </button>
    </div>
</template>
  
<script>
import { getFormattedDate } from '../../utils/DateUtils.js';

export default {
    data() {
        return {
            join: 'Topluluğa Katıl',
            exit: 'Topluluktan Ayrıl',
            text: '',
            isMember: false,
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        member: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    methods: {
        getDate() {
            return getFormattedDate(this.item.created_time)
        },
        changeText() {
            this.text = this.member ? this.exit : this.join;
        },
        communityAction() {
            this.$emit('communityAction', this.item.community_id);
        }
    },
    async created() {
        this.changeText();
    },
    components: {}
}
</script>
  
<style scoped>
.subs {
    border: none;
    height: 30px;
    border-radius: 20px;
    border: 1px solid #f8b632;
    background-color: #1A1A1B;
    width: 100%;
    color: #f8b632;
    transition: 0.4s;
    cursor: pointer;
    font-weight: 500;
}

.subs:hover {
    background-color: #f8b632;
    color: black;
}

.community_info {
    background-color: #1A1A1B;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.8rem 1rem;
    border: 1px rgb(58 58 58) solid;
    border-radius: 6px;
    display: flex;
    gap: 20px;
    width: 100%;
    min-height: 200px;
}

.community_info p {
    color: white;
    text-align: left;
}

.community_info h3,
h4 {
    font-weight: 700;
    color: rgb(170, 165, 165);
}

.bottom_info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.bottom_info span {
    font-size: 14px;
    color: rgb(168, 167, 167);
}
</style>
  
<template>
    <div class="community_statistic">
        <div>
            <h3>{{header}}</h3>
        </div>
        <div class="info_wrapper">
            <div v-for="(item, index) in data" :key="index" class="info">
                <div class="info_inner">
                    <h3>{{ item.value }}</h3>
                    <span>{{ item.langTR }}</span>
                </div>
                <img alt="avatar" :src="item.icon" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            header: 'İstatistikler'
        }
    },
    props: {
        data: {
            type: Array,
            required: true
        }
    }
    ,
    methods: {},
    async created() { },
    components: {}
}
</script>

<style scoped>
.info_inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.community_statistic {
    background-color: #1A1A1B;
    color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 18%;
    padding: 0.8rem 1rem;
    border: 1px rgb(58 58 58) solid;
    border-radius: 6px;
    gap: 5px;
}

.community_statistic p {
    color: white;
}

.community_statistic h3,
h4 {
    font-weight: 700;
    color: rgb(170, 165, 165);
}

.community_statistic img {
    width: 25px;
    height: 25px;
}

.info {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
}

.info_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.online-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: lightgreen;
}

.online_wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 8%;
}

@media screen and (max-width: 920px) {
    .community_statistic {
        border-radius: 0px;
        border: none;
    }

}
</style>
import moment from "moment";

export function getFormattedDate(time) {
    return moment(time).format('DD-MM-YYYY')
}

export function getFormattedYear(time) {
    return moment(time).format('YYYY')
}

export function getFormatYearMonth(time) {
    return moment(time).format('YYYY-MM-DD')
}

export function getDateDiffNow(init) {
    var start = moment(init).format("YYYY-MM-DD hh:ss");
    var end = moment();
    const diffInHours = end.diff(start, 'hours');

    return diffInHours;
}
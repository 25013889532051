<template>
    <div @click="checkOutsideClick" class="modal-a" :class="{ active: modalOpen }">
        <div class="create_post_div">
            <div class="top">
                <div>
                    <span class="category-font">
                        {{ text }}
                    </span>
                </div>
                <button @click="this.$emit('close')" class="icon_button">
                    <img class="small-icon" src="../../assets/images/close.png" />
                </button>
            </div>
            <div class="post_body">
                <div class="input_div">
                    <span class="body-font">Topluluk İsmi</span>
                    <div class="input-container">
                        <input v-model="this.community.name" placeholder="" />
                        <span class="remain">{{ remainingTitleCharacters }}</span>
                    </div>
                </div>
                <div>
                    <span class="body-font">Topluluk Açıklaması</span>
                    <div class="text_area">
                        <textarea class="title" v-model="this.community.description" rows="10" required cols="2"
                            placeholder="" />
                    </div>
                </div>
            </div>
            <div class="attach">
                <div style="display: flex; justify-content: flex-end;">
                    <button @click="submit" class="custom_button">
                        <span v-if="loading" class="loader"></span>
                        <span v-else> {{ share }} </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            share: 'Oluştur',
            text: 'Topluluğunu Oluştur',
            community: {
                name: '',
                description: ''
            }
        }
    },
    props: {
        modalOpen: {
            type: Boolean,
            required: true
        },
        loading: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    methods: {
        submit() {
            if (this.community.name === '' || this.community.description === '') return;
            this.$emit('submit', this.community);
        },
        validateName() {
            this.$emit('validate-title', this.community.name);
        },
        checkOutsideClick(event) {
            if (!this.$el.contains(event.target.closest('.input_div'))) {
                if (this.community.name === '') return;
                this.validateName();
            }
        },

    },
    computed: {
        remainingTitleCharacters() {
            const maxTitleCharacters = 140;
            const remainingCharacters = maxTitleCharacters - this.community.name.length;
            return `${remainingCharacters} / ${maxTitleCharacters} `;
        }
    },
    async created() { },
    components: {}
}
</script>

<style scoped>
.loader {
    width: 30px;
    height: 30px;
}

.body-font {
    color: black;
    font-weight: 600;
}

.input_div input {
    width: 100%;
    height: 45px;
    border-radius: 8px;
}

.input_div:hover input {
    border: 2px solid #f8b632;
}

.input-container {
    position: relative;
    width: 100%;
}

.input_div {}

.remain {
    position: absolute;
    bottom: 10px;
    right: 2%;
    font-size: 2vh;
    font-weight: bold;
    color: rgb(161, 158, 158);
}

.mid-font {
    color: #000000;
}

.attach {
    width: 100%;
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.text_area {
    height: 75%;
}

.text_area textarea {
    width: 100%;
    height: 100%;
    background-color: #000000;
    border: 2px solid gray;
    border-radius: 8px;
    padding: 10px;
    color: white;
}

.text_area:hover textarea {
    border-color: #f8b632;
}

.post_body {
    width: 100%;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 6px;
    gap: 2.5%;
}

.category-font {
    color: black;
    font-weight: 900;
    padding-left: 0px;
}

.modal-a {
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 6;
}

.modal-a.active {
    display: flex;
}

.create_post_div {
    position: fixed;
    top: 20%;
    left: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 70%;
    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(215, 214, 214, 1) 35%, rgba(175, 176, 176, 1) 100%);
    box-shadow: 5px 5px 0px 0px rgba(246, 246, 246, 0.4);
    border: 1px gray solid;
    z-index: 5;
    border-radius: 6px;
}

.top {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.icon_button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80px;
    height: 40px;
    border: none;
    background-color: transparent;
    transition: 1s ease-in-out;
}

.icon_button:hover img {
    transform: scale(1.1);
}

.attach img {
    width: 45px;
}

.attach .icon_button {
    width: 40px;
}

.custom_button {
    width: 140px;
    background: rgb(192, 151, 63);
    background: linear-gradient(38deg, rgba(226, 191, 118, 1) 0%, rgba(255, 223, 156, 1) 35%, rgba(254, 201, 1, 1) 100%);
    color: black;
    text-shadow: 2px 2px 2px white;
    font-weight: 500;
    border-radius: 8px;
}

.custom_button:hover {
    background: rgb(192, 151, 63);
    background: linear-gradient(38deg, rgb(175, 154, 110) 0%, rgb(244, 202, 111) 35%, rgb(228, 181, 10) 100%);
}
</style>
import { apiInstance } from './ApiService.js'

const API_URL = '/comment'

const commentByMovieId = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + '/all', {
      params: {
        limit: req.limit,
        order_by: req.order_by,
        order_dir: req.order_dir,
        movie_id: req.movie_id
      }
    })
    if (!response.data.success) return [];
    return response.data.comments
  } catch (error) {
    return []
  }
}

const movieCommentLike = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + '/like', 
    {
      user_id: req.user_id,
      movie_id: req.movie_id,
      comment_id: req.comment_id
    })
    if (!response) return false;

    return response.data.success;
  } catch (error) {
    return false;
  }
}

const commentByUserId = async (req) => {

  try {

    const response = await apiInstance.get(API_URL + '/user', {
      params: {
        limit: req.limit,
        order_by: req.order_by,
        order_dir: req.order_dir,
        user_id: req.user_id
      }
    });

    if (!response.data.success) return [];
    return response.data
  } catch (error) {
    return []
  }
}

const CommentService = {
  commentByMovieId,
  commentByUserId,
  movieCommentLike
}

export default CommentService;
<template>
  <NavBar />
  <div class="top_margin_div"></div>
  <div class="wrapper">
    <div v-if="!isMobile" class="main_container">
      <div class="left">
        <CreatePost @show-modal="showPostModal" />
        <div class="filter_bar">
          <FilterBar :items="filters" @sort="handleSort" />
        </div>
        <ThreadCard :member="user.member" @go-to-link="goLink" v-for="(item, index) in posts" :item="item"
          :key="index" />
      </div>

      <div class="right">
        <div v-if="!communityInfoLoading">
          <CommunityInfo :member="user.member" @communityAction="setAction" :item="communityInfo" />
        </div>
        <div v-if="!communityInfoLoading">
          <CommunityStatistic :data="communityStatistic" />
        </div>
        <div>
          <ActiveUser :loading="activeLoading" @filterUser="filterUsers" :tabs="tabs" :items="activeUsers" />
        </div>
        <ModeratorCard :items="moderators" />
      </div>
    </div>
    <div v-if="isMobile" class="main_container">
      <div class="mobile_top">
        <div v-if="!communityInfoLoading">
          <MobileCommunityInfo :showText="showText" :member="user.member" @communityAction="setAction"
            @show="setShowMore" :item="communityInfo" />
        </div>
        <div v-if="isShowMore && isMobile">
          <div v-if="!communityInfoLoading">
            <CommunityStatistic :data="communityStatistic" />
          </div>
          <div>
            <ActiveUser :loading="activeLoading" @filterUser="filterUsers" :tabs="tabs" :items="activeUsers" />
          </div>
          <ModeratorCard :items="moderators" />
        </div>
        <div class="post_area">
          <CreatePost @show-modal="showPostModal" />
          <div class="filter_bar">
            <FilterBar :items="filters" @sort="handleSort" />
          </div>
          <ThreadCard :member="user.member" @go-to-link="goLink" v-for="(item, index) in posts" :item="item"
            :key="index" />
        </div>
      </div>
    </div>
    <div v-if="!userLoading">
      <CreatePostModal :loading="postLoading" @submit="submitPost" :filter-list="filterList"
        :selectedItem="selectedCommunity" :communityOptions="communityOptions" :modalOpen="showModal" v-if="showModal"
        @close="closePostModal" />
    </div>
    <InfoModal :duration="'3s'" :type="'error'" v-if="showInfoModal" :message="infoMessage"
      @close="showInfoModal = false" />
  </div>
</template>

<script>
import PostService from '../services/PostService';
import CommunityService from '../services/CommunityService';
import UserService from '../services/UserService';
import ActiveUser from '../components/PostComponents/ActiveUser.vue';

import CreatePost from '../components/PostComponents/CreatePost.vue';
import FilterBar from '../components/PostComponents/FilterBar.vue';
import ThreadCard from '../components/PostComponents/ThreadCard.vue';
import CommunityInfo from '../components/PostComponents/CommunityInfo.vue';
import MobileCommunityInfo from '../components/Community/MobileCommunityInfo.vue';
import CommunityStatistic from '../components/PostComponents/CommunityStatistic.vue';
import ModeratorCard from '../components/PostComponents/ModeratorCard.vue'
import InfoModal from '../components/shared/MessageModal.vue';
import CreatePostModal from '../components/PostComponents/CreatePostModal.vue';

import ippo from '../assets/images/ippo.jpg';
import avat from '../assets/images/avatar.png';
import anime from '../assets/images/anime.jpg';
import router from '../router/index.js';

import postIcon from "../assets/images/paper.png"
import memberIcon from "../assets/images/group.png"
import hotIcon from '../assets/images/hot.png';
import newIcon from '../assets/images/new.png';
import riseIcon from '../assets/images/increase.png';

import { useUserStore } from '../store/user.js'

export default {
  data() {
    return {
      communityBackgroundImage: anime,
      isMobile: false,
      communityID: null,
      selectedPostId: null,
      communityInfoLoading: false,
      userLoading: true,
      postLoading: false,
      isShowMore: false,
      showText: 'Detayları Göster...',
      posts: [],
      moderators: [
        { name: 'numan', uid: 1, url: avat },
        { name: 'ippo', uid: 2, url: ippo },
      ],
      communityStatistic: [
        { name: 'Posts', langTR: 'Gönderi',value: 0, icon: postIcon },
        { name: 'Members', langTR: 'Üye',value: 0, icon: memberIcon },
      ],
      tabs: [
        { name: 'Weekly', langTR: 'Haftalık',  id: 0, code: 'weekly' },
        { name: 'Monthly',  langTR: 'Aylık',id: 1, code: 'monthly' },
        { name: 'All', langTR: 'Yıllık',id: 2, code: 'all' },
      ],
      filters: [
        { name: 'Hot', langTR: 'Sıcak',url: hotIcon, type: 'hot' },
        { name: 'New',  langTR: 'Yeni',url: newIcon, type: 'latest' },
        { name: 'Top',  langTR: 'En İyiler',url: riseIcon, type: 'top' },
      ],
      sortObject: {
        orderDir: 'desc',
        orderBy: 'latest',
        limit: 12
      },
      rankFilters: {
        limit: 5,
        range: 'weekly'
      },
      communityInfo: {},
      memberIcon,
      postIcon,
      activeUsers: [],
      activeLoading: true,
      activeLimit: 3,
      showModal: false,
      showInfoModal: false,
      infoMessage: 'Please, try again later',
      user: {
        member: false
      },
      communityOptions: [],
      postCommunityID: null,
      selectedCommunity: null,
      errorDuration: 3000
    }
  },
  methods: {
    async submitPost(postObject) {

      this.postLoading = true;

      if (!this.validatePost(postObject)) return false;

      const response = await PostService.createPost({
        community_id: this.selectedCommunity.id,
        user_id: this.user.user_id,
        title: postObject.title,
        content: postObject.body
      });

      if (!response) {
        this.handlePostSubmissionFailure();
        return false;
      }
      const htmlElement = document.querySelector('html');
      htmlElement.style.overflowY = 'auto';
      
      this.postLoading = false;
      return this.$router.push({ name: 'post', params: { communityId: response.community_id, postId: response.post_id } })
    },
    async getUserCommunity() {

      if (!this.user) return this.userLoading = false;

      this.userLoading = true;
      const res = await UserService.getUserCommunities({
        user_id: this.user.user_id
      });

      if (!res) { return this.communityOptions = [] };

      this.setCommunityOptions(res);
      await this.setMemberStatus(res);
      await this.setSelectedCommunity();

      this.userLoading = false;
    },
    async setSelectedCommunity() {
      this.selectedCommunity = this.communityOptions.find(e => e.id === parseInt(this.communityID));
      if (!this.selectedCommunity) this.selectedCommunity = this.communityOptions[0];
    },
    setCommunityOptions(res) {
      this.communityOptions = res.map(community => ({
        id: community.community_id,
        option: community.name,
        icon: community.icon ?? avat
      }));
    },
    async setMemberStatus(subscribedCommunities) {
      const value = subscribedCommunities.find(e => e.community_id === this.communityID) ?? false;
      this.user.member = (value);
      if (!value) return this.user.member = false;
      return this.user.member = true;
    },
    filterList(select) {
      this.selectedCommunity = select;
      this.postCommunityID = select.id;
    },
    async filterUsers(select) {
      this.rankFilters.range = select;
      return await this.getActiveUsers();
    },
    async goLink(postId) {
      this.selectedPostId = postId;
      return router.push({ name: 'post', params: { communityId: this.communityID, postId: this.selectedPostId } });
    },
    async handleSort(orderBy) {
      if (!orderBy) return;
      this.sortObject.orderBy = orderBy;
      await this.getPosts();
    },
    async getPosts() {
      const response = await PostService.getPosts({
        limit: this.sortObject.limit,
        order_by: this.sortObject.orderBy,
        order_dir: this.sortObject.orderDir,
        community_id: this.communityID
      })
      if (!response) return this.posts = [];
      this.posts = response;
    },
    async getCommunity() {

      this.communityInfoLoading = true;

      const response = await CommunityService.getOneCommunity({
        community_id: this.communityID
      });

      if (!response) { return this.communityInfoLoading = false; }

      this.communityInfo = response;

      this.communityStatistic[0].value = response.post_count;
      this.communityStatistic[1].value = response.member_count;

      this.communityInfoLoading = false;

    },
    setShowMore() {
      this.isShowMore = !this.isShowMore;
      if (this.isShowMore) return this.showText = 'Detayları Gizle...';
      return this.showText = 'Detayları Göster...';
    },
    async getActiveUsers() {
      this.activeLoading = true;
      const response = await CommunityService.getMostActiveUsers({
        community_id: this.communityID,
        limit: this.rankFilters.limit,
        range: this.rankFilters.range
      });
      if (!response) return this.activeLoading = false;
      this.activeUsers = response;
      this.activeLoading = false;
    },
    async setAction(communityID) {
      if (this.user.member) return await this.leaveCommunity(communityID);
      return await this.joinCommunity(communityID)
    },
    async joinCommunity(communityID) {

      if (communityID === -1) return;

      this.joinLoading = true;

      const response = await CommunityService.subscribeOneCommunity({
        community_id: communityID,
        user_id: this.user.user_id
      });

      if (!response) { this.joinLoading = false; };
      if (response !== communityID) { return this.joinLoading = false; };

      this.joinLoading = false;

      return;
    },
    async leaveCommunity(communityID) {
      console.log(communityID)
      if (communityID === -1) return;

      this.joinLoading = true;

      const response = await CommunityService.unsubscribeOneCommunity({
        community_id: communityID,
        user_id: this.user.user_id
      });
      console.log(response, "??")
      if (!response) { this.joinLoading = false; };
      if (response !== communityID) { return this.joinLoading = false; };

      this.joinLoading = false;
    },
    handlePostSubmissionFailure() {
      this.infoMessage = 'Pleaes, try again later.'
      this.postLoading = false;
      this.closePostModal();
      this.openInfoModal();
    },
    validatePost(postObject) {
      if (!postObject || postObject.title === '' || postObject.body === '') {
        this.infoMessage = 'You must fill in the title and content sections.'
        this.closePostModal();
        this.openInfoModal();
        return false;
      }
      return true;
    },
    closePostModal() {
      this.showModal = false;
      this.postLoading = false;
      const htmlElement = document.querySelector('html');
      htmlElement.style.overflowY = 'auto';
      //document.querySelector("body").style.overflowY = 'scroll';
    },
    openInfoModal() {
      this.showInfoModal = true;

      setTimeout(() => {
        this.showInfoModal = false;
      }, this.errorDuration);
    },
    showPostModal(control) {
      this.showModal = control;
      const htmlElement = document.querySelector('html');
      htmlElement.style.overflowY = 'hidden';
    },
    checkMobile() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  async created() {
    this.checkMobile();
    this.user = useUserStore().user;
    if (!this.user) this.user = { member: false };
    this.communityID = parseInt(this.$route.params.id);
    await this.getUserCommunity();
    await this.getPosts();
    await this.getCommunity();
    await this.getActiveUsers();
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    })
  },
  components: { CreatePost, FilterBar, ThreadCard, CommunityInfo, MobileCommunityInfo, CommunityStatistic, ActiveUser, ModeratorCard, CreatePostModal, InfoModal }
}
</script>

<style scoped>
.wrapper {
  background-color: #030303;
  z-index: 3;
}

.main_container {
  display: flex;
  gap: 2%;
  padding: 1rem;
  height: 100%;
  z-index: 5;
}

.top_margin_div {
    height: 11vh;
}

.left {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 5px;
  z-index: 3;
}

.right {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  border-radius: 6px;
  gap: 10px;
  z-index: 3;
}

.post_wrapper {
  display: flex;
}

.filter_bar {
  position: sticky;
  top: 0%;
}

.background_image {
  min-height: 100%;
  min-width: 1024px;
  z-index: 1;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}


@media screen and (max-width: 920px) {

  .main_container {
    display: flex;
    gap: 2%;
    padding: 0.1em;
    height: 100%;
    z-index: 5;
  }

  .mobile_top {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    gap: 10px;
    z-index: 3;
  }

  .post_area {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .top_margin_div {
    height: 1vh;
}
}
</style>

<template>
    <div class="outside">
        <div class="background-image" :style="{ backgroundImage: `url(${background})` }"></div>
        <div class="clapper">
            <div class="clapper-top">
                <div class="clapper-top-item"></div>
                <div class="clapper-top-item"></div>
                <div class="clapper-top-item"></div>
            </div>
            <div class="clapper-bottom-top">
                <div class="clapper-top-item2"></div>
                <div class="clapper-top-item2"></div>
                <div class="clapper-top-item2"></div>
            </div>
            <template v-if="signClick">
                <div class="clapper-bottom">
                    <div class="clapper-info"></div>
                    <div class="clapper-info" @click="googleSignIn"></div>
                    <div class="clapper-info"></div>
                    <div class="clapper-info"></div>
                    <label for="email">Email:</label>
                    <input v-model="mail" type="text">
                    <label for="password">Şifre:</label>
                    <input v-model="password" type="password">
                    <button class="clapper-button-sign" @click="mailSignIn">Giriş Yap</button>
                    <button class="clapper-button-register" @click="addAnim(1)">Kayıt Ol</button>
                </div>
            </template>
            <template v-if="registerClick">
                <div class="clapper-bottom">
                    <div class="clapper-info-register"></div>
                    <div class="clapper-info-register" @click="mailSignIn"></div>
                    <div class="clapper-info-register"></div>
                    <div class="clapper-info-register"></div>
                    <label for="username">Kullanıcı Adı:</label>
                    <input v-model="registerUserName" type="text">
                    <label for="email">EMail:</label>
                    <input v-model="registerMail" type="text">
                    <label for="password">Şifre:</label>
                    <input v-model="registerPassword" type="text">
                    <label for="passwordagain">Şifre:</label>
                    <input type="password">
                    <button class="clapper-button-sign" @click="addAnim(0)">Sign In</button>
                    <button class="clapper-button-register" @click="mailRegister">Register</button>
                </div>
            </template>
        </div>
       
    </div>
</template>

<script>
import { googleSdkLoaded } from "vue3-google-login";
import AuthService from '../services/AuthService.js';
import background from '../assets/images/background.png';

export default {
    data() {
        return {
            userDetails: null,
            movies: [],
            loading: false,
            title: 'Most Viewed',
            signClick: true,
            registerClick: false,
            registering: false,
            signing: false,
            mail: null,
            password: null,
            userName: null,
            registerMail: null,
            registerPassword: null,
            registerUserName: null,
            background
        }
    },
    methods: {
        addAnim(number) {
            if (number == 0) {
                this.signClick = true;
                this.registerClick = false;
                this.signing = true;
                this.anim();
            } else {
                this.signClick = false;
                this.registerClick = true;
                this.registering = true;
                this.anim();
            }
        },
        googleSignIn() {
            const clientId = '565268843353-up2r9mjns4bsmcp21fm8osdp8i2ffkgu.apps.googleusercontent.com';

            googleSdkLoaded(google => {
                google.accounts.oauth2
                    .initCodeClient({
                        client_id: clientId,
                        scope: "email profile openid",
                        redirect_uri: "https://www.mosecop.com/home",
                        callback: response => {
                            if (response.code) {
                                this.sendCodeToBackend(response.code);
                            }
                        }
                    })
                    .requestCode();
            });
        },
        async sendCodeToBackend(code) {
            const response = await AuthService.google({ code: code });
            if (!response) return this.$router.push({ path: '/login' });
            this.clapperAnimation();
        },
        async mailSignIn() {
            const response = await AuthService.signWithMail({ mail: this.mail, passcode: this.password });
            if (!response) return this.$router.push({ path: '/login' });
            this.clapperAnimation();
        },
        async mailRegister() {
            const response = await AuthService.registerWithMail({
                user: {
                    mail: this.registerMail,
                    passcode: this.registerPassword,
                    userName: this.registerUserName
                }
            });
            if (!response) return this.$router.push({ path: '/login' });
        },
        clapperAnimation() {
            var clapperTop = document.querySelector(".clapper-top");
            var clapper = document.querySelector(".clapper");

            setTimeout(() => {
                clapperTop.classList.add("clapper-top-animation-in");
                clapperTop.classList.remove("clapper-top-animation-out");
            }, 200);

            setTimeout(() => {
                clapperTop.classList.add("clapper-top-animation-out");
                clapperTop.classList.remove("clapper-top-animation-in");
                this.signing = false;
                this.registering = false;

                clapper.classList.add("scale-up");
                setTimeout(() => {
                    return this.$router.push({ path: '/home' });
                }, 1100);
            }, 2000);
        },
        anim() {

            var clapperTop = document.querySelector(".clapper-top");
            //var clapper = document.querySelector(".clapper");

            setTimeout(() => {
                clapperTop.classList.add("clapper-top-animation-in");
                clapperTop.classList.remove("clapper-top-animation-out");
            }, 200);

            setTimeout(() => {
                clapperTop.classList.add("clapper-top-animation-out");
                clapperTop.classList.remove("clapper-top-animation-in");
                this.signing = false;
                this.registering = false;

            }, 2000);
        }
    },
    components: {}
}
</script>

<style scoped>

h4 {
    color: white;
}

.outside {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    position: relative;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    backdrop-filter: blur(12px);
    filter: brightness(0.45) blur(1px);
}

.scale-up {
    animation: scaleUp 2s ease-in-out both;
}

@keyframes scaleUp {
    from {
        scale: 1;
    }

    to {
        scale: 100;
    }
}

.clapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0;
}

.clapper-top {
    display: grid;
    width: max(32%, 320px);
    height: 8%;
    background-color: black;
    rotate: -10deg;
    transform-origin: 0;
    padding: 10px;
    grid-template-columns: 20% repeat(3, 20%) 20%;
    grid-template-rows: 100%;
    gap: 1rem;
}

.clapper-top-item {
    background-color: white;
    transform: skew(30deg);
    width: 100%;
    height: 100%;
}

.clapper-top>.clapper-top-item:nth-child(1) {
    grid-column: 2/3;
}

.clapper-top>.clapper-top-item:nth-child(2) {
    grid-column: 3/4;
}

.clapper-top>.clapper-top-item:nth-child(3) {
    grid-column: 4/5;

}

.clapper-bottom-top {
    display: grid;
    width: max(32%, 320px);
    height: 8%;
    background-color: black;
    padding: 10px;
    grid-template-columns: 20% repeat(3, 20%) 20%;
    grid-template-rows: 100%;
    gap: 1rem;
}

.clapper-connection {
    position: absolute;
    display: grid;
    grid-template-rows: repeat(3, 33%);
    width: 2rem;
    height: 5rem;
    background-color: black;
    top: 24.5%;
    left: 35%;
    border-radius: 10px;
    border: 1px solid white;
}

.clapper-dot {
    background-color: gray;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    margin: 8%;
}

.clapper-connection>.clapper-dot:nth-child(1) {
    grid-row: 1/2;
}

.clapper-connection>.clapper-dot:nth-child(2) {
    grid-row: 3/4;
}

.clapper-top-item2 {
    background-color: white;
    transform: skew(-30deg);
    width: 100%;
    height: 100%;

}

.clapper-bottom-top>.clapper-top-item2:nth-child(1) {
    grid-column: 2/3;
}

.clapper-bottom-top>.clapper-top-item2:nth-child(2) {
    grid-column: 3/4;
}

.clapper-bottom-top>.clapper-top-item2:nth-child(3) {
    grid-column: 4/5;

}

.clapper-bottom {
    width: max(30%, 300px);
    height: 300px;
    background-color: black;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: repeat(10, 10%);
    padding: 10px;
    border-radius: 0% 0% 5% 5%;
}

.clapper-bottom>label,
input {
    border: 1px solid white;
    background-color: black;
    color: white;
    height: 25px;
    padding: 0;
    padding-left: 10px;

}

.clapper-bottom>label {
    grid-column: 1/2;
}

.clapper-bottom>input {
    grid-column: 2/4;
}

.clapper-info,
.clapper-info-register {
    border: 1px solid white;
    grid-row: 2/7;
    animation: loginAnimation 0.5s ease-in both;
}

label,
input {
    animation: loginAnimation 0.5s ease-in both;
}

.clapper-info-register {
    grid-row: 2/5;
}

.clapper-info-register:nth-child(1) {
    grid-row: 1/2;
    grid-column: 1/4;
    border: none;
}

.clapper-bottom>.clapper-info:nth-child(1) {
    grid-row: 1/2;
    grid-column: 1/4;
    border: none;
}

.clapper-bottom>.clapper-info:nth-child(2) {
    background: url(../assets/images/google.png);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.clapper-bottom>.clapper-info-register:nth-child(2) {
    background: url(../assets/images/google.png);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
}

.clapper-bottom>.clapper-info:nth-child(2):hover,
.clapper-info-register:nth-child(2):hover {
    transition: 0.2s;
    filter: brightness(80%);
    border: 1px solid #f8b632;
    cursor: pointer;
}


.clapper-bottom>.clapper-info:nth-child(3) {
    background-color: black;
}

.clapper-bottom>.clapper-info:nth-child(4) {
    background-color: black;
}

.clapper-button-sign {
    grid-column: 2/4;
    grid-row: 9/10;
}

.clapper-button-register {
    grid-column: 2/4;
    grid-row: 10/11;
}

.clapper-button-sign,
.clapper-button-register {
    background-color: black;
    border: 1px solid white;
    color: white;
    border-radius: 5px;
    height: 80%;
}

.clapper-button-sign:hover,
.clapper-button-register:hover {
    background-color: white;
    border: 1px solid black;
    color: black;
}

.clapper-top-animation-in {
    animation: clapperIn 1s ease both;
}

.clapper-top-animation-out {
    animation: clapperOut 1s ease both;
}

.login-animation {
    animation: loginAnimation 1s ease-int both;
}

.clapper-top,
.clapper-bottom,
.clapper-bottom-top {
    box-shadow: 0px 1px 8px #a6a5a589;
}

@keyframes loginAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes clapperIn {
    from {
        rotate: -10deg;
    }

    to {
        rotate: 0deg;
    }

}

@keyframes clapperOut {
    from {
        rotate: 0deg;

    }

    to {
        rotate: -10deg;
    }

}

@media (max-width: 35em) {
    .clapper-connection {


        top: 9rem;
        left: 3rem;

    }
}
</style>
<template>
    <template v-if="item == undefined || item == null">
        <div class="blur-container" style="user-select: none">
            <div class="blur-comment" style="user-select: none">
                <div class="user-comment" style="user-select: none">
                    <div class="user">
                        <img class="circle-avatar" :src="avatar" />
                    </div>
                    <div class="comment_right">
                        <textarea type="text" id="userComment" style="pointer-events: none; font-size: 12pt"></textarea>
                    </div>
                </div>
            </div>
            <div class="blur-text">
                You must <a @click="go" class="to-login" style="cursor: pointer;">LOGIN</a> to comment
            </div>
        </div>
    </template>
    <template v-else>
        <div class="share-comment">
            <div class="user">
                <img class="circle-avatar" :src="item.img" />
            </div>
            <div class="comment_right">
                <div class="comment-user-info align-bottom">
                    <div class="comment-username">{{ item.name }}</div>
                </div>
                <div class="comment">
                    <textarea class="title" v-model="comment" rows="10" required cols="2"
                        placeholder="Share your comment." />
                </div>
                <div class="btn_div">
                    <button @click="submit" class="custom_button">Yorum yap</button>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
import moment from 'moment'
import rankIcon from '../../assets/images/rank.png';
import avatar from '../../assets/images/avatar.png'

export default {
    props: {
        item: {
            type: Object,
            required: true,
            default: null
        }
    },
    data() {
        return {
            rankIcon: rankIcon,
            comment: '',
            avatar
        }
    },
    methods: {
        formatDate(time) {
            return moment(time).format('DD-MM-YYYY')
        },
        submit() {
            if (this.comment === '' || this.comment == undefined) return;
            this.$emit('submit', this.comment);
        },
        go() {
            this.$emit('go', 'login');
        }
    },
    mounted() { },
    created() { }
}
</script>

<style scoped>
#userComment {
    height: 100%;
}

.blur-container {
    height: 120px;
    display: flex;
    flex-direction: row;
    background-color: #232323;
    position: relative;
}

.blur-comment {
    filter: blur(3px);
    padding-right: 20px;
    height: 120px;
    width: 100%;
    padding: 1rem 0.5rem;
    position: relative;
}

.blur-comment .user-comment {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.blur-text {
    position: absolute;
    z-index: 2;
    left: 40%;
    top: 40%;
    color: black;
    font-weight: 600;
    font-size: 16px;
}

.share-comment {
    color: white;
    background-color: #232323;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    min-height: 100px;
    padding: 1rem 0.8rem;
    transition: 0.4s;
    border: 1px solid transparent;
    max-height: 180px;
}

.share-comment .user {
    justify-content: flex-start;
}

.comment {
    border-radius: 5px;
    word-wrap: break-word;
    height: 100%;
}

.comment textarea {
    height: 100%;
    max-height: 110px;
    resize: vertical;
    border-radius: 5px;
    padding: 0.4rem;
}

.comment_right {
    gap: 0px;
    height: 100%;
}

.custom_button {
    width: 120px;
    height: 30px;
    border-radius: 12px;
    background-color: #f8b632;
    background-image: none;
    color: black;
    transition: 0.5s;
}

.custom_button:hover {
    background-color: #eb950b;
}

.btn_div {
    display: flex;
    justify-content: flex-end;
}
</style>
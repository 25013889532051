import './assets/styles/main.css';
import './assets/styles/style.css';
import YouTube from 'vue3-youtube';
import Vue3Lottie from 'vue3-lottie'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import NavBar from "./components/shared/NavBar.vue";
import FooterBar from "./components/shared/FooterBar.vue";
import Loader from "./components/shared/Loader.vue";
import ClapperButton from "./components/shared/ClapperButton.vue";
import LoginTemplate from "./components/shared/LoginTemplate.vue";
import CustomButton from "./components/shared/CustomButton.vue";
import InfoModal from './components/shared/MessageModal.vue';
import PaginationBar from './components/shared/PaginationBar.vue';
import { Icon } from '@iconify/vue';
const app = createApp(App);

app.component('YouTube', YouTube)
app.component("NavBar", NavBar);
app.component("FooterBar", FooterBar);
app.component("LoaderBar", Loader);
app.component("ClapperButton", ClapperButton);
app.component("LoginTemplate", LoginTemplate);
app.component("CustomButton", CustomButton);
app.component("InfoModal", InfoModal);
app.component("PaginationBar", PaginationBar);
app.component("VueIcon", Icon);

app.use(createPinia())
app.use(router);
app.use(Vue3Lottie);

app.mount('#app')

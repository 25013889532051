import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import MovieView from '../views/MovieView.vue'
import MovieViews from '../views/MoviesView.vue'
import ContactView from '../views/ContactView.vue';
import WatchlistView from '../views/WatchlistView.vue';
import CommunityView from '../views/CommunityView.vue';
import CommunityDetailView from '../views/CommunityDetailView.vue';
import PostView from '../views/PostView.vue';
import CommunitySearch from '../views/CommunitySearch.vue';
import UserDetail from '../views/UserDetail.vue';
import LandingView from '../views/LandingView.vue';
import RecommendationView from '../views/RecommendationView.vue';
import EditWatchlist from '../views/EditWatchlistView.vue';
import CreateWatchlistView from '../views/CreateWatchlistView.vue';
import SettingsView from '../views/SettingsView.vue';

import AllWatchlist from '../views/AllWatchlistView.vue';
import PersonalWatchlist from '../views/PersonalWatchlistsView.vue';
import WatchlistDetail from '../views/WatchlistDetailView.vue';
import AIView from '../views/AIView.vue';

import { useUserStore } from '../store/user.js'

const router = createRouter({
  history: createWebHistory(),
  routes: [

    {
      path: '/',
      name: 'landing',
      component: LandingView,
      meta: { title: 'Mosecop: Anasayfa' }
    },
    {
      path: '/home',
      name: 'home',
      component: HomeView,
      meta: { title: 'Mosecop: Anasayfa' }
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsView,
      meta: { title: 'Mosecop: Ayarlar' }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: { title: 'Mosecop: Giriş Yap' }
    },
    {
      path: '/movie/:id',
      name: 'movie',
      component: MovieView,
      meta: { title: 'Mosecop: Film' }
    },
    {
      path: '/movies',
      name: 'movies',
      component: MovieViews,
      meta: { title: 'Mosecop: Filmler' }
    },
    {
      path: '/ai',
      name: 'ai',
      component: AIView,
      meta: { title: 'Mosecop: Filmler' }
    },
    {
      path: '/recommendations',
      name: 'recommendations',
      component: RecommendationView,
      meta: { title: 'Mosecop: Öneriler' }
    },
    {
      path: '/community',
      name: 'community',
      component: CommunityView,
      meta: { title: 'Mosecop: Topluluk' }
    },
    {
      path: '/community/:id',
      name: 'community-detail',
      component: CommunityDetailView,
      meta: { title: 'Mosecop: Topluluk' }
    },
    {
      path: '/community/:communityId/post/:postId',
      name: 'post',
      component: PostView,
      meta: { title: 'Mosecop: Gönderi' }
    },
    {
      path: '/community-search',
      name: 'community-search',
      component: CommunitySearch,
      meta: { title: 'Mosecop: Keşfet' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactView,
      meta: { title: 'FIP: İletişim' }
    },
    {
      path: '/details',
      name: 'details',
      component: UserDetail,
      meta: { title: 'Mosecop: Profilim' }
    },
    {
      path: '/list',
      name: 'list',
      component: WatchlistView,
      meta: { title: 'Mosecop: İzleme Listesi' }
    },
    {
      path: '/my-watchlists',
      name: 'my-watchlists',
      component: PersonalWatchlist,
      meta: { title: 'Mosecop: İzleme Listelerim' }
    },
    {
      path: '/watchlists',
      name: 'watchlists',
      component: AllWatchlist,
      meta: { title: 'Mosecop: İzleme Listelerini Keşfet' }
    },
    {
      path: '/watchlist/:id',
      name: 'watchlist',
      component: WatchlistDetail,
      meta: { title: 'Mosecop: İzleme Listelerini Keşfet' }
    },
    {
      path: '/watchlist/create',
      name: 'create-watchlist',
      component: CreateWatchlistView,
      meta: { title: 'Mosecop: İzleme Listeleni Oluştur' }
    },
    {
      path: '/watchlist/:id/edit',
      name: 'edit-watchlist',
      component: EditWatchlist,
      meta: { title: 'Mosecop: İzleme Listeni Düzenle' }
    },
  ]
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  await useUserStore().fetchUserDetails();
  next();
});

export default router

<template>
  <NavBar />
  <div class="wrapper">
    <div class="top_margin_div"></div>
    <div class="main_container">
      <div class="left">
        <PostContent @setReportType="setType" @report="report" @copyLink="sharePost" :comment="commentCount"
          @vote-post="voteForPost" v-if="!postLoading" :item="post" />
        <CreateComment :avatar="user.img" @comment-submitted="submitComment" />
        <FilterBar v-if="this.comments.length > 0" @sort="handleSort" />
        <div v-if="!commentLoading">
          <PostComment @vote-comment="voteForComment" v-for="(item, index) in comments" :key="index" :item="item" />
        </div>
      </div>
      <div class="right">
        <div class="stick">
          <CommunityInfo :member="user.member" :item="communityInfo" />
        </div>
      </div>
    </div>
  </div>
  <InfoModal :duration="'2s'" :type="'info'" v-if="showInfoModal" :message="infoMessage"
    @close="showInfoModal = false" />
  <ReportModal v-if="showReportModal" @submitReport="sendReport" :isVisible="showReportModal" :reasons="reportReasons"
    header="Gönderiyi Rapor Et" message="Rapor nedenini seçin." type="info" @close="showReportModal = false"
    duration="4s" />
  <FooterBar />
</template>

<script>
import UserService from '../services/UserService';
import PostService from '../services/PostService';
import CommunityService from '../services/CommunityService';
import PostCommentService from '../services/PostCommentService';
import PostContent from '../components/Post/PostContent.vue';
import PostComment from '../components/Post/PostComment.vue';
import CommunityInfo from '../components/PostComponents/CommunityInfo.vue';
import CreateComment from '../components/Post/CreateComment.vue';
import FilterBar from '../components/Post/FilterBar.vue'
import anime from '../assets/images/anime.jpg';
import ReportModal from '../components/Post/ReportModal.vue'
import { useUserStore } from '../store/user.js'


export default {
  data() {
    return {
      showReportModal: false,
      isMobile: false,
      communityBackgroundImage: anime,
      postID: null,
      communityID: null,
      limit: 20,
      post: {},
      postLoading: false,
      commentLoading: false,
      comments: [],
      communityInfo: {},
      communityInfoLoading: false,
      newComment: '',
      commentOrderBy: 'date',
      commentOrderDir: 'desc',
      commentCount: 0,
      reportReasons: [
        'Spam',
        'Uygunsuz içerik',
        'Taciz',
        'Küfür',
        'Diğer'
      ],
      user: {
        member: false
      },
      showInfoModal: false,
      infoMessage: 'İşlem başarılı.',
      modalDuration: 1500,
      reportType: null
    }
  },
  methods: {
    setType(type) {
      if (!type) return;
      this.reportType = type;
    },
    async sendReport(reason) {
      const post_id = this.post.post_id;
      const community_id = this.post.community_id;

      if (this.reportType == 'post') {
        const response = await CommunityService.reportCommunity({
          type: this.reportType,
          post_id: post_id,
          community_id: community_id,
          reason: reason
        });

        if(!response) return;
        this.openInfoModal('Gönderi şikayet edildi!');
      }
    },
    sharePost() {
      const link = this.$router.currentRoute.value.fullPath;
      if (!link) return;
      navigator.clipboard.writeText('https://www.mosecop.com' + link);
      this.openInfoModal('Gönderi linki kopyalandı.');
    },
    report() {
      this.showReportModal = true;
      this.reportType = 'post';
    },
    openInfoModal(msg) {
      this.showInfoModal = true
      this.infoMessage = msg;

      setTimeout(() => {
        this.showInfoModal = false
      }, this.modalDuration)
    },
    async getUserCommunity() {
      const res = await UserService.getUserCommunities({
        user_id: this.user.user_id
      });

      if (!res) { return this.user.member = false };

      this.setMemberStatus(res);
    },
    setMemberStatus(subscribedCommunities) {
      const value = subscribedCommunities.find(e => e.community_id == this.communityID) || false;
      this.user.member = value ? true : false;
    },
    async getPost() {
      this.postLoading = true;
      const response = await PostService.getOnePost({
        post_id: this.postID,
        community_id: this.communityID
      })
      if (!response) return;
      this.post = response;
      this.postLoading = false;
    },
    async getComments() {
      this.commentLoading = true;
      const response = await PostCommentService.getComments({
        post_id: this.postID,
        community_id: this.communityID,
        limit: this.limit,
        order_by: this.commentOrderBy,
        order_dir: this.commentOrderDir,
      })
      if (!response) return this.commentLoading = false;;
      this.comments = response;
      this.commentLoading = false;
    },
    async getCommunity() {
      this.communityInfoLoading = true;
      const response = await CommunityService.getOneCommunity({
        community_id: this.communityID
      });
      if (!response) return this.communityInfoLoading = false;
      this.communityInfo = response;
      this.communityInfoLoading = false;
    },
    async submitComment(comment) {
      const response = await PostCommentService.createComment({
        community_id: this.communityID,
        post_id: this.postID,
        content: comment,
        user_id: this.user.user_id
      });
      if (!response) return;
      return await this.getComments();
    },
    async handleSort(sortBy) {
      if (!sortBy) return;
      this.commentOrderBy = sortBy;
      await this.getComments();
    },
    async voteForComment(comment_id, vote) {
      if (!comment_id) return;
      const response = await PostCommentService.voteComment({
        community_id: this.communityID,
        post_id: this.postID,
        comment_id: comment_id,
        user_id: this.user.user_id,
        vote: vote
      });
      if (!response) return;
      return await this.getComments();
    },
    async voteForPost(vote) {
      if (vote === undefined) return;
      const response = await PostService.votePost({
        community_id: this.communityID,
        post_id: this.postID,
        user_id: this.user.user_id,
        vote: vote
      });
      if (!response) return;
      return await this.getPost();
    },
    checkMobile() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  async created() {
    this.checkMobile();

    document.querySelector("body").style.overflowY = 'auto';
    this.user = useUserStore().user;
    if (!this.user) this.user = { member: false };
    this.postID = this.$route.params.postId;
    this.communityID = this.$route.params.communityId;
    await this.getUserCommunity();
    await this.getPost();
    await this.getComments();
    await this.getCommunity();
    this.commentCount = this.comments.length;
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    })
  },
  components: { PostContent, PostComment, CommunityInfo, CreateComment, FilterBar, ReportModal }
}
</script>

<style scoped>
.wrapper {
  background-color: #030303;
  z-index: 3;
}

.top_margin_div {
    height: 15vh;
}

.main_container {
  display: flex;
  gap: 2%;
  padding: 1rem;
  height: 100%;
  z-index: 10;
}

.left {
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 10px;
  z-index: 3;
}

.right {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  border-radius: 6px;
  gap: 10px;
  z-index: 3;
}

.post_wrapper {
  display: flex;
}

.filter_bar {
  position: sticky;
  top: 10%;
}

.background_image {
  min-height: 100%;
  min-width: 1024px;
  z-index: 1;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 920px) {
  .main_container {
    flex-direction: column-reverse;
    gap: 10px;
    display: flex;
    padding: 0.3em;
    height: 100%;
    z-index: 10;
  }

  .right {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    gap: 10px;
  }

  .left {
    width: 100%;
  }

  .filter_bar {
    position: sticky;
    top: 0%;
  }

  .top_margin_div {
        height: 2vh;
    }

}
</style>
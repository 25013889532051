<template>
  <meta name="referrer" content="no-referrer" />
  <div class="movie-comment">
    <div class="user">
      <img v-if="item.user.profile_image_url" class="circle-avatar" :src="item.user.profile_image_url" />
      <img v-else class="circle-avatar" :src="avatar" />
      <div class="user-icon">
        <span class="badge_rank">{{ rank }}</span>
        <img style="height: 22px; width: 22px;" :src="rankIcon" />
      </div>
    </div>
    <div class="comment_right">
      <div class="comment-user-info align-bottom">
        <div class="comment-username">{{ item.user.username }}</div>
      </div>
      <div class="comment">
        {{ item.comment_text }}
      </div>
      <div class="comment-under-info">
        <div style="display: flex" class="comment-likes" @click="likeCommentMovie">
          <img :src="like" class="small-icon" />
          <span class="">{{ item.like_count }} </span>
        </div>

        <div class="date"><span class="text">Tarih: </span>{{ formatDate(item.created_time) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import rankIcon from '../../assets/images/rank.png';
import like from '../../assets/images/tickets.png';
import avatar from '../../assets/images/avatar.png';
import ImageUtil from '../../utils/ImageUtil';

export default {
  emits: ["likeComment"],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rankIcon: rankIcon,
      rank: '',
      like,
      avatar
    }
  },
  methods: {
    formatDate(time) {
      return moment(time).format('DD-MM-YYYY')
    },
    likeCommentMovie() {
      this.$emit('likeComment', this.item.comment_id, this.item.movie_id);
    },
    setBadge(user) {
      if (!user.user_rank.rank) return this.rankIcon = ImageUtil.discover;
      this.rankIcon = ImageUtil.getBadge(user.user_rank.rank.id);
    }
  },
  created() {
    if (!this.user) {
      this.rankIcon = ImageUtil.discover;
      this.rank = ''
    }
    this.setBadge(this.item.user);
    this.rank = this.item.user.user_rank.rank.name
  }
}
</script>

<style scoped>
.circle-avatar {
  width: 35px;
  border-radius: 50%;
}

.badge_rank {
  font-size: 13px;
  color: #f8b632;
}

.movie-comment {
  color: white;
  background-color: #232323;
  border-radius: 4px;
  margin-block: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  padding: 0.5rem 0.8rem;
  transition: 0.4s;
  border: 1px solid transparent;
}

.movie-comment:hover {
  background-color: #323232;
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 15%;
  gap: 5px;
}

.user-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.comment_right {
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment-user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-username {
  color: white;
  font-weight: bold;
}

.date {
  margin-left: auto;
}

.comment {
  word-wrap: break-word;
}

.avatar {
  pointer-events: none;
  width: 80%;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid black;
  margin: 2%;
  position: relative;
  left: 10%;
  top: 10%;
}

.comment-under-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.comment-likes {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.comment-likes:hover {
  background-color: #232323;
}

.comment-replies {
  margin-left: auto;
}

.small-icon {
  width: 28px;
}

.heart {
  background-image: url('../../assets/images/heart.png');
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
}

.heart:active {
  background-image: url('../../assets/images/heartwhite.png');
}

@media screen and (max-width: 920px) {
  .movie-comment {
    color: white;
    background-color: #232323;
    border-radius: 5px;
    margin-block: 8px;
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 100%;
    padding: 0.3rem 0.1rem;
    transition: 0.4s;
    border: 1px solid transparent;
  }

  .circle-avatar {
    width: 35px;
    border-radius: 50%;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 20%;
    gap: 5px;
  }

  .comment_right {
    width: 80%;
  }

  .text {
    display: none;
  }

  .badge_rank {
    font-size: 11px;
    color: #f8b632;
  }

  .date {
    font-size: 12px;
  }

  .movie-comment:hover {
    background-color: #232323;
  }

  .comment-likes:hover {
    background-color: rgb(8, 8, 8)23;
  }

  
}
</style>
<template>
    <div @click="route" class="community_card">
        <div class="img_poster">
            <img class="prevent-user-img poster-circle" :src="item.img === '' ? defaultIcon : item.img" />
        </div>
        <div class="info">
            <h3>{{ item.name }}</h3>
            <span>{{ item.member_count }} Üye</span>
            <span>{{ item.post_count }} Gönderi</span>
        </div>
        <div class="bottom">
            <button @click="route" class="join">Topluluğa Git</button>
        </div>
        <div class="content">
            <p>
                {{ item.description }}
            </p>
        </div>
    </div>
</template>

<script>
import defaultIcon from '../../assets/images/focus-group.png'


export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showedCount: 0,
            defaultIcon
        }
    },
    methods: {
        action() {
            this.$emit('action', this.item.community_id);
        },
        route() {
            return this.$emit('go', this.item.community_id);
        },
    },
    mounted() { },
    created() { },
    components: {}
}
</script>

<style scoped>
.img_poster {
    margin-top: 10px;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

h3 {
    font-weight: bold;
}

.join {
    background-color: #f8b632;
    height: 30px;
    border: none;
    width: 100%;
    border-radius: 15px;
    font-weight: 500;
    cursor: pointer;
}

.join:hover {
    background-color: orange;
}

.bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;
    gap: 5px;
}

.info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0.8rem 1.5rem;
    color: #f8b632;
    font-weight: bold;
}

.info span {
    color: #989a9b;
}

.content {
    display: none;
    transition: 1s;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.community_card {
    background-color: rgb(42, 41, 41);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    padding: 0.5rem 0rem;
}

.community_card:hover .content {
    display: block;
    width: 100%;
    padding: 0rem 1.5rem;
    background: linear-gradient(to bottom, rgb(208, 174, 73), rgb(234, 186, 11));
    z-index: 2;
    opacity: 1;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0.8rem 1.5rem;
    color: black;
    animation: fadeOutFromBlock 0.15s ease-in;
}

@keyframes fadeOutFromBlock {
    0% {
        opacity: 0;
    }

    0% {
        opacity: 0.25;
    }

    50% {
        opacity: 0.5;
    }

    75% {
        opacity: 0.75;
    }

    100% {
        opacity: 1;
    }
}

.community_card:hover {
    transform: translateY(-10px);
}

.poster-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.small-font-white {
    font-size: 12px;
    color: white;
}

.small-title-white {
    font-size: 13px;
    color: white;
}

.list_icon {
    width: 40px;
    height: 40px;
}

.action_btn {
    display: none;
}

.community_card:hover .button_wrap {
    top: 35%;
    left: 0%;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15%;
    height: 60px;
    width: 100%;
}

@media screen and (max-width: 500px) {
    .community_card {
        width: 100%;
    }
}
</style>
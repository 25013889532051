<!-- src/components/YoutubePlayer.vue -->

<template>
    <div>
        <iframe :width="iframeWidth" :height="iframeHeight" :src="youtubeEmbedUrl" frameborder="0" allowfullscreen></iframe>
    </div>
</template>
  
<script>
export default {
    props: {
        videoId: {
            type: String,
            required: true,
        },
        iframeWidth: {
            type: [String, Number],
            default: "500",  // Varsayılan genişlik değeri
        },
        iframeHeight: {
            type: [String, Number],
            default: "315", 
        },
    },
    computed: {
        youtubeEmbedUrl() {
            return `https://www.youtube.com/embed/${this.videoId}`;
        },
    },
};
</script>
  
<style scoped>
/* Stil gereksinimlerini buraya ekleyebilirsiniz */
</style>
  
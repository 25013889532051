<template>
  <NavBar />
  <div class="wrapper">
    <div v-if="!isReadyFeatured" style="height: 75vh;">
      <FeaturedShimmer />
    </div>
    <FeaturedMovie v-if="selectedMovie && isReadyFeatured" :item="selectedMovie" />
    <div style="height: 20px"></div>
    <div>
      <div class="f g5">
        <span class="category-font">{{ texts.new }}</span>
        <img class="category_icon" :src="icons.new" />
      </div>
      <div style="width: 100%; height: 250px; margin-top: 10px" v-if="loading">
        <ShimmerBar />
      </div>
      <div class="movie_bar_wrap" v-if="newMovies.length > 0">
        <MovieBar :items="newMovies" />
      </div>
    </div>

    <div style="height: 10px"></div>

    <div>
      <div class="f g5">
        <span class="category-font">{{ texts.most_liked }}</span>
        <img class="category_icon" :src="icons.like" />
      </div>
      <div class="movie_bar_wrap" v-if="loading">
        <ShimmerBar />
      </div>
      <div class="movie_bar_wrap" v-if="!loading && mostLikedMovies.length > 0">
        <MovieBar :items="mostLikedMovies" />
      </div>
    </div>

    <div style="height: 10px"></div>


    <div>
      <div class="f g5">
        <span class="category-font">{{ texts.most_commented }}</span>
        <img class="category_icon" :src="icons.comment" />
      </div>
      <div style="width: 100%; height: 250px; margin-top: 10px" v-if="loading">
        <ShimmerBar />
      </div>
      <div class="movie_bar_wrap" v-if="!loading && mostCommentedMovies.length > 0">
        <MovieBar :items="mostCommentedMovies" />
      </div>
    </div>

    <div style="height: 10px"></div>

    <div>
      <div class="f g5">
        <span class="category-font">{{ texts.trend }}</span>
        <img class="category_icon" :src="icons.trend" />
      </div>
      <div class="movie_bar_wrap" v-if="loading">
        <ShimmerBar />
      </div>
      <div class="movie_bar_wrap" v-if="!loading && trendMovies.length > 0">
        <MovieBar :items="trendMovies" />
      </div>
    </div>
  </div>
  <FooterBar />
</template>

<script>
import ShimmerBar from '../components/Shimmer/MovieBar.vue';
import FeaturedShimmer from '../components/Shimmer/FeaturedCard.vue'

import MovieBar from '../components/HomeViewComponents/MovieBar.vue';

import FeaturedMovie from '../components/HomeViewComponents/FeaturedMovie.vue'
import MovieService from '../services/MovieService.js'
import commentIcon from '../assets/images/message.png';
import likeIcon from '../assets/images/tickets.png';
import trendIcon from '../assets/images/trending.png';
import newIcon from '../assets/images/trending-topic.png';


export default {
  data() {
    return {
      newMovies: [],
      topRateMovies: [],
      mostLikedMovies: [],
      mostCommentedMovies: [],
      trendMovies: [],
      loading: false,
      topRatedLoading: true,
      newMoviesLoading: true,
      mostLikedCount: 15,
      isMobile: false,
      topRatedCount: 15,
      featuredIndex: 0,
      selectedMovie: null,
      isReadyFeatured: false,
      texts: {
        new: 'En Yeniler',
        top_rated: 'En Çok Oy Alanlar',
        most_liked: 'En Çok Beğenilenler',
        most_commented: 'En Çok Yorumlananlar',
        trend: 'Trendler'
      },
      icons: {
        comment: commentIcon,
        like:likeIcon,
        trend:trendIcon,
        new:newIcon
      }
    }
  },
  methods: {
    async setFeatured() {
      const delay = 15000;

      setInterval(() => {
        const randomIndex = Math.floor(Math.random() * this.topRatedCount)
        this.selectedMovie = this.topRateMovies[randomIndex]
      }, delay)
    },
    async getNewdMovies() {
      this.newMoviesLoading = true
      const response = await MovieService.getMovies({
        limit: this.topRatedCount,
        order_by: 'year',
        order_dir: 'desc'
      })
      if (!response) return this.newMoviesLoading = false;
      this.newMovies = response;
      this.newMoviesLoading = false;
    },
    async getTopRatedMovies() {
      this.topRatedLoading = true
      const response = await MovieService.getMovies({
        limit: this.topRatedCount,
        order_by: 'top_rated',
        order_dir: 'desc'
      })
      if (!response) return this.topRatedLoading = false;
      this.topRateMovies = response;
      this.topRatedLoading = false;
    },
    async getMostLikedMovies() {
      this.loading = true
      const response = await MovieService.getMovies({
        limit: this.mostLikedCount,
        order_by: 'most_liked',
        order_dir: 'desc'
      })
      if (!response) return
      this.mostLikedMovies = response
      this.loading = false
    },
    async getMostCommentedMovies() {
      this.loading = true
      const response = await MovieService.getMovies({
        limit: this.mostLikedCount,
        order_by: 'most_commented',
        order_dir: 'desc'
      })
      if (!response) return
      this.mostCommentedMovies = response
      this.loading = false
    },
    async getTrendMovies() {
      this.loading = true
      const response = await MovieService.getMovies({
        limit: this.mostLikedCount,
        order_by: 'trend',
        order_dir: 'desc'
      })
      if (!response) return
      this.trendMovies = response
      this.loading = false
    },
    async getDatas() {
      await Promise.all([
        this.getNewdMovies(),
        this.getTopRatedMovies(),
        this.getMostLikedMovies(),
        this.getMostCommentedMovies(),
        this.getTrendMovies()
      ])
      this.selectedMovie = this.topRateMovies[0] || null;
      if (this.selectedMovie) this.isReadyFeatured = true;
      this.setFeatured();
    },
    checkMobile() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
    this.checkMobile();
  },
  async created() {
    this.checkMobile();
    this.getDatas();

  },
  components: { MovieBar, FeaturedMovie, ShimmerBar, FeaturedShimmer }
}
</script>

<style>
.category-font {
  font-size: 20px;
  color: white;
  font-weight: 600;
  padding-left: 0px;
}

.category_icon {
  width: 32px;
}

.movie_bar_wrap {
  width: 100%;
  height: 400px;
  margin-top: 10px
}

@media screen and (max-width: 700px) {
  .movie_bar_wrap {
    width: 100%;
    height: 270px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .category-font {
    font-size: 16px;
  }

}
</style>

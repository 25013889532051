<template>
    <button @click="this.$emit('action')" class="custom_btn">
        <p>{{ message }}</p>
    </button>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            greenColor: '#76ff03',
            redColor: '#cb0000',
            greenAccent: 'rgba(76, 175, 80, 0.95)',
            redAccent: 'rgba(230, 62, 62, 0.95)'
        }
    }
};
</script>

<style scoped>
.custom_btn {
    background-color: #f8b632;
    width: 140px;
    border-radius: 5px;
    height: 35px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.4s;
}

.custom_btn:hover {
    background-color: #f7aa0e;
}

.custom_btn p {
    font-weight: 600;
    font-size: 2vh;
}

</style>
<template>
  <div class="thread_card">
    <div class="thread_card_left">
      <img class="thread_avatar" alt="avatar" :src="item.avatar" />
      <span class="user_name">{{ item.name }}</span>

      <img class="rank_icon" alt="icon" src="../../assets/images/rank.png" />
    </div>
    <div @click="$emit('goToLink', item.post_id)" class="thread_card_right">
      <div>
        <h3>{{ item.title }}</h3>
        <p class="user_name">
          {{ item.content }}
        </p>
      </div>
      <div class="social_info">
        <div class="social_left">
          <div class="lower_icon_bundle">
            <img alt="icon" class="lower_icon" src="../../assets/images/tickets.png" />
            <span class="user_name">{{ item.net_votes }}</span>
          </div>
          <div class="lower_icon_bundle">
            <img alt="icon" class="lower_icon" src="../../assets/images/message.png" />
            <span class="user_name">{{ item.comment_count }}</span>
          </div>
        </div>
        <div>
          <div class="lower_icon_bundle">
            <span class="user_name">{{ getDateDifference() }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFormattedDate } from '../../utils/DateUtils.js';
import ImageUtil from '../../utils/ImageUtil';
import rankIcon from '../../assets/images/rank.png';

export default {
  data() {
    return {
      rankIcon: rankIcon,
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  methods: {
    getDateDifference() {
      return getFormattedDate(this.item.created_at);
    },
    setBadge(user) {
      if (!user.user_rank.rank) return this.rankIcon = ImageUtil.discover;
      this.rankIcon = ImageUtil.getBadge(user.user_rank.rank.id);
    }
  },
  async created() { },
  components: {}
}
</script>

<style scoped>
.thread_card {
  background-color: #1A1A1B;
  padding: 1rem 0.5rem;
  border: 1px rgb(58 58 58) solid;
  border-radius: 6px;
  display: flex;
  gap: 2%;
  cursor: pointer;
}

.thread_card:hover {
  background-color: #262627;
}

.thread_card_left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 15%;
  padding: 0rem;
}

.thread_card_right {
  display: flex;
  flex-direction: column;
  gap: 10%;
  width: 90%;
  color: white;
}

.rank_icon {
  width: 32px;
  min-height: 32x;
}

.lower_icon {
  width: 25px;
  min-height: 25px;
}

.lower_icon_bundle {
  display: flex;
  gap: 5px;
  align-items: center;
}

.user_name {
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.thread_avatar {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: white;
}

.social_info {
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
  padding-right: 4%;
}

.social_left {
  display: flex;
  gap: 20%;
}

@media screen and (max-width: 920px) {

  .thread_card {
    background-color: #1A1A1B;
    padding: 1em 0.4em;
    border: 1px rgb(58 58 58) solid;
    border-radius: 6px;
    display: flex;
    gap: 1%;
    cursor: pointer;
  }

  .thread_avatar {
    cursor: pointer;
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
    background-color: white;
  }

  .user_name {
    font-size: 11px;
    color: white;
    cursor: pointer;
  }

  h3 {
    font-size: 14px;
  }


  .thread_card_left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 20%;
    padding: 0rem;
  }

  .thread_card_right {
    display: flex;
    flex-direction: column;
    gap: 10%;
    width: 85%;
  }


  .rank_icon {
    width: 24px;
    min-height: 24px;
  }
}
</style>

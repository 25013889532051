<template>
    <div class="modal-a" :class="{ active: modalOpen }">
        <div class="create_post_div">
            <div class="top">
                <button @click="this.$emit('close')" class="icon_button">
                    <VueIcon icon="ic:baseline-arrow-back-ios" width="24px" style="color: white;" color="white">
                    </VueIcon>
                </button>
                <div>
                    <span class="category-font">
                        Gönderi Oluştur
                    </span>
                </div>
                <div></div>
            </div>
            <div class="post_body">
                <div class="input_div w100">
                    <div class="drop_wrap">
                        <span class="mid-font">Topluluk Seç</span>
                        <IconDropdown :refFunction="filterList" :options="communityOptions"
                            :selectedItem="selectedItem" />
                    </div>
                    <div class="input-container">
                        <input v-model="title" placeholder="Başlık Gir" />
                        <span class="remain">{{ remainingTitleCharacters }}</span>
                    </div>
                </div>
                <div class="text_area">
                    <textarea class="title" v-model="body" rows="15" required cols="2"
                        placeholder="Düşüncelerini paylaş ..." />
                </div>
                <div class="attach">
                    <button :disabled="true" class="icon_button">
                        
                    </button>
                    <div style="display: flex; justify-content: flex-end;">
                        <button @click="submit" class="custom_button">
                            <span v-if="loading" class="loader"></span>
                            <span v-else> {{ share }} </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconDropdown from '../Buttons/IconDropdown.vue';

export default {
    data() {
        return {
            title: '',
            body: '',
            share: 'Paylaş'
        }
    },
    props: {
        modalOpen: {
            type: Boolean,
            required: true
        },
        communityOptions: {
            type: Array,
            required: true
        },
        filterList: {
            type: Function,
            required: true
        },
        selectedItem: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    methods: {
        submit() {
            this.$emit('submit', { title: this.title, body: this.body });
        }
    },
    computed: {
        remainingTitleCharacters() {
            const maxTitleCharacters = 140;
            const remainingCharacters = maxTitleCharacters - this.title.length;
            return `${remainingCharacters} / ${maxTitleCharacters} `;
        }
    },
    async created() { },
    components: { IconDropdown }
}
</script>

<style scoped>
.loader {
    width: 30px;
    height: 30px;
}

.drop_wrap {
    width: 30%;
}

.input_div input {
    width: 100%;
    height: 45px;
    border-radius: 8px;
}

.input_div:hover input {
    border: 2px solid #f8b632;
}

.input-container {
    position: relative;
    width: 70%;
}

.input_div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 5px;
}

.remain {
    position: absolute;
    bottom: 10px;
    right: 2%;
    font-size: 2vh;
    font-weight: bold;
    color: rgb(161, 158, 158);
}

.mid-font {
    color: white;
}

.attach {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.text_area {
    height: 50%;
}

.text_area textarea {
    width: 100%;
    height: 100%;
    background-color: #000000;
    border: 2px solid gray;
    border-radius: 8px;
    padding: 10px;
    color: white;
}

.text_area:hover textarea {
    border-color: #f8b632;
}

.post_body {
    width: 100%;
    padding-left: 4%;
    padding-right: 4%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 6px;
    gap: 2.5%;
}

.category-font {
    color: white;
    font-weight: 900;
}

.modal-a {
    color: white;
    background-color: rgba(0, 0, 0, 1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 6;
}

.modal-a.active {
    display: flex;
}

.create_post_div {
    position: fixed;
    top: 20%;
    left: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 70%;
    background-color: #11101d;
    border: 1px gray solid;
    z-index: 5;
    border-radius: 5px;
    border: 1px solid #f8b632;
}

.top {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 80px;
    padding-top: 2%;
}

.icon_button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    border: none;
    background-color: transparent;
    transition: 1s ease-in-out;
}

.icon_button:hover img {
    transform: scale(1.1);
}

.attach img {
    width: 45px;
}

.attach .icon_button {
    width: 40px;
}

.custom_button {
    width: 140px;
    background: rgb(192, 151, 63);
    background: linear-gradient(38deg, rgba(226, 191, 118, 1) 0%, rgba(255, 223, 156, 1) 35%, rgba(254, 201, 1, 1) 100%);
    color: black;
    text-shadow: 2px 2px 2px white;
    font-weight: 500;
    border-radius: 8px;
}

.custom_button:hover {
    background: rgb(192, 151, 63);
    background: linear-gradient(38deg, rgb(175, 154, 110) 0%, rgb(244, 202, 111) 35%, rgb(228, 181, 10) 100%);
}

@media screen and (max-width: 920px) {
    .create_post_div {
        position: fixed;
        top: 20%;
        left: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        height: 70%;
        background-color: #11101d;
        border: 1px gray solid;
        z-index: 5;
        border-radius: 5px;
        border: 1px solid #f8b632;
    }

    .input_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }

    .input-container {
        width: 100%;
    }

    .drop_wrap {
        width: 100%;
    }


    .mid-font {
        font-size: 12px;
    }


}
</style>
<template>
  <div class="featured-movie" :style="{ background: `${getImageUrl(item.poster)}` }">
    <div class="row">
      <div class="col col1">
        <h1>{{ item.title }}</h1>
        <span class="overview">
          {{ item.description }}
        </span>
        <div class="info_bar">
          <span class="mid-bold">{{ item.year }}</span>
          <div class="f align-center">
            <img class="info-icon" src="../../assets/images/imdb.png" />
            <div style="width: 5px"></div>
            <span class="mid-bold">{{ item.imdbrating }}</span>
          </div>
          <div class="f align-center">
            <img class="info-icon" src="../../assets/images/tickets.png" />
            <div style="width: 5px"></div>
            <span class="mid-bold">{{ item._count.like || 0 }}</span>
          </div>
          <div class="f align-center">
            <img class="info-icon" src="../../assets/images/message.png" />
            <div style="width: 5px"></div>
            <span class="mid-bold">{{ item._count.comment || 0 }}</span>
          </div>
        </div>
        <div v-if="item.director && item.director.length > 0" class="f align-center">
          <VueIcon class="info-icon" icon="iconoir:director-chair" style="color: #F8B631"></VueIcon>
          <div style="width: 5px"></div>
          <div>
            <span v-for="(item, index) in item.director" :key="index" class="mid-bold">{{ item.name }} &nbsp;</span>
          </div>
        </div>
        <div class="f align-center" style="margin-top: 6px;">
          <VueIcon class="info-icon" icon="solar:reel-outline" style="color: #F8B631"></VueIcon>
          <div style="width: 5px"></div>
          <div>
            <span v-for="(item, index) in item.genres" :key="index" class="mid-bold">{{ item.lang_tr }} &nbsp;</span>
          </div>
        </div>
        <div v-if="item.actors && item.actors.length > 0" class="f align-center" style="margin-top: 6px;">
          <VueIcon class="info-icon" icon="material-symbols:star-outline" style="color: #F8B631"></VueIcon>
          <div style="width: 5px"></div>
          <div>
            <span v-for="(item, index) in item.actors" :key="index" class="mid-bold">{{ item.name }} &nbsp;</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtil from '../../utils/ImageUtil.js';
export default {
  data() {
    return {}
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    getImageUrl(imageUrl) {
      const str = ImageUtil.getImage(imageUrl, 'full');
      const bg = `linear-gradient(to right, rgba(0, 0, 0, 0.8) 15%, rgba(0, 0, 0, 0) 100%), url(${str})`
      return bg
    },
    startAnimation() {
      const col1Element = this.$el.querySelector('.col1')
      col1Element.style.animation = 'title-animation 3s ease'

      setTimeout(() => {
        col1Element.style.animation = 'none'
        void col1Element.offsetWidth
        col1Element.style.animation = 'title-animation 3s ease'
      }, 0)
    }
  },
  watch: {
    item() {
      this.startAnimation();
    }
  },
}
</script>

<style>
.info_bar {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8%;
}

h1 {
  color: white;
  font-size: 4em;
  font-weight: 800;
  margin-bottom: 0px;
  text-shadow: 2px 2px 2px black;
}

h1 .mid-bold {
  font-size: 24px;
  text-shadow: 2px 2px 2px black;
}

.overview {
  color: white;
  font-size: 18px;
  text-shadow: 2px 2px 2px black;
}

.line-vertical {
  border: 1px solid white;
  color: white;
  height: 25px;
}

.mid-bold {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  text-shadow: 2px 2px 2px black;
}

.col1 {
  width: 50%;
  padding-left: 2%;
  padding-top: 10%;
  padding-right: 2%;
  text-align: left;
  animation: title-animation 3s ease;
}

.col1 p {
  font-size: 1.5em;
  text-shadow: 2px 2px 2px black;
}

.featured-movie {
  border-radius: 6px;
  position: relative;
  display: flex;
  min-height: 75vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: featured-anim 70s ease-in infinite;
  overflow: hidden;
  padding-bottom: 1%;
}

.featured-movie h1 {
  position: relative;
  z-index: 10;
  text-shadow: 2px 2px 2px black;
}

.featured-movie-info {
  position: relative;
  display: flex;
  background-color: #5c82bc;
  color: white;
  margin: 10px 10px 10px 0px;
  padding: 10px;
  border-radius: 10px;
}

.info-icon {
  height: 35px;
  width: 35px;
}

@keyframes title-animation {
  from {
    transform: translateY(-500px);
  }

  to {
    transform: translateY(-0px);
  }
}

@keyframes movie-info-anim {
  from {
    transform: translateY(20px);
  }

  to {
    transform: translateY(-10px);
  }
}

@media (max-width: 35em) {
  @keyframes anim-in {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  @keyframes anim-out {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(100%);
    }
  }
}

@keyframes featured-anim {

  0%,
  100% {
    background-position: center;
  }

  50% {
    background-position: top;
  }
}

@media screen and (max-width: 700px) {
  .col1 {
    width: 75%;
  }

  .featured-movie {
    border-radius: 0px;
    min-height: 50vh;
    max-height: 60vh;
    animation: featured-anim 70s ease-in infinite;
    padding-bottom: 1%;
    padding-left: 5%;
  }

  h1 {
    font-size: 1.5em;
  }

  .overview {
    font-size: 13px;
  }

  .mid-bold {
    font-size: 13px;
  }

  .info-icon {
    width: 24px;
    height: 24px;
  }
}
</style>

<template>
    <div class="outside3" @click="callParentFunction">
        <div class="clapper-button">
            <div class="clapper-button-top">
                <div class="clapper-button-top-item"></div>
                <div class="clapper-button-top-item"></div>
                <div class="clapper-button-top-item"></div>
            </div>
            <div class="clapper-button-connection">
                <div class="clapper-button-dot"></div>
                <div class="clapper-button-dot"></div>
            </div>
            <div class="clapper-button-bottom-top">
                <div class="clapper-button-top-item2"></div>
                <div class="clapper-button-top-item2"></div>
                <div class="clapper-button-top-item2"></div>
            </div>
            <div class="clapper-button-bottom">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clapperOpen: false
        }
    },
    methods: {
        callParentFunction() {
            var clapperTop = document.querySelector(".clapper-button-top");
            if (!this.clapperOpen) {
                setTimeout(() => {
                    clapperTop.classList.add("clapper-open");
                    clapperTop.classList.remove("clapper-close");
                    this.$emit('fun', true);
                    this.clapperOpen = true;
                }, 100);

            } else {
                setTimeout(() => {
                    clapperTop.classList.add("clapper-close");
                    clapperTop.classList.remove("clapper-open");
                    this.$emit('fun', false);
                    this.clapperOpen = false;
                }, 100);

            }
        }
    }
};
</script>
<style>
.outside3 {
    user-select: none;
    overflow: hidden;
    display: flex;
    height: 100dvh;
    width: 100dvh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    scale: 0.1;
    position: absolute;
    z-index: 999;
    left: 43%;
    bottom: 43%;
}

.clapper-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0;
}

.clapper-button-top {
    display: grid;
    width: max(32%, 320px);
    height: 8%;
    background-color: black;
    rotate: -10deg;
    transform-origin: 0;
    padding: 10px;
    grid-template-columns: 20% repeat(3, 20%) 20%;
    grid-template-rows: 100%;
    gap: 1rem;
}

.clapper-button-top-item {
    background-color: white;
    transform: skew(30deg);
    width: 100%;
    height: 100%;
}

.clapper-button-top>.clapper-button-top-item:nth-child(1) {
    grid-column: 2/3;
}

.clapper-button-top>.clapper-button-top-item:nth-child(2) {
    grid-column: 3/4;
}

.clapper-button-top>.clapper-button-top-item:nth-child(3) {
    grid-column: 4/5;

}

.clapper-button-bottom-top {
    display: grid;
    width: max(32%, 320px);
    height: 8%;
    background-color: black;
    padding: 10px;
    grid-template-columns: 20% repeat(3, 20%) 20%;
    grid-template-rows: 100%;
    gap: 1rem;
}

.clapper-button-connection {
    position: absolute;
    display: grid;
    grid-template-rows: repeat(3, 33%);
    width: 2rem;
    height: 5rem;
    background-color: black;
    top: 23%;
    left: 30%;
    border-radius: 10px;
    border: 1px solid white;
}

.clapper-button-dot {
    background-color: gray;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    margin: 8%;
}

.clapper-button-connection>.clapper-button-dot:nth-child(1) {
    grid-row: 1/2;
}

.clapper-button-connection>.clapper-button-dot:nth-child(2) {
    grid-row: 3/4;
}

.clapper-button-top-item2 {
    background-color: white;
    transform: skew(-30deg);
    width: 100%;
    height: 100%;

}

.clapper-button-bottom-top>.clapper-button-top-item2:nth-child(1) {
    grid-column: 2/3;
}

.clapper-button-bottom-top>.clapper-button-top-item2:nth-child(2) {
    grid-column: 3/4;
}

.clapper-button-bottom-top>.clapper-button-top-item2:nth-child(3) {
    grid-column: 4/5;

}

.clapper-button-bottom {
    width: max(30%, 300px);
    height: 300px;
    background-color: black;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: repeat(10, 10%);
    padding: 10px;
    border-radius: 0% 0% 10% 10%;
}


.clapper-open {
    animation: clapperOpen 1s ease-in-out both;
}

.clapper-close {
    animation: clapperClose 1s ease-in-out both;
}


.clapper-button-top,
.clapper-button-bottom,
.clapper-button-bottom-top {
    box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.5);
}

@keyframes clapperOpen {

    0% {
        rotate: 0deg;

    }

    100% {
        rotate: -10deg;
    }

}

@keyframes clapperClose {

    0% {
        rotate: -10deg;
    }

    100% {
        rotate: 0deg;
    }

}




@media (max-width: 35em) {
    .clapper-button-connection {


        top: 9rem;
        left: 3rem;

    }
}
</style>
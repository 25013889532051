<template>
    <div class="search_community">
        <div class="content">
            <h2>Topluluk Ara</h2>
            <h3>{{ content }}</h3>
            <div @click="this.$emit('go')" @mouseenter="active = true" @mouseleave="active = false" class="wrap">
                <div class="search">
                    <input type="text" class="searchTerm" :placeholder="placeholder" @input="onInput">
                </div>
                <button type="submit" class="searchButton">
                    <img class="small-icon" :src="active ? icons.WHITE : icons.BLACK" />
                </button>
            </div>
        </div>
        <div class="right">
            <Vue3Lottie :animationData="community" :loop="false" :height="250" :width="250" />
        </div>
    </div>
</template>

<script>

import white from '../../assets/images/search.png';
import black from '../../assets/images/search-black.png';
import { Vue3Lottie } from 'vue3-lottie';
import community from '../../assets/animation/community.json'


export default {
    data() {
        return {
            community,
            active: false,
            icons: {
                BLACK: black,
                WHITE: white
            },
            placeholder: 'Toplulukları Keşfet...',
            content: 'Dive into the magic of movies with vibrant film communities. Join discussions, get recommendations, and celebrate cinema together!',
            animationDelay: 10000,
            play: true
        }
    },
    props: {
    },
    methods: {

    },
    async created() {
    },
    components: { Vue3Lottie }
}
</script>

<style scoped>

.hide {
    display: none;
}

.search_community span {
    font-family: 'Montserrat';
    color: white;
    font-weight: 600;
}

h2 {
    font-size: 5vh;
    color: white;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
}

h3 {
    color: black;
}

.search_community {
    display: flex;
    justify-content: space-between;
    gap: 4%;
    background-color: #f8b632;
    min-height: 30vh;
    width: 100%;
    border-radius: 4px;
    padding: 1.2rem 2rem;
    height: auto;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
}

.search_community p {
    color: black;
    font-size: 2.4vh;
    font-weight: 500;
}

.right {
    display: flex;
    align-items: center;
}

.search {
    width: 100%;
    display: flex;
}

.searchTerm {
    width: 100%;
    border: none;
    padding: 10px;
    height: 40px;
    border-radius: 20px;
    outline: none;
    background-color: transparent;
    color: white;
    font-weight: 800;
}

.searchButton {
    width: 35px;
    height: 35px;
    background-color: transparent;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    border-radius: 50%;
    border: 2px solid black;
    display: grid;
    align-items: center;
}

.wrap:hover .searchButton {
    border-color: white;
}

.searchButton:hover {
    transform: scale(1.05);
    transition: transform 0.1s ease-in-out;
}

.wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 75%;
    border: 2px solid black;
    padding: 1px;
    height: 40px;
    border-radius: 25px;
}

.wrap img {
    height: 18px;
    width: 18px;
}

.wrap:hover {
    border-color: white;
    transition: transform 0.1s ease-in-out;
}

.wrap:hover ::placeholder {
    color: white;
}

.wrap:hover ::-ms-input-placeholder {
    color: white;
}

::-ms-input-placeholder {
    /* Edge 12-18 */
    color: black;
}

::placeholder {
    color: black;
}

.searchTerm {
    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-left: none;
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;
    /* Adjust as needed */

}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange;
    }
}

@media (max-width: 590px) {

    .right {
        display: none;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0px;
        width: 100%;
    }

    .search_community {
        display: flex;
        justify-content: space-between;
        gap: 1%;
        background-color: #f8b632;
        min-height: 30vh;
        width: 100%;
        border-radius: 4px;
        padding: 1.2rem 0.8rem;
        height: auto;
    }

    .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 2px solid rgb(90, 89, 89);
        padding: 10px;
        height: 35px;
        border-radius: 20px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 13px;
    }
}
</style>
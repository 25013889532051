<template>
  <NavBar />
  <div class="wrapper">
    <div class="top_margin_div"></div>
    <div class="header">
      <span class="category-font">{{ watchlist.name }}</span>
      <span style="color: #B3B6B8;" class="category-font">Film Sayısı: {{ watchlist.count }}</span>
    </div>
    <div style="width: 100%; height: 250px; margin-top: 10px" v-if="loading">
      <ShimmerBar />
    </div>
    <div class="movie_bar_wrap" v-if="!loading && movies && movies.length > 0">
      <MovieBar :items="movies" />
    </div>
    <div class="container">
      <div v-if="watchlist && watchlist.user" class="user_bar">
        <div class="bottom_left">
          <img v-if="watchlist.user.profile_image_url" class="circle_avatar_bar"
            :src="watchlist.user.profile_image_url" />
          <img v-else class="circle_avatar_bar" :src="avatar" />
          <span> <span class="inner_span">{{ watchlist.user.username }}</span> tarafından oluşturuldu.</span>
        </div>
        <div class="bottom_right">
          <button @click="like(item)" class="button-comment">
            <img class="small-icon" :src="ticket" />
            <span class="lower">{{ watchlistStatistics.like }} Beğeni</span>
          </button>
          <button @click="goRoute(item.watchlist_id)" class="button-like">
            <img class="small-icon" :src="comment" />
            <span class="lower">{{ watchlistStatistics.comments }} Yorum </span>
          </button>
          <div class="right">
            <button class="button-like">
              <VueIcon icon="carbon:view" width="28px" style="color: #f8b632"></VueIcon>
              <span class="lower">{{ watchlistStatistics.view }} </span>
            </button>
          </div>
        </div>
      </div>
      <div class="movie-comments">
        <div class="comment-title">
          <span v-if="comments" class="mid-title">Yorumlar ({{ comments.length || 0 }})</span>
        </div>
        <div>
          <ShareComment @submit="shareComment" :item="user" @go="toLogin" />
        </div>
        <div style="margin-top: 10px;">
          <FilterBar :items="filters" @sort="handleSort" />
        </div>
        <div>
          <div class="none-comment" v-if="loadingComments">
            <DotLoader :loading="loadingComments" />
          </div>
          <div v-else>
            <div v-if="comments && comments.length > 0">
              <CommentBar v-for="(comment) in comments" :item="comment" :user="comment.user" :key="comment.comment_id"
                @likeComment="null" />
            </div>
            <div v-else class="none-comment">Hiç Yorum Yapılmamış</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterBar />
</template>

<script>
import ShimmerBar from '../components/Shimmer/MovieBar.vue';
import MovieBar from '../components/HomeViewComponents/MovieBar.vue';
import CommentBar from '../components/WatchlistDetail/CommentBar.vue'
import ShareComment from '../components/MovieView/ShareComment.vue';
import FilterBar from '../components/PostComponents/FilterBar.vue';
import DotLoader from '../components/Loaders/DotLoader.vue';

import ticket from '../assets/images/tickets.png';
import comment from '../assets/images/message.png';
import view from '../assets/images/view.png';
import avatar from '../assets/images/avatar.png'
import newIcon from '../assets/images/new.png';
import { useUserStore } from '../store/user.js'
import PublicWatchlistService from '../services/PublicWatchlistService';

export default {
  data() {
    return {
      avatar: avatar,
      topRateMovies: [],
      mostLikedMovies: [],
      mostCommentedMovies: [],
      trendMovies: [],
      loading: true,
      topRatedLoading: true,
      mostLikedCount: 10,
      isMobile: false,
      topRatedCount: 10,
      featuredIndex: 0,
      selectedMovie: {},
      ticket,
      comment,
      view,
      filters: [
        { name: 'New', langTR: 'En Yeniler', url: newIcon, type: 'latest' },
      ],
      sortObject: {
        orderDir: 'desc',
        orderBy: 'new',
        limit: 25,
        page: 1
      },
      movies: [],
      loadingComments: true,
      watchlistID: null,
      watchlist: { name: '' },
      watchlistStatistics: {
        name: '',
        user: {},
        comments: 0,
        like: 0,
        count: 0,
        view: 0,
      },
      comments: []
    }
  },
  methods: {
    toLogin() {
      this.$router.push('/login')
    },
    async handleSort(orderBy) {
      if (!orderBy) return;
      this.sortObject.orderBy = orderBy;
      await this.getComments();
    },
    async shareComment(comment) {
      if (!comment || comment === '') return;
      console.log()
      const response = await PublicWatchlistService.commentWatchlist({
        watchlist_id: this.watchlistID,
        comment: comment
      });
      if (!response) return;
      return await this.getComments();
    },
    async getComments() {
      try {
        this.loadingComments = true
        const response = await PublicWatchlistService.getWatchlistComments({
          order_by: this.sortObject.orderBy,
          order_dir: this.sortObject.orderDir,
          watchlist_id: this.watchlistID,
          limit: this.sortObject.limit,
          page: this.sortObject.page
        })

        if (!response) return
        this.comments = response.comments;

        this.watchlistStatistics.like = response._count.like || 0;
        this.watchlistStatistics.comments = response._count.comments || 0;
        this.watchlistStatistics.view = response._count.watchlist_view || 0;
        this.loadingComments = false
      } catch (error) {
        this.watchlistStatistics.like = 0;
        this.watchlistStatistics.comments = 0;
        this.watchlistStatistics.view = 0;
      }

    },
    async getListBySearchQuery() {
      this.loading = true
      const response = await PublicWatchlistService.getWatchlist({
        id: this.watchlistID
      });

      if (!response) return this.loading = false;
      this.movies = response.movies;
      this.watchlistID = response.watchlist_id;
      this.watchlist.name = response.name;
      this.watchlist.user = response.user;
      this.watchlist.count = this.movies.length || 0;
      this.loading = false
    },
    async getDatas() {
      await Promise.all([
        this.getListBySearchQuery(),
        this.getComments()
      ])
    },
    checkMobile() {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 920) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    })
  },
  async created() {
    this.user = useUserStore().user;
    this.watchlistID = this.$route.params.id;
    this.checkMobile();
    this.getDatas();
  },
  components: { MovieBar, ShimmerBar, CommentBar, ShareComment, FilterBar, DotLoader }
}
</script>

<style>

.header {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  padding-left: 2%;
  padding-right: 2%;
}

.watchlist-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 2%
}

.left {
  display: flex;
  flex-direction: row;
  gap: 3%;
  width: 30%;
}

.mid-title {
  font-size: 18px;
}

.user_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.circle_avatar_bar {
  width: 30px;
  border-radius: 50%;
}

.inner_span {
  color: #f8b632;
}

.bottom_left {
  display: flex;
  align-items: center;
  gap: 5px;
}

.bottom_left:hover {
  cursor: pointer;
}

.bottom_right {
  display: flex;
  gap: 20px;
}

.category-font {
  font-size: 2.8vh;
  color: white;
  font-weight: 600;
  padding-left: 0px;
}

.movie_bar_wrap {
  width: 100%;
  height: 390px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.button-comment,
.button-like,
.button-follow {
  color: #f8b632;
  background-color: black;
  font-size: 1rem;
  border: 1px solid #f8b632;
  border-radius: 2px;
  height: 35px;
  min-width: 120px;
  aspect-ratio: auto;
  cursor: pointer;
  display: flex;
  gap: 0.3rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.top_margin_div {
  height: 14vh;
}

.button-comment:hover,
.button-like:hover,
.button-follow:hover {
  transition: 0.2s;
  background-color: #f8b632;
  color: black;
}

.right .button-like:hover {
  background-color: black;
  color: #f8b632;
}

@media screen and (max-width: 700px) {
  .movie_bar_wrap {
    width: 100%;
    height: 270px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .category-font {
    font-size: 16px;
  }

  .top_margin_div {
    height: 2vh;
  }

  .mid-title {
    font-size: 12px;
  }

  .bottom_left {
    font-size: 10px
  }

  .right {
    display: none;
  }

  .bottom_right {
    display: flex;
    gap: 5px;
  }
}
</style>
<template>
    <div class="wrap">
        <div class="search">
            <input type="text" class="searchTerm" :placeholder="placeholder" v-model="searchTerm" @input="onInput">
        </div>
        <button type="submit" class="searchButton">
            <img class="small-icon" src="../../assets/images/search.png" />
        </button>
    </div>
</template>

<script>
export default {
    props: {
        refFunction: Function,
        placeholder: {
            type:String,
            default: 'Ara'
        }
    },
    data() {
        return {
            searchTerm: '',
        };
    },
    methods: {
        onInput() {
            this.$emit('searchMovie', this.searchTerm)
        }
    },
};
</script>


<style scoped>
.search {
    width: 100%;
    display: flex;
}

.searchTerm {
    width: 100%;
    border: none;
    padding: 10px;
    height: 40px;
    border-radius: 10px;
    outline: none;
    background-color: transparent;
    color: #f8b632;
}

.searchButton {
    width: 30px;
    height: 30px;
    background-color: transparent;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
    border: none;
    display: grid;
    align-items: center;
}

.searchButton:hover {
    transform: scale(1.05);
    transition: transform 0.1s ease-in-out;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    border: 2px solid rgb(90, 89, 89);
    padding: 10px;
    height: 40px;
    border-radius: 10px;
}

.wrap img {
    height: 18px;
    width: 18px;
}

.wrap:hover {
    border-color: #f8b632;
    transition: transform 0.1s ease-in-out;
}

@media (max-width: 590px) {
    .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 2px solid rgb(90, 89, 89);
        padding: 10px;
        height: 35px;
        border-radius: 10px;
        color: #f8b632;
    }
}
</style>
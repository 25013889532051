<template>
    <div class="film-element">
        <img class="prevent-user-img poster" :src="getImageUrl(item.poster)" />
        <div class="button_wrap">
            <button @click="deleteMovie" class="action_btn">
                <img class="list_icon" src="../../assets/images/remove.png" />
                <div class="tooltip">
                    <span class="tooltiptext">Kaldır</span>
                </div>
            </button>
            <button @click="goRoute" class="action_btn">
                <img class="list_icon" src="../../assets/images/info.png" />
                <div class="tooltip">
                    <span class="tooltiptext">Film Detayları</span>
                </div>
            </button>
        </div>
        <div class="movie-stats">
            <div class="f align-center">
                <img class="small-icon" src="../../assets/images/imdb.png" />
                <div style="width: 5px"></div>
                <span class="small-font-white">{{ item.imdbrating }}</span>
            </div>
            <div class="f align-center">
                <div style="width: 5px"></div>
                <span class="small-font-white">{{ item.runtime }} min</span>
            </div>
        </div>
        <div class="movie-info">
            <div class="f align-center" style="justify-content: center">
                <span class="small-title-white">{{ item.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showedCount: 0,
        }
    },
    methods: {
        getImageUrl(imageUrl) {
            let str = imageUrl;
            if (imageUrl && imageUrl.includes('@.')) {
                const startIndex = imageUrl.indexOf('@.')
                str = imageUrl.slice(0, startIndex + 1)
                str += '.jpg';
            }
            return str;
        },
        deleteMovie() {
            return this.$emit('deleteMovie', this.item.id);
        },
        goRoute() {
            return this.$emit('goMovie', this.item.id);
        },
    },
    mounted() { },
    created() { },
    components: {}
}
</script>

<style scoped>
.small-icon {
    width: 28px;
}

.film-element {
    min-height: 240px;
    width: 100%;
    background-color: white;
    position: relative;
    border-radius: 3px;
}

.film-element:hover {
    transition: 0.2s ease-in-out;
}

.film-element:not(:hover) {
    transition: 0.2s ease-in-out;
    z-index: 1;
}

.film-element:hover .poster {
    transition: 0.2s ease-in-out;
    border-radius: 0px;
    filter: blur(2px);
}

.movie-info {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
    font-size: 20px;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    border-radius: 0;
    min-height: 50px;
}

.list_icon {
    width: 40px;
    height: 40px;
}

.action_btn {
    display: none;
}

.film-element:hover .button_wrap {
    top: 35%;
    left: 0%;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15%;
    height: 60px;
    width: 100%;
}

.film-element:hover .action_btn {
    display: grid;
    align-items: center;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: none;
}

.movie-stats {
    position: absolute;
    display: none;
    top: 0;
    border-radius: 3px;
    transition: 0.3s ease-in;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    top: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    border-radius: 0px;
}

.poster {
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.small-font-white {
    font-size: 12px;
    color: white;
}

.small-title-white {
    font-size: 13px;
    color: white;
}

.list_icon {
    width: 40px;
    height: 40px;
}

.remove-list {
    display: none;
}

.film-element:hover .remove-list {
    display: grid;
    align-items: space-between;
    position: fixed;
    top: 40%;
    left: 40%;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: none;
}

.film-element:hover .list_icon:hover {
    cursor: pointer;
}

.tooltip {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    animation: 0.5s ease-in-out;
}

.action_btn .tooltiptext {
    visibility: hidden;
    background-color: white;
    box-shadow: 0px 1px 1px 0px rgb(145, 144, 144);
    color: black;
    text-align: center;
    font-size: 1.8vh;
    border-radius: 6px;
    height: 30px;
    padding-top: 3px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    position: absolute;
    z-index: 5;
}

.action_btn:hover .tooltiptext {
    visibility: visible;
    animation: 0.5s ease-in-out;
}
</style>
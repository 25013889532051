<template>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">

    <div class="go-down">
        <VueIcon icon="subway:down-2  go-down" height="50px" style="color: white"></VueIcon>
    </div>
    <div class="parallax">
        <div class="parallax-background"></div>
        <!--- <img class="parallax-car" src="car.png" alt=""> -->
        <div class="parallax-items">
            <h1 class="parallax-text">MOSECOP</h1>
            <p class="parallax-paragraph">
                <span>Yeni filmleri ve dizileri keşfet! </span>
                <span>Topluluğunu oluştur!</span>
                <span>Topluluklara katıl ve yeni insanlarla tanış!</span>
                <span>İzleme listeleri oluştur ve insanlarla paylaş!</span>

            </p>
            <button @click="goRoute" class="parallax-button">Şimdi Başla</button>
        </div>
    </div>
    <main class="main-content">
        <hr>
        <div class="main">
            <div class="main-left">
                <h1 class="main-title">FİLMLER VE DİZİLER</h1>
                <p class="main-text">Yeni filmleri ve dizileri keşfet, yorumlarını paylaş!</p>
            </div>
            <div class="main-right">
                <div class="movies">
                    <img class="movie">
                    <img class="movie">
                    <img class="movie">
                    <img class="movie">
                    <img class="movie">
                </div>
                <div class="movie-comment">
                    <textarea class="comment-text-area" cols="50" rows="3"></textarea>
                    <button class="comment-button">Gönder</button>
                </div>
            </div>
        </div>
        <hr>
        <div class="community">
            <div class="community-left">
                <div class="community-left-left">
                    <img :src="rightPerson" alt="">
                    <div class="hrs">
                        <hr class="arrow1">
                        <hr class="arrow2">
                        <hr class="arrow3">
                    </div>

                </div>
                <div class="community-left-right">
                    <img v-for="(index) in 3" :key="index" :src="leftPerson" alt="">
                </div>

            </div>
            <div class="community-right">
                <h1>TOPLULUK</h1>
                <p>Topluluğunu oluştur!
                    İnsanlarla iletişim kur!
                </p>
            </div>
        </div>
        <hr>
        <div class="watchlist">
            <div class="watchlist-top">
                <h1>İZLEME LİSTESİ</h1>
                <p>İzleme listesi oluştur ve zevklerini paylaş!</p>
            </div>
            <div class="watchlist-bottom">
                <img :src="watchlistIcon" width="400px">
                <img :src="watchlistIcon" width="400px">


                <div class="socials-wrapper">
                    <div class="socials-text">İZLEME LİSTENİ PAYLAŞ</div>
                    <div class="socials">
                        <ul>
                            <li>
                                <a href="">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span class="fa fa-instagram"></span>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span class="fa fa-youtube-play"></span>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span class="fa fa-whatsapp"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <hr>
        <div class="posts">
            <div class="posts-left">
                <div class="posts-left-posts">
                    <textarea class="post-text-area" cols="50" rows="3"></textarea>
                    <button class="post-button">Gönder</button>

                </div>
            </div>
            <div class="posts-right">
                <h1>GÖNDERİ</h1>
                <p>Topluluğunda gönderi paylaş!
                    Diğer insanların gönderilerini oku, yanıtla!
                </p>
            </div>
        </div>
        <hr>
        <div class="bottom-button">
            <h1>Üye olmaya hazır mısın?</h1>
            <button @click="goRoute" class="parallax-button">Şimdi Başla</button>
        </div>
    </main>
</template>

<script>
import MovieService from '../services/MovieService.js';
import leftPerson from '../assets/images/left.png';
import rightPerson from '../assets/images/right.png';
import watchlistIcon from '../assets/images/watchlist.jpg';

export default {
    data() {
        return {
            leftPerson,
            rightPerson,
            watchlistIcon,
            movieLoading: true,
            userDetails: null,
            movies: [],
            loading: false,
            commentInterval: 0,
            postUsers: ["Mali", "Numan", "Yeni Kullanıcı"],
            arrIndex: 0,
            charIndex:0,
            charIndex2: 0,
            interval2: null,
            postText: '',
            postComments: ["Filmi beğendim.", "Sana katılmıyorum Mali. Çok kötü film.", "Naber ben yeni kullanıcı"]
        }
    },
    methods: {
        async getTopRatedMovies() {
            this.movieLoading = true
            const response = await MovieService.getMovies({
                limit: 8,
                order_by: 'top_rated',
                order_dir: 'desc'
            })
            if (!response) return
            this.movies = response;
            this.movieLoading = false
        },
        addPost() {
            var postTextArea = document.querySelector(".post-text-area");
            var postsButton = document.querySelector(".post-button");
            const posts = document.querySelector(".posts-left-posts");

            this.postText = this.postComments[this.arrIndex];
            if (this.charIndex2 < this.postText.length && this.postText[this.charIndex2]) {
                postTextArea.value += this.postText[this.charIndex2];
                this.charIndex2++;

            } else {
                clearInterval(this.interval2);
                setTimeout(() => {
                    postsButton.style.backgroundColor = "black";
                    postsButton.style.color = "#f8b632";
                    setTimeout(() => {
                        postsButton.style.backgroundColor = "";
                        postsButton.style.color = "";
                        postTextArea.value = "";
                    }, 100);

                }, 200);
                setTimeout(() => {
                    posts.innerHTML += `<div class="post">
                <div class="post-left">${this.postUsers[this.arrIndex]}</div>
                <div class="post-right">
                    <div>${this.postComments[this.arrIndex]}</div>
                </div>
                </div>`;
                    postTextArea = document.querySelector(".post-text-area");
                    postsButton = document.querySelector(".post-button");
                    this.arrIndex++;
                    if (this.arrIndex < this.postComments.length) {
                        this.charIndex2 = 0;
                        this.interval2 = setInterval(this.addPost, 100);
                    }
                }, 500);

            }
        },
        addChar() {
            const commentTextArea = document.querySelector(".comment-text-area");
            const commentButton = document.querySelector(".comment-button");
            const commentText = "Yaaani fena olmayan bir film.";
            if (this.charIndex < commentText.length && commentText[this.charIndex]) {
                commentTextArea.value += commentText[this.charIndex];
                this.charIndex++;
            } else {
                clearInterval(this.interval);
                setTimeout(() => {
                    commentButton.style.backgroundColor = "black";
                    commentButton.style.color = "#f8b632";
                    setTimeout(() => {
                        commentButton.style.backgroundColor = "";
                        commentButton.style.color = "";
                    }, 200);
                }, 200);
            }
        },
        goRoute() {
            clearInterval(this.interval);
            clearInterval(this.interval2);
            this.$router.push('login');
        }
    },
    mounted() {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    document.querySelector('.watchlist-bottom img:nth-child(1)').classList.add('watchlist-bottom-anim1');
                    document.querySelector('.watchlist-bottom img:nth-child(2)').classList.add('watchlist-bottom-anim2');
                    document.querySelector('.socials-wrapper').classList.add('watchlist-bottom-anim3');
                    observer.unobserve(entry.target);
                }
            });
        });
        observer.observe(document.querySelector('.watchlist-bottom'));

        const observer2 = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.interval = setInterval(this.addChar, 100);
                    observer2.unobserve(entry.target);
                }
            });
        });
        observer2.observe(document.querySelector('.movie-comment'));

        const observer3 = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.interval2 = setInterval(this.addPost, 100);
                    observer3.unobserve(entry.target);
                }
            });
        });

        observer3.observe(document.querySelector('.posts'));

        const observer4 = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    document.querySelector(' .community-left-left>img').classList.add('image-anim4');
                    document.querySelector(' .community-left-right>img:nth-child(1)').classList.add('image-anim1');
                    document.querySelector(' .community-left-right>img:nth-child(2)').classList.add('image-anim2');
                    document.querySelector(' .community-left-right>img:nth-child(3)').classList.add('image-anim3');
                    document.querySelector(' .arrow1').classList.add('arrow-anim1');
                    document.querySelector(' .arrow2').classList.add('arrow-anim2');
                    document.querySelector(' .arrow3').classList.add('arrow-anim3');
                    observer4.unobserve(entry.target);
                }
            });
        });
        observer4.observe(document.querySelector('.community'));

    },
    async created() {
        await this.getTopRatedMovies();
        const moviesLanding = document.querySelectorAll(".movie ");

        if (this.movies && this.movies.length > 0) {
            moviesLanding.forEach((img, index) => {
                if (this.movies[index]) {
                    const posterLink = this.movies[index].poster;
                    img.src = posterLink;
                }
            });
        }
    },
    components: {}
}
</script>

<style scoped>
.parallax {
    position: relative;
    z-index: 0;
    display: grid;
    grid-template-areas: "stack";
    height: 100dvh;
    overflow: hidden;
}

.parallax>* {
    grid-area: stack;
    animation: parallax linear;
    animation-timeline: scroll();
}

.parallax-background {

    filter: brightness(50%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 140%;
    --parallax-speed: -4;
    transform: skew(-30deg);
    position: relative;
    right: 15%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0.0.0.1)100%), url('../assets/images/background.png') no-repeat center center fixed;
}

.parallax-car {
    position: relative;
    left: 30%;
    top: 15%;
    scale: 2.5;
    --parallax-speed: -2;
}

.parallax-text {
    color: white;
    font-size: 100px;
    z-index: 10;
    width: 50%;
    margin-bottom: 0;
}

.parallax-button {
    padding: 1%;
    background-color: #f8b632;
    color: black;
    border-radius: 2px;
    margin-top: 2%;
    border: none;
    cursor: pointer;
    font-weight: 600;
    min-width: 200px;
    font-size: 20px;
}

.parallax-button:hover {
    filter: brightness(90%);

}

.parallax-button:active {
    filter: brightness(90%);
    color: #a27722;
}


.parallax-items {
    position: relative;
    --parallax-speed: -1;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.parallax-paragraph {
    width: 70%;
    text-align: center;
}

.parallax-paragraph span {
    display: block;
}

@keyframes parallax {
    to {
        transform: translateY(calc(var(--parallax-speed)*200px));
    }
}

.main-content {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.main {
    display: flex;
    padding: 2%;
    font-size: 2rem;
    justify-content: center;
    align-items: center;

}

.main-left {
    width: 30%;
    padding-inline: 5%;
}

.main-right {
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

}

.movies {
    display: flex;
}

.movie {
    border-radius: 25px;
    width: 20%;
    height: 30%;
}

.movie:hover {
    z-index: 3;
}

.camera-film {
    rotate: 90deg;
    height: 50%;
    width: 50%;
    z-index: 0;
}

.community,
.posts {
    display: flex;
    justify-content: center;
    align-items: center;

}


.posts-left {
    width: 30%;
    overflow: hidden;
    display: flex;
    margin-left: 5%;
    height: 400px;
}

.community-left {
    display: flex;
    overflow: hidden;
    width: 40%;
    height: auto;
}

.community-left-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.community-left-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

}

.community-left-right>img {
    position: relative;

}

.hrs {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.hrs>hr {
    position: relative;
    width: 230px;
    border: 1px dotted #f8b632;
    opacity: 0;
}

.arrow1 {
    transform: rotateZ(-20deg);
    bottom: 50px;

}

.arrow2 {
    left: 10px;


}

.arrow3 {
    transform: rotateZ(20deg);
    top: 50px;


}

.arrow-anim1 {
    animation: arrow-anim 0.5s 1s linear forwards;
}

.arrow-anim2 {
    animation: arrow-anim 0.5s 1.5s linear forwards;
}

.arrow-anim3 {
    animation: arrow-anim 0.5s 2s linear forwards;
}

@keyframes arrow-anim {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.community-left-left>img,
.community-left-right>img {
    margin-left: 10%;
    width: 50%;

}

.community-left-left>img {
    width: 100%;

    position: relative;

}

.image-anim1 {
    animation: img-anim1 0.5s 0.5s linear forwards;
}

.image-anim2 {
    animation: img-anim1 0.5s 1s linear forwards;
}

.image-anim3 {
    animation: img-anim1 0.5s 1.5s linear forwards;
}

.image-anim4 {
    animation: img-anim2 0.5s linear forwards;
}

.community-left-right>img:nth-child(1) {
    transform: translateX(300%);

}

.community-left-right>img:nth-child(2) {
    transform: translateX(300%);

}

.community-left-right>img:nth-child(3) {
    transform: translateX(300%);

}

.community-left-right>img:nth-child(even) {
    position: relative;
    left: 20px;
}

.community-left-right>img:nth-child(odd) {
    position: relative;
    left: -20px;
}

@keyframes img-anim1 {
    from {
        transform: translateX(300%);

    }

    to {
        transform: translateX(0);

    }
}

@keyframes img-anim2 {

    from {
        transform: translateX(-200%);

    }

    to {
        transform: translateX(0);

    }
}

h1 {
    animation: title-anim 1s ease;
}

@keyframes title-anim {
    from {
        transform: translateX(-300%);
    }

    to {
        transform: translateX(0);
    }
}

p {

    animation: paragraph-anim 1s ease 0.5s both;
    text-shadow: 0px 5px 10px #fbdda2;
    font-size: 1.5rem;
}

@keyframes paragraph-anim {
    from {
        transform: translateX(-300%);
    }

    to {
        transform: translateX(0);
    }
}

.community-right,
.posts-right {
    padding: 2%;
    width: auto;
    font-size: 2rem;
}

.watchlist {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.watchlist-top {
    font-size: 2rem;
}

.watchlist-bottom {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
}

.watchlist-bottom>img,
.socials-wrapper {
    transform: translateY(150%);
}


.watchlist-bottom-anim1 {
    animation: watchlist-anim 1s ease 1s forwards;
}

.watchlist-bottom-anim2 {
    animation: watchlist-anim 1s ease 2s forwards;
}

.watchlist-bottom-anim3 {
    animation: watchlist-anim 1s ease 3s forwards;
}

@keyframes watchlist-anim {
    from {
        transform: translateY(150%);
    }

    to {
        transform: translateY(0);
    }
}




hr {
    border-color: rgba(0, 0, 0, 0.9);
    width: 90%;
    margin-block: 2%;
}

img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.go-down {
    position: fixed;
    right: 20px;
    bottom: 10px;
    font-size: 3rem;
    z-index: 999;
}

.movie-comment,
.posts-left-posts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    user-select: none;
    pointer-events: none;
    margin-top: 20px;
    gap: 1rem;
    padding: 2%;

}

.comment-text-area {}

.comment-button,
.post-button {
    width: 30%;
    background-color: #f8b632;
    color: black;
    padding: 2%;
    border-radius: 3px;
}

.posts-left .post {
    color: #f8b632;
}

.post {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid #f8b632;
    height: 15%;
    border-left: 2px solid #f8b632;
}

.post-left {
    width: auto;
    text-align: center;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 2%;
}

.post-right {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #f8b632;
    padding: 2%;
}

.bottom-button {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 130px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
}

hr {
    border-color: #f8b632;
}

.socials-wrapper {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    margin: 0;
}

.socials {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
    width: 100%;
}

.socials-text {
    position: relative;
    min-height: 10dvh;

    top: 50px;
}

.socials ul {
    position: relative;
    display: flex;
    justify-content: center;
    transform-style: preserve-3d;
    transform: rotateX(-25deg) skew(25deg);
}

.socials ul li {
    position: relative;
    list-style: none;
    width: 60px;
    aspect-ratio: 1;
    margin: 0 30px;
}

.socials ul li:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: #2a2a2a;
    transform-origin: top;
    transform: skewX(-41deg);
}

.socials ul li:after {
    content: '';
    position: absolute;
    top: 0;
    left: -9px;
    width: 9px;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform-origin: right;
    transform: skewY(-49deg);
}

.socials ul li span {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: #333;
    color: rgba(255, 255, 255, 0.2);
    font-size: 30px !important;
}

.socials ul li:hover span {
    z-index: 1000;
    transition: 0.2s;
    color: #fff;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.05);
}

.socials ul li:hover span:nth-child(5) {
    transform: translate(40px, -40px);
    opacity: 1;
}

.socials ul li:hover span:nth-child(4) {
    transform: translate(30px, -30px);
    opacity: 0.8;
}

.socials ul li:hover span:nth-child(3) {
    transform: translate(20px, -20px);
    opacity: 0.6;
}

.socials ul li:hover span:nth-child(2) {
    transform: translate(10px, -10px);
    opacity: 0.4;
}

.socials ul li:hover span:nth-child(1) {
    transform: translate(0px, 0px);
    opacity: 0.2;
}

.socials ul li:nth-child(1):hover span {
    background: #E1306C;
}

.socials ul li:nth-child(2):hover span {
    background: #FF0000;

}

.socials ul li:nth-child(3):hover span {
    background: #25D366;
}

h1 {
    font-size: 1.5em;
}

@media only screen and (max-width: 600px) {

    .main,
    .posts,
    .community {
        flex-direction: column;
    }

    .parallax {
        height: 333px !important;
    }

    .parallax-background {
        width: 100%;
        transform: skew(0deg);
        left: 0;
        right: 0;
    }

    .parallax-items {
        height: auto;
        position: relative;
        top: 45%;
    }

    .parallax-text {
        font-size: 25px;
    }

    .parallax-paragraph {
        font-size: 15px;
    }

    .parallax-button {
        font-size: 12px;
        height: 35px;
    }

    h1 {
        font-size: 21px;
    }

    p {
        font-size: 20px;
    }

    .main {
        flex-direction: column;
    }

    .main-left {
        display: flex;
        flex-direction: row;
        width: 90%;

    }

    .movie {
        border-radius: 10px;
        width: 70px;
    }

    .movie-comment {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .comment-text-area {
        width: 70%;
    }

    .community {
        flex-direction: column;
    }

    .community-left {
        width: 100%;
    }

    .community-left-left {
        width: 50%;
    }

    .community-left-left>img {
        width: 100px;
    }

    .community-left-right {
        width: 50%;
    }

    .hrs>hr {

        width: 110px;

    }

    .arrow2 {
        width: 110% !important;
    }

    .watchlist-bottom>img {
        width: 30%;
    }

    .posts {
        width: auto;

    }

    .posts-left {
        width: 90%;
        margin: 0;
        height: auto;
    }

    .posts-left-posts {
        width: auto;
        margin-left: none;
    }

    .posts-right {
        padding: 10%;
    }

    .post-text-area {
        width: 100%;
    }

    .top_margin_div {
        height: 5vh;
    }
    .socials-wrapper{
        width: 100px;
    }
    .socials{
        widows: 30px;
    }
}
</style>
<template>
  <div class="filter_bar">
    <div @click="sortBy(item.type)" v-for="(item, index) in filters" :key="index" class="filter_item">
      <img class="filter_icon" :src="item.url" />
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>
  
<script>
import newIcon from '../../assets/images/new.png';
import topIcon from '../../assets/images/top-white.png';

export default {
  data() {
    return {
      filters: [
        { name: 'Yeni', url: newIcon, type: 'date' },
        { name: 'En İyi', url: topIcon, type: 'top_rated' },
      ]
    }
  },
  methods: {
    sortBy(type) {
      this.$emit('sort', type);
    },
  },
  async created() { },
  components: {}
}
</script>
  
<style scoped>
.filter_bar {
  display: flex;
  align-items: center;
  gap: 4%;
  width: 100%;
  height: 3rem;
  padding: 1rem;
  background-color: #1A1A1B;
  border: 1px rgb(58 58 58) solid;
  border-radius: 6px;
  color: white;
  z-index: 10;
  box-shadow: none;
}

.filter_icon {
  width: 24px;
  height: 24px;
}

.filter_item {
  display: flex;
  align-items:flex-start;
  gap: 4px;
  padding: 0.2rem;
  border-radius: 4px;
  cursor: pointer;
}

.filter_item:hover {
  display: flex;
  align-items:flex-start;
  gap: 4px;
  background-color: rgb(83, 82, 82);
}
</style>
  
<template>
    <div class="main_card">
        <div class="main_card_left">
            <img class="thread_avatar" :src="item.avatar" />
            <span class="user_name">{{ item.name }}</span>
        </div>
        <div @click="$emit('goToLink', item.post_id, item.community_id)" class="main_card_right">
            <div>
                <h4>{{ item.title }}</h4>
                <p class="user_name">
                    {{ item.content }}
                </p>
            </div>
            <div class="social_info">
                <div class="social_left">
                    <div class="lower_icon_bundle">
                        <img class="lower_icon" :src="icons.TICKET" />
                        <span class="user_name"> {{ item.net_votes }}</span>
                    </div>
                    <div class="lower_icon_bundle">
                        <img class="lower_icon" :src="icons.COMMENT" />
                        <span class="user_name">{{ item.comment_count }}</span>
                    </div>
                </div>
                <div class="social_right">
                    <div style="display: flex; align-items:flex-end; gap: 5px;">
                        <span class="user_name">Topluluk: </span>
                        <span class="inner">{{ item.community_name }}</span>
                    </div>
                    <div class="f align-bottom">
                        <span class="user_name">{{ getDateDifference() }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getFormattedDate } from '../../utils/DateUtils.js';
import ticket from '../../assets/images/tickets.png';
import comment from '../../assets/images/message.png'


export default {
    data() {
        return {
            socials: [
                { id: 0, name: 'Recent Topics' },
                { id: 1, name: 'Top Topics' },
                { id: 2, name: 'Trending' },
            ],
            icons: {
                TICKET: ticket,
                COMMENT: comment
            }
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        getDateDifference() {
            return getFormattedDate(this.item.created_at);
        }
    },
    async created() { },
    components: {}
}
</script>

<style scoped>
h4 {
    font-weight: 900;
}

.inner {
    font-size: 12px;
    color: white;
}

.main_card {
    background-color: #1A1A1B;
    padding: 0.5rem 0.5rem;
    border-radius: 4px;
    display: flex;
    gap: 2%;
    cursor: pointer;
}

.main_card:hover {
    background-color: #262627;
}

.main_card_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 10%;
}

.main_card_right {
    display: flex;
    flex-direction: column;
    gap: 10%;
    width: 90%;
    color: white;
}

.lower_icon {
    width: 25px;
    min-height: 25px;
}

.lower_icon_bundle {
    display: flex;
    gap: 5px;
    align-items: center;
}

.user_name {
    font-size: 12px;
    color: white;
    cursor: pointer;
}

.thread_avatar {
    cursor: pointer;
    width: 2rem;
    border-radius: 50%;
    background-color: white;
}

.social_info {
    margin-top: 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4%;
}

.social_left {
    display: flex;
    align-items: flex-end;
    gap: 15%;
    width: 50%;
}

.social_right {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 50%;
}

@media (max-width: 590px) {

    .social_info {
        margin-top: 2%;
        padding-right: 0%;
    }

    .main_card {
        background-color: #1A1A1B;
        padding: 0.5rem 0.4rem;
        border-radius: 4px;
        display: flex;
        gap: 2%;
        cursor: pointer;
    }

    .main_card_left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 15%;
    }
}
</style>
<template>
    <NavBar />
    <div class="top_margin_div"></div>
    <div style="padding-left: 2%; padding-right: 2%;" v-if="!user">
        <LoginTemplate :sub-message="'Login'" :action="goLogin" :message="loginMessage" />
    </div>
    <div v-else class="profile-wrapper">
        <div class="left_side">
            <UserCard :item="user" />
            <UserInfo @edit-bio="editBio" :item="userDetails" />
        </div>
        <div class="right-movie-section">
            <div class="right-tab-bar">
                <div class="personal-activities-tab">
                    <div class="tab_header">
                        <h3>Kişisel Aktiviteler</h3>
                    </div>
                    <div class="movie-tab-bar">
                        <div class="movie-liked" @click="setActiveTabPersonal(1)"
                            :class="{ 'active': activeTabPersonal === 1 }">Beğendiklerim
                            <div class="count"><span>{{ activityCounts.like }}</span></div>
                        </div>
                        <div class="movie-watched" @click="setActiveTabPersonal(2)"
                            :class="{ 'active': activeTabPersonal === 2 }">İzlediklerim
                            <div class="count"><span>{{ activityCounts.watched }}</span></div>
                        </div>
                        <div class="movie-watchlist" @click="setActiveTabPersonal(3)"
                            :class="{ 'active': activeTabPersonal === 3 }">
                            İzleme Listem
                            <div class="count"><span>{{ activityCounts.watchlist }}</span></div>
                        </div>
                        <div class="movie-comments" @click="setActiveTabPersonal(4)"
                            :class="{ 'active': activeTabPersonal === 4 }">
                            Yorumlarım
                            <div class="count"><span>{{ activityCounts.comments }}</span></div>
                        </div>
                    </div>
                </div>
                <div v-if="activeTabPersonal === 1" class="tab-content">
                    <!-- Content for Liked tab -->
                    <div @click="goMovie(item.movie_id)" v-for="(item, index) in likedMovies" :key="index"
                        class="movie">
                        <MovieCard :item="item" />
                    </div>
                </div>
                <div v-if="activeTabPersonal === 2" class="tab-content">
                    <!-- Content for Watched tab -->
                    <div @click="goMovie(item.movie_id)" v-for="(item, index) in watchedMovies" :key="index"
                        class="movie">
                        <MovieCard :item="item" />
                    </div>
                </div>
                <div v-else-if="activeTabPersonal === 3" class="tab-content">
                    <!-- Content for Liked tab -->
                    <div @click="goMovie(item.movie_id)" v-for="(item, index) in watchlist" :key="index" class="movie">
                        <MovieCard :item="item" />
                    </div>
                    <ExtraInfoCard v-if="watchlist && watchlist.length > 0" @go-route="goRoute" class="movie">
                    </ExtraInfoCard>

                </div>
                <div v-else-if="activeTabPersonal === 4" class="tab-content mobile_tab_flex_wrap">
                    <!-- Content for Liked tab -->
                    <UserComments @go="goMovie" :items="movieComments" />
                </div>
            </div>
            <div style="height: 30px;"></div>
            <div class="right-tab-bar">
                <div class="community-activities-tab">
                    <div class="tab_header">
                        <h3>Topluluk Aktiviteleri</h3>
                    </div>
                    <div class="community-tab-bar">
                        <div class="community-communities" @click="setActiveTabCommunity(1)"
                            :class="{ 'active': activeTabCommunity === 1 }">
                            Toplulularım
                            <div class="count"><span>{{ communityCounts.total }}</span></div>
                        </div>
                        <div class="community-posts" @click="setActiveTabCommunity(2)"
                            :class="{ 'active': activeTabCommunity === 2 }">
                            Gönderilerim
                            <div class="count"><span>{{ communityCounts.posts }}</span></div>
                        </div>
                        <div class="community-comments" @click="setActiveTabCommunity(3)"
                            :class="{ 'active': activeTabCommunity === 3 }">
                            Yorumlarım
                            <div class="count"><span>{{ communityCounts.comments }}</span></div>
                        </div>
                    </div>
                </div>
                <div v-if="activeTabCommunity === 1" class="community-tab-content">
                    <div v-for="(item, index) in communities" :key="index" class="community_card_wrap">
                        <CommunityCard @go="goCommunity" :item="item" />
                    </div>
                </div>
                <div v-else-if="activeTabCommunity === 2" class="community-tab-content">
                    <div @click="goComment(item.community_id, item.post_id)"
                        v-for="(item, index) in communityInfo.posts" :key="index" class="comment">
                        <CommunityComments :item="item" />
                    </div>
                </div>
                <div v-else-if="activeTabCommunity === 3" class="community-tab-content">
                    <div @click="goComment(item.community_id, item.post_id)"
                        v-for="(item, index) in communityInfo.comments" :key="index" class="comment">
                        <CommunityComments :item="item" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterBar />
</template>

<script>
import PostService from '../services/PostService';
import PostCommentService from '../services/PostCommentService';
import ListService from '../services/WatchlistService.js';
import MovieService from '../services/MovieService.js';
import CommentService from '../services/CommentService.js';
import UserComments from '../components/User/UserComments.vue';
import CommunityService from '../services/CommunityService';
import UserInfo from '../components/User/UserInfo.vue';
import MovieCard from '../components/User/MovieCard.vue';
import ExtraInfoCard from '../components/User/ExtraInfoCard.vue';

import UserCard from '../components/User/UserCard.vue';
import CommunityCard from '../components/CommunitySearch/CommunityCard.vue';
import CommunityComments from '../components/User/CommuniyComments.vue';

import anime from '../assets/images/anime.jpg';
import avatar from '../assets/images/avatar.png';

import { useUserStore } from '../store/user.js'
import LoginTemplate from '../components/shared/LoginTemplate.vue';
import UserService from '../services/UserService';
import ImageUtil from '../utils/ImageUtil';

export default {
    data() {
        return {
            loginMessage: 'Please login to view your user details.',
            communityBackgroundImage: anime,
            avatarImage: avatar,
            postID: null,
            communityID: null,
            limit: 10,
            post: {},
            postLoading: true,
            commentLoading: false,
            comments: [],
            communityInfoLoading: false,
            newComment: '',
            commentOrderBy: 'date',
            commentOrderDir: 'desc',
            commentCount: 0,
            communityInfo: {
                comments: [],
                posts: []
            },
            sortObject: {
                limit: 12,
                order_by: '',
                order_dir: 'desc',
            },
            communityFilters: {
                orderBy: 'top',
                orderDir: 'desc',
                limit: 10
            },
            communities: [],
            activeTabPersonal: 1,
            activeTabCommunity: 1,
            user: {
                username: 'numan',
                point: 50,
                joined_date: '2022',
                rank: '',
            },
            watchlistLoading: true,
            watchlist: [],
            likedLoading: true,
            likedMovies: [],
            watchedLoading: true,
            watchedMovies: [],
            movieComments: [],
            activityCounts: {
                like: 0,
                watchlist: 0,
                watched: 0,
                comments: 0
            },
            communityCounts: {
                total: 0,
                posts: 0,
                watched: 0,
                comments: 0
            },
            userDetails: {
                followers: 12,
                following: 10,
                bio: '',
                total_comment: 120,
                total_like: 100,
                joined_at: '2022',
            },
        }
    },
    methods: {
        getImageUrl(imageUrl) {
            if (!imageUrl) return;
            const startIndex = imageUrl.indexOf('@.')
            let str = imageUrl.slice(0, startIndex + 1)
            str += '.jpg'
            return str
        },
        async setActiveTabPersonal(tab) {
            this.activeTabPersonal = tab;

            if (tab === 1) return await this.getLikedMovies();
            if (tab === 2) return await this.getWatchedMovies();
            if (tab === 3) return await this.getWatchlist();
            if (tab === 4) return await this.getMovieComments();
        },
        async setActiveTabCommunity(tab) {
            this.activeTabCommunity = tab;

            if (tab === 1) return await this.getSubsCommunities();
            if (tab === 2) return await this.getCommunityPost();
            if (tab === 3) return await this.getCommunityComments();
        },
        async getCommunityPost() {
            this.postLoading = true;
            const response = await PostService.getUserPosts({
                limit: this.limit,
                order_by: this.commentOrderBy,
                order_dir: this.commentOrderDir,
                user_id: this.user.user_id
            })
            if (!response) { return this.postLoading = false; }
            this.communityInfo.posts = response.posts;
            this.communityCounts.posts = response.total;
            this.postLoading = false;
        },
        async getCommunityComments() {
            this.commentLoading = true;
            const response = await PostCommentService.getCommentsByUserId({
                limit: this.limit,
                order_by: this.commentOrderBy,
                order_dir: this.commentOrderDir,
                user_id: this.user.user_id
            })
            if (!response) return this.commentLoading = false;;
            this.communityInfo.comments = response.comments;
            this.communityCounts.comments = response.total;
            this.commentLoading = false;
        },
        async getMovieComments() {
            this.commentLoading = true;
            const response = await CommentService.commentByUserId({
                limit: this.limit,
                order_by: this.commentOrderBy,
                order_dir: this.commentOrderDir,
                user_id: this.user.user_id
            })
            if (!response) return this.commentLoading = false;
            this.movieComments = response.comments;
            this.activityCounts.comments = response.total;
            this.commentLoading = false;
        },
        async getWatchlist() {
            this.watchlistLoading = true
            const response = await ListService.getMovies({
                limit: this.limit,
                order_by: this.orderBy,
                order_dir: this.order_dir,
                search: '',
                user_id: this.user.user_id
            })
            if (!response) return;
            this.watchlist = response.movies;
            this.activityCounts.watchlist = response.total ?? 0;
            this.watchlistLoading = false
        },
        async editBio(bio) {
            const response = await UserService.setBiography({
                text: bio
            })
            if (!response) return;
            this.getUserDetails();
        },
        async getWatchedMovies() {
            this.watchedLoading = true
            const response = await MovieService.getWatchedMovies({
                user_id: this.user.user_id,
                limit: this.limit,
                order_dir: 'desc',
            })
            if (!response) return
            this.watchedMovies = response.movies;
            this.activityCounts.watched = response.total;
            this.watchedLoading = false
        },
        async getLikedMovies() {
            this.likedLoading = true
            const response = await MovieService.getLikedMovies({
                limit: this.limit,
                order_by: this.orderBy,
                order_dir: this.order_dir,
                user_id: this.user.user_id
            })
            if (!response) return
            this.likedMovies = response.movies;
            this.activityCounts.like = response.total;
            this.likedLoading = false
        },
        async getSubsCommunities() {
            this.communityLoading = true;
            const response = await CommunityService.getUserAllCommunity({
                limit: this.communityFilters.limit,
                order_by: this.communityFilters.orderBy,
                order_dir: this.communityFilters.orderDir,
                user_id: this.user.user_id
            });

            if (!response) return
            this.communities = response.communities;
            this.communityCounts.total = response.total;
            this.communityLoading = false;
        },
        goMovie(movieId) {
            const id = movieId;
            this.$router.push({ name: 'movie', params: { id } })
        },
        goCommunity(id) {
            return this.$router.push({ name: 'community-detail', params: { id: id } })
        },
        goComment(communityID, postID) {
            return this.$router.push({ name: 'post', params: { communityId: communityID, postId: postID } })
        },
        async handleSort(sortBy) {
            if (!sortBy) return;
            this.commentOrderBy = sortBy;
            await this.getCommunityComments();
        },
        goLogin() {
            return this.$router.push({ name: 'login' });
        },
        goRoute() {
            return this.$router.push({ name: 'list' });
        },
        async getUserDetails() {
            const response = await UserService.getUserDeepDetails();
            if (!response) return;
            this.getBadge();
            this.userDetails.bio = response.bio;
        },
        getBadge() {
            if (!this.user.rank) return ImageUtil.discover;
            this.user.rank.badge = ImageUtil.getBadge(this.user.rank.id);
        },
    },
    async created() {
        this.user = useUserStore().user;
        await this.getUserDetails();
        if (!this.user) return;
        await this.getLikedMovies();
        await this.getSubsCommunities();
        this.commentCount = this.comments.length;
    },
    components: { ExtraInfoCard, UserCard, MovieCard, UserInfo, UserComments, CommunityCard, CommunityComments, LoginTemplate }
}
</script>

<style scoped>
.count {
    background-color: #f8b632;
    color: color;
    min-width: 24px;
    height: 24px;
    border-radius: 15px;
    padding: 3px 6.85px;
    font-size: 13px;
    font-weight: 400;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.count span {
    color: black;
    font-weight: 600;
}

h3 {
    color: white;
    font-weight: bold;
}

.top_margin_div {
    height: 15vh;
}

.left_side {
    width: 25%;
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.personal-acitivities {
    justify-content: start;
    text-align: center;
    align-items: center;
}

.personal-activities-tab,
.community-activities-tab {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}

.community-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.community-name-top {
    margin: 1%;
    text-transform: uppercase;
}

.tab_header {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
}

.community {
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    color: white;
    text-shadow: 1px 1px 1px black;
}

.community:hover {
    animation: community-background-anim 10s infinite ease-in-out;
}

@keyframes community-background-anim {

    0%,
    100% {
        background-position: center;
    }

    33% {
        background-position: top;
    }

    66% {
        background-position: bottom;
    }
}

.community-name {
    text-transform: uppercase;
    font-size: 2rem;
}

.go-community {
    margin-top: 4%;
    text-transform: uppercase;
    padding: 1%;
}

.profile-wrapper {
    display: flex;
    height: auto;
    width: 100%;
    gap: 2%;
    padding-left: 3%;
    padding-right: 3%;
    flex-direction: row;
    background-color: black;
    color: white;
    align-items: flex-start;
    min-height: 100dvh;
}


.right-movie-section {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 100%;
    height: 100%;

}

.tab-content {
    display: flex;
    height: 450px;
    background-color: rgba(255, 255, 255, 0.133);
    width: 100%;
    flex-wrap: wrap;
    overflow-y: scroll;
    justify-content: flex-start;
    z-index: 2;
    border-radius: 5px;
    padding: 0.5rem;
    overflow: scroll;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
}


.tab-content::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}


.community-tab-content {
    width: 100%;
    display: flex;
    height: 550px;
    background-color: rgba(255, 255, 255, 0.133);
    width: 100%;
    flex-wrap: wrap;
    overflow-y: scroll;
    justify-content: flex-start;
    z-index: 2;
    border-radius: 5px;
    padding: 0.5rem;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
}

.community-tab-content::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.movie {
    margin: 10px;
    min-height: 140px;
    min-width: 80px;
    height: 50%;
    background-color: black;
    width: 18%;
    border-radius: 8px;
    cursor: pointer;
}

.community_card_wrap {
    margin: 10px;
    min-height: 140px;
    min-width: 80px;
    height: 50%;
    width: 22%;
    border-radius: 8px;
    cursor: pointer;
}

.movie-tab-bar,
.community-tab-bar,
.right-tab-bar {
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 0.2rem;
    height: 100%;
    width: 100%;
}

.movie-tab-bar {
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    height: 50px;
    bottom: 0px;
    gap: 2%;
    width: 70%;
}

.community-tab-bar {
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    height: 50px;
    top: 16px;
    width: 70%;
    gap: 3%;
}

.right-tab-bar {
    justify-content: center;
    align-items: center;
    min-height: 500px;
}

.community-activities,
.personal-acitivities {
    width: 100%;
    display: flex;

}

.community-activities {
    justify-content: start;
    text-align: center;
    align-items: center;

}



.community-tab-bar>div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 100%;
    text-align: center;
    gap: 3%;
}

.community-tab-bar>div:hover {
    background-color: rgba(255, 255, 255, 0.133);
    cursor: pointer;
}

.movie-tab-bar>div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 100%;
    text-align: center;
    gap: 5px;
}

.movie-tab-bar>div:hover {
    background-color: rgba(255, 255, 255, 0.133);
    cursor: pointer;
}


.active {
    background-color: black !important;
    transform: translateY(0px);
    z-index: 6;
    border-bottom: 1px #f8b632 solid;
}

.comment {
    width: 100%;
    max-height: 140px;
    border: 1px solid transparent;
    background-color: rgb(50, 50, 50);
    margin-block: 5px;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.4s;
    cursor: pointer;
}

.comment:hover {
    border-color: #f8b632;
}

.comment-content {
    color: white;
}

.comment-movie {
    font-size: 1.5rem;
    color: lightgrey;
    width: 100%;
}

.comment-movie:hover {
    cursor: pointer;
    filter: brightness(50%);
}

.go-comment {
    align-self: flex-end;
    width: 20%;
    padding: 7px;
    border-radius: 20px;
    background-color: #8ACDD7;
    color: #F9F9E0;
    border: 1px solid #F9F9E0;
}

.go-comment:hover {
    background-color: #F9F9E0;
    color: #8ACDD7;
    border: 1px solid #8ACDD7;
}

@media screen and (max-width: 768px) {
    .profile-wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column !important;
        align-items: center;
        height: auto !important;
    }

    .left_side {
        width: 100%;
        gap: 15px;
        display: flex;
        flex-direction: row;
    }

    .community-activities-tab,
    .personal-activities-tab {
        font-size: 0.7rem;
    }

    .movie {
        height: 40% !important;
    }

    .comment {
        height: 50% !important;
        font-size: 0.7rem;
    }

    .community-wrapper {
        height: 50% !important;
    }

}

@media screen and (max-width: 500px) {
    .top_margin_div {
        height: 0vh;
    }

    .profile-wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column !important;
        align-items: center;
        height: auto !important;
        gap: 25px;
    }

    .left_side {
        width: 100%;
        gap: 15px;
        display: flex;
        flex-direction: column;
    }

    .right-tab-bar {
        justify-content: center;
        align-items: center;
        min-height: auto;
    }

    .personal-activities-tab {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0.3em;
        padding-right: 0.3em;
    }

    .community-activities-tab {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0.3em;
        padding-right: 0.3em;
    }

    .tab_header {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-bottom: 0;
    }

    .movie-tab-bar {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        height: 50px;
        gap: 0;
        width: 100%;
    }

    .community-tab-bar {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        height: 50px;
        top: 0px;
        width: 100%;
        gap: 3%;
    }

    .count {
        background-color: #f8b632;
        color: color;
        min-width: 18px;
        height: 18px;
        border-radius: 50%;
        padding: 3px 6.85px;
        font-size: 11px;
        font-weight: 400;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .count span {
        color: black;
        font-weight: 500;
    }

    .tab-content {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-auto-rows: 180px;
        height: 480px;
        background-color: rgba(255, 255, 255, 0.133);
        width: 100%;
        flex-wrap: none;
        gap: 5px;
        justify-content: unset;
        z-index: 2;
        border-radius: 4px;
        padding: 0.3rem;
        overflow-x: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
    }

    .community-tab-content {
        display: flex;
        height: 550px;
        background-color: rgba(255, 255, 255, 0.133);
        width: 100%;
        flex-wrap: wrap;
        overflow-y: scroll;
        justify-content: space-between;
        z-index: 2;
        gap: 1%;
        border-radius: 5px;
        padding: 0.4rem;
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
    }

    .movie {
        margin: 0;
        min-height: 180px;
        min-width: 100%;
        background-color: black;
        width: 100%;
        border-radius: 8px;
        cursor: pointer;
    }

    .mobile_tab_flex_wrap {
        display: flex;
        height: 450px;
        background-color: rgba(255, 255, 255, 0.133);
        width: 100%;
        flex-wrap: wrap;
        overflow-x: hidden;
        overflow-y: scroll;
        justify-content: flex-start;
        gap: 8px;
        padding: 0.2rem;
        z-index: 2;
        border-radius: 5px;
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
    }

    .community_card_wrap {
        display: flex;
        margin: 0;
        height: 320px;
        min-width: 49%;
        width: 49%;
        border-radius: 8px;
        cursor: pointer;
    }


}
</style>
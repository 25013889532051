<template>
    <div class="comment_card">
        <div class="comment_card_left">
            <img class="postavatar" :src="item.avatar" />
            <span class="content">{{ item.name }}</span>
            <div class="user-icon">
                <span class="badge_rank">{{ rank }}</span>
                <img class="rank_icon" :src="rankIcon" />
            </div>
        </div>
        <div class="comment_card_middle">
            <div class="">
                <div class="f sb">
                    <span class="date_text">{{ formattedDate }}</span>
                    <span class="content">#{{ this.$.vnode.key + 1 }}</span>
                </div>
                <hr class="hr hidden" />
            </div>
            <div>
                <p>
                    {{ item.content }}
                </p>
            </div>
            <div class="social_info">
                <div class="social_item">
                    <img src="../../assets/images/reply.png" />
                    <span class="content">Yanıtla</span>
                </div>
                <div class="social_item">
                    <img src="../../assets/images/report.png" />
                    <span class="content">Rapor Et</span>
                </div>
            </div>
        </div>
        <div class="post_comment_right">
            <div @click="incrementNumber" class="up" :style="{ borderColor: upPressed ? 'green' : 'white' }"></div>
            <span class="number_div"> {{ number }} </span>
            <div @click="decreaseNumber" class="down" :style="{ borderColor: downPressed ? 'red' : 'white' }"></div>
        </div>
    </div>
</template>

<script>
import { getFormattedDate } from '../../utils/DateUtils.js';
import rankIcon from '../../assets/images/rank.png';
import ImageUtil from '../../utils/ImageUtil';

export default {
    data() {
        return {
            number: 0,
            upPressed: false,
            downPressed: false,
            rankIcon: rankIcon,
            rank: '',
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        async incrementNumber() {
            this.number += 1;
            this.upPressed = true;
            this.downPressed = false;
            await this.voteComment(1);
        },
        async decreaseNumber() {
            this.number -= 1;
            this.downPressed = true;
            this.upPressed = false;
            await this.voteComment(0);
        },
        async voteComment(number) {
            this.$emit('vote-comment', this.item.comment_id, number)
        },
        setBadge(user) {
            if (!user) return this.rankIcon = ImageUtil.discover;
            this.rankIcon = ImageUtil.getBadge(user.rank_id);
        }
    },
    computed: {
        formattedDate() {
            return getFormattedDate(this.item.created_at);
        }
    },

    async created() {
        this.number = this.item.vote;

        if (!this.item) {
            this.rankIcon = ImageUtil.discover;
            this.rank = ''
        }
        this.setBadge(this.item);
        this.rank = this.item.rank_name
    },
    components: {}
}
</script>

<style scoped>

.user-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.badge_rank {
    font-size: 13px;
    color: #f8b632;
}


.content {
    font-size: 2vh;
    color: white;
    cursor: pointer;
}

.up,
.down {
    content: "";
    width: 10px;
    height: 10px;
    border: solid white;
    border-width: 1px 1px 0 0;
    cursor: pointer;
}

.up {
    transform: rotate(-45deg);
}

.down {
    transform: rotate(135deg);
}

.hr {
    border: 1px gray solid;
}

.header {
    font-size: 3.6vh;
    font-weight: 700;
}

.comment_card p {
    font-size: 2vh;
    color: white;
    cursor: unset;
}

.social_inner {
    display: flex;
    justify-content: space-between;
    gap: 25px;
}

.social_item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    gap: 5px;
    cursor: pointer;
    padding: 4px;
}

.social_item:hover {
    background-color: #4a4a4c;
    border-radius: 2px;
}

.comment_card {
    background-color: #1A1A1B;
    width: 100%;
    padding: 1rem 1rem;
    border: 1px rgb(58 58 58) solid;
    border-radius: 6px;
    display: flex;
    gap: 2%;
    margin-top: 10px;
}

.postcard:hover {
    background-color: #262627;
}

.comment_card_left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 15%;
}

.comment_card_middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10%;
    width: 85%;
    color: white;
}

.post_comment_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10%;
    color: white;
}

.social_item img {
    width: 20px;
    min-height: 20px;
}

.rank_icon {
    width: 28px;
    min-height: 28px;
}

.postavatar {
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: white;
}

.social_info {
    display: flex;
    justify-content: space-between;
}

.social_left {
    display: flex;
    gap: 20%;
}

@media screen and (max-width: 920px) {

    .text {
        display: none;
    }

    .hidden {
        display: none;
    }

    .comment_card p {
        font-size: 11px;
        color: white;
        cursor: unset;
    }

    .date_text {
        font-size: 11px;
    }

    .comment_card {
        margin-block: 8px;
        background-color: #1A1A1B;
        width: 100%;
        padding: 0.5rem 0.4rem;
        border: 1px rgb(58 58 58) solid;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        gap: 0.5%;
        margin-top: 0px;
    }

    .comment_card_left {
        gap: 4px;
        width: 20%;
        flex-wrap: wrap;
    }

    .post_comment_right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 5%;
        gap: 15%;
        color: white;
    }

    .comment_card_middle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0;
        width: 70%;
        color: white;
    }

    .postavatar {
        cursor: pointer;
        height: 1.8rem;
        width: 1.8rem;
        border-radius: 50%;
        background-color: white;
    }

    .rank_icon {
        width: 24px;
        min-height: 24px;
    }

    .content {
        font-size: 11px;
    }

    .social_info {
        margin-top: 0%;
        display: flex;
        justify-content: space-between;
        padding-right: 4%;
        gap: 20px;
        height: 30px;
    }

    .social_inner {
        display: flex;

    }

    .social_item {
        align-items: flex-end;
    }

}
</style>
<template>
    <div @click="go(item.movie_id)" v-for="(item, index) in items" :key="index" class="comment">
        <div class="com_left">
            <img class="small" :src="getImageUrl(item.movie.poster)" />
        </div>
        <div class="comm_right">
            <h4 class="small_font">{{ item.movie.title }}</h4>
            <div class="comment-content">
                {{ item.comment_text.slice(0, 90) }}...
            </div>
            <div style="width: 100%;" class="f sb">
                <div class="comment-content">{{ formatDate(item.time) }}</div>
            </div>
        </div>
        <div class="right_most">
            <button style="background-color: transparent; border: none;">
                <img class="small-icon" src="../../assets/images/right-arrow.png" />
            </button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            tabs: [],
        }
    },
    emits: ['go'],
    props: {
        items: {
            type: Array,
            required: true
        },
    },
    methods: {
        getImageUrl(imageUrl) {
            let str = imageUrl;
            if (imageUrl.includes('@.')) {
                const startIndex = imageUrl.indexOf('@.')
                str = imageUrl.slice(0, startIndex + 1)
                str += '.jpg';
            }

            return str
        },
        formatDate(time) {
            return moment(time).format('DD-MM-YYYY')
        },
        go(id) {
            this.$emit('go', id)
        }

    },
    created() {
    },
    components: {}
}
</script>

<style scoped>
.comment-content {
    color: rgb(126, 125, 125)
}

h4 {
    color: white;
    font-weight: 800;
}

.small-icon {
    width: 15px;
}

.comment {
    width: 100%;
    max-height: 130px;
    border: 1px solid transparent;
    background-color: rgb(50, 50, 50);
    margin: 10px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.4s;
    cursor: pointer;
}

.comment:hover {
    border-color: #f8b632;
}

.com_left {
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small {
    width: 60px;
    height: 90px;
}

.comm_right {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.right_most {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .comment {
        height: 50% !important;
        font-size: 0.7rem;
    }

    .comment {
        width: 100%;
        max-height: 130px;
        border: 1px solid transparent;
        background-color: rgb(50, 50, 50);
        margin: 0;
        padding: 10px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        transition: 0.4s;
        cursor: pointer;
        gap: 8px;
    }

    .com_left {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .comm_right {
        width: 75%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .right_most {
        display: flex;
        align-items: center;
        width: 5%;
    }

    .small {
    width: 95%;
    height: 95%;
}
}
</style>
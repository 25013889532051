<template>
    <div class="community_statistic">
        <div class="tab">
            <div v-for="(item, index) in tabs" :key="index" class="inner_tab"
                :class="{ 'inner_tab': true, 'active': index === activeTabIndex }" @click="setActiveTab(index)">
                {{ item.langTR }}
            </div>
        </div>
        <div v-if="!loading" class="user_wrapper">
            <div v-for="(item, index) in items" :key="index" class="info_wrapper">
                <div class="info_left">
                    <span class="">{{ index + 1 }}.</span>
                    <img class="active_avatar" :src="item.img" />
                    <span class="name">{{ item.name }}</span>
                </div>
                <div class="info_right">
                    <img class="small-icon" src="../../assets/images/tickets.png" />
                    <span class="name">{{ item.points }}</span>
                </div>
            </div>
        </div>
        <div v-else class="loader_wrap">
            <DotLoader :loading="loading" :width="'50px'" />
        </div>
    </div>
</template>
  
<script>
import DotLoader from '../Loaders/DotLoader.vue';

export default {
    data() {
        return {
            activeTabIndex: 0
        }
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        tabs: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    methods: {
        setActiveTab(index) {
            this.activeTabIndex = index;
            this.$emit('filter-leaders', this.tabs[this.activeTabIndex].code ?? 'all');
        }
    },
    components: { DotLoader }
}
</script>
  
<style scoped>
.loader_wrap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    min-height: 150px;
}

.tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 4px;
}

.inner_tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30px;
    font-weight: 500;
    cursor: pointer;
    color: white;
    border-bottom: 2px solid transparent;
    transition: 0.5s;
}


.inner_tab.active {
    color: white;
    border-bottom: 2px solid #f8b632;
}

.community_statistic {
    color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0.5rem 1rem;
    border: 1px rgb(60, 60, 60) solid;
    border-radius: 1px;
    display: flex;
    gap: 5px;
    width: 100%;
    border-radius: 5px;
    background-color: #1A1A1B;
}

.user_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0.5rem;
    margin-top: 10px;
    width: 100%;
}

.info_wrapper span {
    font-weight: bold;
    font-size: 16px;
}

.info_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.info_right {
    display: flex;
    align-items: center;
    gap: 4px;
}

.info_wrapper .name {
    font-size: 14px;
}

.community_statistic p {
    color: white;
}

.community_statistic h3,
h4 {
    color: white;
    font-weight: bold;
}

.info {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 5%;
}

.info_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.info_wrapper:hover {
    color: #f8b632;
    cursor: pointer;
}

.active_avatar {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: white;
}
</style>
  
<template>
    <div class="post_card">
        <div class="post_card_left">
            <img class="postavatar" :src="item.avatar" alt="null" />
            <span class="content">{{ item.name }}</span>
            <div class="user-icon">
                <span class="badge_rank">{{ rank }}</span>
                <img class="rank_icon" :src="rankIcon" />
            </div>
        </div>
        <div class="post_card_middle">
            <div>
                <span class="header">{{ item.title }}</span>
                <p class="content">
                    {{ item.content }}
                </p>
            </div>
            <div class="social_info">
                <div class="social_inner">
                    <div class="social_item">
                        <img src="../../assets/images/message.png" />
                        <span class="content">{{ comment }} <span class="text">Yorumlar</span> </span>
                    </div>
                    <div @click="this.$emit('copyLink')" class="social_item">
                        <img src="../../assets/images/share.png" />
                        <span class="content"><span class="text">Paylaş</span></span>
                    </div>
                    <div @click="this.$emit('report')" class="social_item">
                        <img src="../../assets/images/report.png" />
                        <span class="content"><span class="text">Rapor Et</span></span>
                    </div>
                </div>
                <div class="posted_time">
                    <span><span class="text">Gönderi Tarih</span> {{ formattedDate }}</span>
                </div>

            </div>
        </div>
        <div class="post_card_right">
            <div @click="incrementNumber" class="up" :style="{ borderColor: upPressed ? 'green' : 'white' }"></div>
            <span class="number_div"> {{ number }} </span>
            <div @click="decreaseNumber" class="down" :style="{ borderColor: downPressed ? 'red' : 'white' }"></div>
        </div>
    </div>
</template>

<script>
import { getFormattedDate } from '../../utils/DateUtils';
import rankIcon from '../../assets/images/rank.png';
import ImageUtil from '../../utils/ImageUtil';

export default {
    data() {
        return {
            number: 0,
            upPressed: false,
            downPressed: false,
            rankIcon: rankIcon,
            rank: '',
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        comment: {
            type: Number,
            required: true
        }
    },
    methods: {
        async incrementNumber() {
            this.number += 1;
            this.upPressed = true;
            this.downPressed = false;
            await this.votePost(1);
        },
        async decreaseNumber() {
            this.number -= 1;
            this.downPressed = true;
            this.upPressed = false;
            await this.votePost(0);
        },
        async votePost(number) {
            this.$emit('vote-post', number)
        },
        setBadge(user) {
            if (!user) return this.rankIcon = ImageUtil.discover;
            this.rankIcon = ImageUtil.getBadge(user.rank_id);
        }
    },
    computed: {
        formattedDate() {
            return getFormattedDate(this.item.created_at);
        }
    },
    async created() {
        this.number = this.item.net_votes || 0;
        if (!this.item) {
            this.rankIcon = ImageUtil.discover;
            this.rank = ''
        }
        this.setBadge(this.item);
        this.rank = this.item.rank_name
    },
    components: {}
}
</script>

<style scoped>
.up,
.down {
    content: "";
    width: 10px;
    height: 10px;
    border: solid white;
    border-width: 2px 2px 0 0;
    cursor: pointer;
}

.number_div {
    color: white;
}

.up {
    transform: rotate(-45deg);
}

.down {
    transform: rotate(135deg);
}

.rank_icon {
    width: 25px;
    min-height: 25px;
}

.user-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.badge_rank {
    font-size: 13px;
    color: #f8b632;
}

.header {
    font-size: 2.6vh;
    font-weight: 700;
}

.social_inner {
    display: flex;
    gap: 25px;
}

.social_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 4px;
}

.social_item,
.posted_time span {
    font-size: 1.65vh;
}

.post_card {
    background-color: #1A1A1B;
    padding: 1rem 1rem;
    border: 1px rgb(58 58 58) solid;
    border-radius: 5px;
    display: flex;
    gap: 2%;
    margin-bottom: 0px;
}

.postcard:hover {
    background-color: #262627;
}

.post_card_left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 15%;
    padding: 0.5rem 0.5rem;
}

.post_card_middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10%;
    width: 85%;
    color: white;
}

.post_card_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10%;
    color: white;
}

.social_item img {
    width: 18px;
    min-height: 18px;
}

.social_item:hover {
    background-color: #4a4a4c;
    border-radius: 4px;
}

.content {
    font-size: 2.2vh;
    color: white;
    cursor: pointer;
}

.postavatar {
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: white;
}

.social_info {
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
    padding-right: 4%;
}

.social_left {
    display: flex;
    gap: 20%;
}

@media screen and (max-width: 920px) {

    .text {
        display: none;
    }

    .post_card {
        background-color: #1A1A1B;
        padding: 1rem 0.4rem;
        border: none;
        border-radius: 4px;
        display: flex;
        gap: 0.5%;
        margin-bottom: 0px;
    }

    .badge_rank {
        font-size: 11px;
        color: #f8b632;
    }

    .post_card_left {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 20%;
        padding: 0.2rem 0em;
    }

    .post_card_right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 10%;
        gap: 10%;
        color: white;
    }

    .post_card_middle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0;
        width: 70%;
        color: white;
    }

    .postavatar {
        cursor: pointer;
        height: 1.8rem;
        width: 1.8rem;
        border-radius: 50%;
        background-color: white;
    }

    .rank_icon {
        width: 24px;
        min-height: 24px;
    }

    .content {
        font-size: 11px;
    }

    .social_info {
        margin-top: 0%;
        display: flex;
        justify-content: space-between;
        padding-right: 4%;
        height: 30px;
    }

    .social_inner {
        display: flex;
        gap: 10px;
    }

    .social_info {
        margin-top: 0%;
        display: flex;
        justify-content: space-between;
        padding-right: 4%;
    }

    .header {
        font-size: 14px;
    }
}
</style>
<template>
    <div class="recent_posts">
        <div class="top">
            <div class="filter_tab">
                <button @click="selectFilter(item.code)" class="filter_button" v-for="(item, index) in filters"
                    :key="index" :class="{ 'selected': selectedFilter === item.code }">
                    {{ item.langTR }}
                </button>
            </div>
        </div>
        <div class="middle" v-if="posts">
            <DataCard @goToLink="goLink" v-for="(item, index) in posts" :key="index" :item="item" />
        </div>
    </div>
</template>

<script>
import DataCard from '../Community/DataCard.vue';

export default {
    data() {
        return {
            tabs: [],
            selectedFilter: null,
            filters: [
                { id: 0, name: 'Latest Topics', langTR: 'Son Başlıklar', code: 'latest' },
                { id: 1, name: 'Top Topics', langTR: 'En İyi Başlıklar',code: 'top' },
                { id: 2, name: 'Trending', langTR: 'Trendler',code: 'hot' },
            ],
        }
    },
    props: {
        posts: {
            type: Array,
            required: true
        },
    },
    methods: {
        getImageUrl(imageUrl) {
            if (imageUrl === undefined) return
            const startIndex = imageUrl.indexOf('@.')
            let str = imageUrl.slice(0, startIndex + 1)
            str += '.jpg'
            return str
        },
        goLink(postID, communityID) {
            return this.$emit('goLink', postID, communityID);
        },
        selectFilter(filterCode) {
            this.selectedFilter = filterCode;
            this.$emit('filter', filterCode);
        },
    },
    created() {
    },
    components: { DataCard }
}
</script>

<style scoped>
h3 {
    color: white;
    font-weight: 800;
}

.middle {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.top {
    margin-bottom: 10px;
}

.filter_tab {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 3%;
    padding: 0.5rem 0rem;
}

.filter_button {
    background-color: transparent;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: 500;
}

.filter_button:hover {
    cursor: pointer;
    color: #f8b632;

}

.filter_button.selected {
    color: #f8b632;
}

.recent_posts {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px rgb(60, 60, 60) solid;
    padding: 0.8rem 1rem;
    color: white;
    border-radius: 5px;
}

@media (max-width: 590px) {

    .recent_posts {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 1px rgb(60, 60, 60) solid;
        padding: 0.4rem 0.2rem;
        color: white;
        border-radius: 5px;
    }

    .middle {
        padding-top: 0rem;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

}
</style>
<template>
  <div class="related_movie_element" @click="this.$emit('goToMovie', item.id)">
    <img class="prevent-user-img poster" :src="getImageUrl(item.poster)" />
    <div class="movie-stats">
      <div class="f align-center">
        <img class="small-icon" src="../../assets/images/imdb.png" />
        <div style="width: 5px"></div>
        <span class="small-font-white">{{ item.imdbrating }}</span>
      </div>
    </div>
    <div class="movie-info">
      <div class="f align-center" style="justify-content: center">
        <span class="small-title-white">{{ item.title }}</span>
      </div>
      <div class="f sb">
        <div class="f align-center">
          <img class="small-icon" src="../../assets/images/tickets.png" />
          <div style="width: 5px"></div>
          <span class="small-font-white">{{ item._count.like }}</span>
        </div>
        <div class="f align-center">
          <img class="small-icon" src="../../assets/images/message.png" />
          <div style="width: 5px"></div>
          <span class="small-font-white">{{ item._count.comment }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visibleMovieCount: null,
      filmBoxWidth: 220,
      showedCount: 0
    }
  },
  methods: {
    getImageUrl(imageUrl) {
      let str = imageUrl;
      if (imageUrl && imageUrl.includes('@.')) {
        const startIndex = imageUrl.indexOf('@.')
        str = imageUrl.slice(0, startIndex + 1)
        str += '.jpg';
      }
      return str;
    },
  },
  mounted() { },
  created() { }
}
</script>

<style scoped>
.small-icon {
  width: 24px;
}

.related_movie_element {
  min-height: 260px;
  width: 85%;
  max-width: 300px;
  background-color: white;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
}

.related_movie_element:hover {
  transform: scale(1.0);
  transition: 0.2s ease-in-out;
  z-index: 1;
}

.related_movie_element:not(:hover) {
  transform: scale(1.0);
  transition: 0.2s ease-in-out;
  z-index: 1;
}

.related_movie_element:hover img {
  transition: 0.2s ease-in-out;
  border-radius: 0px;
}

.movie-info {
  position: absolute;
  bottom: 0;
  color: #f8b632;
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 20px;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 2%;
  border-radius: 0;
}

.movie-stats {
  position: absolute;
  display: none;
  top: 0;
  border-radius: 3px;
  transition: 0.3s ease-in;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  top: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  border-radius: 0px;
  color: #f8b632;
}

.poster {
  width: 100%;
  height: 100%;
  min-height: 200px;
  border-radius: 3px;
}

.small-font-white {
  font-size: 12px;
  color: #f8b632;
}

.small-title-white {
  text-align: center;
  font-size: 13px;
  color: #f8b632;
}

@media screen and (max-width:600px) {
  .related_movie_element {
    min-height: 340px;
    width: 85%;
    max-width: 300px;
    background-color: white;
    position: relative;
    cursor: pointer;
    border-radius: 3px;
  }

  .poster {
    width: 100%;
    height: 100%;
    min-height: 300px;
    border-radius: 3px;
  }

}
</style>

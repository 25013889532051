import { apiInstance } from '../services/ApiService.js'

const API_URL = '/list';

const getMovies = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/all', {
            params: {
                limit: req.limit,
                order_by: req.order_by,
                order_dir: req.order_dir,
                user_id: req.user_id
            }
        })
        if (!response.data.success) return []
        return response.data
    } catch (error) {
        return []
    }
}

const searchByQuery = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/search', {
            params: {
                limit: req.limit,
                order_by: req.order_by,
                order_dir: req.order_dir,
                search_term: req.search,
                user_id: req.user_id
            }
        })
        if (!response.data.success) return []
        return response.data.movies
    } catch (error) {
        return []
    }
}

const addMovie = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/add', {
            params: {
                movie_id: req.movie_id,
                user_id: req.user_id
            }
        });
        if (!response) return false;
        return response.data.success;
    } catch (error) {
        return false;
    }
}

const deleteMovie = async (req) => {
    try {
        const response = await apiInstance.delete(API_URL + '/', {
            params: {
                movie_id: req.movie_id,
                user_id: req.user_id
            }
        })
        if (!response) return false;
        if (response.data.success) return response.data.movie_id;
    } catch (error) {
        return false;
    }
}

const getWatchlistAll = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/watchlist/get-all', {
            params: {
                sortBy: req.sortBy,
                dir: req.dir
            }
        })
        if (!response.data.success) return []
        return response.data.data
    } catch (error) {
        return []
    }
}

const ListService = {
    getMovies,
    deleteMovie,
    searchByQuery,
    addMovie,
    getWatchlistAll,
}

export default ListService

<template>
  <div class="film-top-bottom">
    <div v-for="(index) in reelCount" :key="index" class="film-top-bottom-element"></div>
  </div>

  <div class="slider-bar">
    <div class="slider_wrap" :style="{ transform: 'translateX(' + currentSlide * -100 + '%)' }">
      <div v-for="(item, index) in items" :key="index" class="category_element" @click="this.$emit('go-routes')">
        <span class="number">#{{ index + 1 }}</span>
        <img class="prevent-user-img img_poster" :src="item.icon" />
        <div class="stat-info">
          <div class="f align-center" style="justify-content: center">
            <h6>{{ item.name }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="navigation_wrap">
      <div class="slider_button_left">
        <VueIcon @click="prevSlide" style="color:rgb(237, 190, 50);" icon="mingcute:left-line" height="32px"
          width="32px">
        </VueIcon>
      </div>
      <div class="slider_button_right">
        <VueIcon @click="nextSlide" style="color:rgb(237, 190, 50);" icon="mingcute:right-line" height="32px"
          width="32px">
        </VueIcon>
      </div>
    </div>
  </div>

  <div class="film-top-bottom mt-6">
    <div v-for="(index) in reelCount" :key="index" class="film-top-bottom-element"></div>
  </div>
</template>

<script>

export default {
  emits: ["go-routes"],
  props: {
    items: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reelCount: 0,
      currentSlide: 0,
      windowWidth: 1280,
      visibleMovieCount: null,
      filmBoxWidth: 170,
      showedCount: 0,
      movieUpperLimit: 0
    }
  },
  methods: {
    getImageUrl(imageUrl) {
      let str = imageUrl;
      if (!imageUrl) return;
      if (imageUrl.includes('@.')) {
        const startIndex = imageUrl.indexOf('@.')
        str = imageUrl.slice(0, startIndex + 1)
        str += '.jpg';
      }

      return str
    },
    nextSlide() {
      if (this.currentSlide + 1 >= Math.ceil(this.movieUpperLimit)) {
        this.currentSlide = 0
      } else {
        this.currentSlide += 1
      }
    },
    prevSlide() {
      if (this.currentSlide == 0) return (this.currentSlide = Math.floor(this.movieUpperLimit))
      this.currentSlide -= 1
    },
    checkVisibleMovie() {
      this.movieUpperLimit = this.items.length / this.visibleMovieCount;
    },
    setVisibleCount() {
      this.windowWidth = window.innerWidth
      this.visibleMovieCount = this.windowWidth / this.filmBoxWidth
    },
    setReelCount() {
      this.reelCount = Math.floor(this.windowWidth / 25)
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      this.visibleMovieCount = this.windowWidth / this.filmBoxWidth
      this.movieUpperLimit = this.items.length / this.visibleMovieCount
      this.reelCount = Math.floor(this.windowWidth / 25)
    })
  },
  created() {
    this.windowWidth = window.innerWidth
    this.showedCount = this.windowWidth / this.filmBoxWidth
    this.setVisibleCount()
    this.checkVisibleMovie()
    this.setReelCount()
  }
}
</script>

<style>
.slider-bar {
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.slider_wrap {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  height: 100%;
  gap: 15px;
  transition: transform 0.6s ease-in-out;
}

.navigation_wrap {
  position: absolute;
  top: 30%;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  justify-content: space-between;
}

.small-icon {
  width: 20px;
}

.mb-6 {
  margin-bottom: 0.6rem;
}

.mt-6 {
  margin-top: 0.6rem;
}

.number {
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  left: 5px;
  top: 0px;
  color: #f8b632;
}

.category_element {
  border-radius: 6px;
  margin-left: 0px;
  margin-right: 0px;
  width: 200px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}

.category_element:hover {
  transform: scale(1.04);
  transition: 0.2s ease-in-out;
  z-index: 999;
}

.category_element:not(:hover) {
  transform: scale(1);
  transition: 0.2s ease-in-out;
  z-index: 1;
}

.category_element:hover img {
  transition: 0.3s ease-in-out;
}

.stat-info {
  position: absolute;
  bottom: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.img_poster {
  height: 100px;
  width: 100px;
  border-radius: 2px;
}

.film-top-bottom {
  display: flex;
  height: 2rem;
  width: 100%;
  gap: 1rem;
  padding-left: 5px;
  padding-right: 5px;
  background-color: black;
}

.film-top-bottom-element {
  height: 1rem;
  background-color: transparent;
  width: 10px;
  border-radius: 2px;
  background-color: white;
}

.small-font-white {
  font-size: 12px;
  color: white;
}

.nav_arrow {
  background-color: red;
  outline: none;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-top: 4px #f8b632 solid;
  border-left: 4px #f8b632 solid;
  border-bottom: none;
  border-right: none;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}


.slider_button_left {
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 40%;
  height: 45px;
  width: 45px;
  background-color: #08080899;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  transition: 0.1s;
}

.stat-info .small-icon {
  width: 24px;
}

.slider_button_right {
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 40%;
  height: 45px;
  width: 45px;
  background-color: #08080899;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  transition: 0.1s;
  display: flex;
}

.nav_arrow {
  background-color: red;
  outline: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-top: 4px #f8b632 solid;
  border-left: 4px #f8b632 solid;
  border-bottom: none;
  border-right: none;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

h6 {
  color: #f8b632;
  font-size: 15px;
  font-weight: bold;
}

@media screen and (max-width: 700px) {

  h6 {
    font-size: 12px;
  }

  .mb-6 {
    margin-bottom: 0rem;
  }

  .mt-6 {
    margin-top: 0rem;
  }

  .slider-bar {
    width: 100%;
    height: 90%;
    margin: 0;
    overflow: hidden;
    position: relative;
  }

  .stat-info .small-icon {
    width: 18px;
  }

  .small-font-white {
    font-size: 11px;
    color: white;
  }

  .category_element {
    border-radius: 6px;
    margin-left: 5px;
    margin-right: 5px;
    width: 140px;
    height: 85%;
    background-color: white;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .film-top-bottom {
    display: flex;
    height: 1.4rem;
    width: 100%;
    gap: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    background-color: black;
  }

  .film-top-bottom-element {
    height: 0.9rem;
    background-color: transparent;
    width: 10px;
    border-radius: 2px;
    background-color: white;
  }


}

@media screen and (max-width: 700px) {

  h6 {
    font-size: 12px;
  }

  .mb-6 {
    margin-bottom: 0rem;
  }

  .mt-6 {
    margin-top: 0rem;
  }

  .slider-bar {
    width: 100%;
    height: 90%;
    margin: 0;
    overflow: hidden;
    position: relative;
  }

  .stat-info .small-icon {
    width: 18px;
  }

  .small-font-white {
    font-size: 11px;
    color: white;
  }

  .category_element {
    border-radius: 6px;
    margin-left: 5px;
    margin-right: 5px;
    width: 140px;
    height: 100%;
    background-color: white;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .film-top-bottom {
    display: flex;
    height: 1.4rem;
    width: 100%;
    gap: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    background-color: black;
  }

  .film-top-bottom-element {
    height: 0.9rem;
    background-color: transparent;
    width: 10px;
    border-radius: 2px;
    background-color: white;
  }

}
</style>
<template>
  <meta name="referrer" content="no-referrer" />
  <div class="movie-comment">
    <div class="user">
      <img v-if="user.profile_image_url" class="circle-avatar" :src="user.profile_image_url" />
      <img v-else class="circle-avatar" :src="avatar" />
      <div class="user-icon"><img :src="rankIcon" /></div>
    </div>
    <div class="comment_right">
      <div class="comment-user-info align-bottom">
        <div class="comment-username">{{ user.username }}</div>
      </div>
      <div class="comment">
        {{ item.comment }}
      </div>
      <div v-if="item.like_count" class="comment-under-info">
        <div style="display: flex" class="comment-likes" @click="likeCommentMovie">
          <img :src="like" class="small-icon" />
          <span class="">{{ item.like_count }} </span>
        </div>

        <div class="date"><span class="text">Tarih:  </span>{{ formatDate(item.created_at) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import rankIcon from '../../assets/images/rank.png';
import like from '../../assets/images/tickets.png';
import avatar from '../../assets/images/avatar.png'

export default {
  emits: ["likeComment"],
  props: {
    item: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rankIcon: rankIcon,
      like,
      avatar
    }
  },
  methods: {
    formatDate(time) {
      return moment(time).format('DD-MM-YYYY')
    },
    likeCommentMovie() {
      this.$emit('likeComment', this.item.comment_id, this.item.movie_id);
    }
  }
}
</script>

<style>
.circle-avatar {
  width: 40px;
  border-radius: 50%;
}

.movie-comment {
  color: white;
  background-color: #232323;
  border-radius: 4px;
  margin-block: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  padding: 0.5rem 0.8rem;
  transition: 0.4s;
  border: 1px solid transparent;
}

.movie-comment:hover {
  background-color: #323232;
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 15%;
}

.comment_right {
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment-user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-username {
  color: white;
  font-weight: bold;
}

.date {
  margin-left: auto;
}

.comment {
  word-wrap: break-word;
}

.avatar {
  pointer-events: none;
  width: 80%;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid black;
  margin: 2%;
  position: relative;
  left: 10%;
  top: 10%;
}

.comment-under-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.comment-likes {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.comment-likes:hover {
  background-color: #232323;
}

.comment-replies {
  margin-left: auto;
}

.small-icon {
  width: 28px;
}

.heart {
  background-image: url('../../assets/images/heart.png');
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
}

.heart:active {
  background-image: url('../../assets/images/heartwhite.png');
}

@media screen and (max-width: 920px) {
  .movie-comment {
    color: white;
    background-color: #232323;
    border-radius: 5px;
    margin-block: 8px;
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 100%;
    padding: 0.3rem 0.1rem;
    transition: 0.4s;
    border: 1px solid transparent;
  }

  .text {
    display: none;
  }

  .date {
    font-size: 12px;
  }

  .movie-comment:hover {
    background-color: #232323;
  }

  .comment-likes:hover {
    background-color: rgb(8, 8, 8)23;
  }
}
</style>
<template>
  <div class="featured_community">
    <div class="left" :style="{ backgroundImage: `url(${item.img === '' ? defaultIcon : item.img})` }">
      <button @click="this.$emit('goLink', item.community_id)" class="hover_item">
        <VueIcon class="list_icon" style="color:white;" icon="material-symbols-light:arrow-circle-right-outline-rounded"
          height="60px" width="60px">
        </VueIcon>
        <div class="tooltip">
          <span class="tooltiptext">Topluluğa Git</span>
        </div>
      </button>
      <div class="featured_title">
        <span style="padding-left: 0px;" class="category-font">
          Öne Çıkan
        </span>
      </div>
    </div>
    <div class="right">
      <div class="row">
        <div class="main_col">
          <h1>{{ item.name }}</h1>
          <span class="overview">
            {{ item.description }}
          </span>
          <div class="community_statistic">
            <div class="info_wrapper">
              <div class="info">
                <div>
                  <h3>#{{ item.post_count }}</h3>
                  <span>Sıralama</span>
                </div>
              </div>
              <div class="info">
                <div>
                  <img class="ic" alt="icon" :src="icons.POST" />
                </div>
                <div>
                  <h3>{{ item.post_count }}</h3>
                  <span>Gönderiler</span>
                </div>
              </div>
              <div class="info">
                <div>
                  <img class="ic" alt="icon" :src="icons.MEMBER" />
                </div>
                <div>
                  <h3>{{ item.member_count }}</h3>
                  <span>Üyeler</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import post from "../../assets/images/paper.png"
import member from "../../assets/images/group.png"
import reply from "../../assets/images/reply-all.png"
import defaultIcon from '../../assets/images/focus-group.png'

export default {
  data() {
    return {
      icons: {
        POST: post,
        MEMBER: member,
        REPLY: reply
      },
      defaultIcon
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  methods: {
    getImageUrl(imageUrl) {
      if (imageUrl === undefined) return
      const startIndex = imageUrl.indexOf('@.')
      let str = imageUrl.slice(0, startIndex + 1)
      str += '.jpg'
      return str
    },

  },
  watch: {
    item() {
    }
  },
}
</script>

<style scoped>
.category-font {
  font-size: 18px;
}

.list_icon {
  width: 60px;
  height: 60px;
}

.hover_item {
  display: none;
}

.left:hover .hover_item {
  display: grid;
  align-items: center;
  position: relative;
  top: 50%;
  left: 50%;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: none;
}

.left:hover .list_icon:hover {
  transition: 0.2s ease-in-out;
  transform: scale(1.1);
  cursor: pointer;
}

.left:hover .featured_title {
  transition: 0.2s;
}

.tooltip {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  transition: 1s ease-in-out;
}

.hover_item .tooltiptext {
  visibility: hidden;
  background-color: white;
  box-shadow: 0px 1px 1px 0px rgb(145, 144, 144);
  color: black;
  text-align: center;
  font-size: 1.8vh;
  border-radius: 6px;
  height: 22px;
  width: 150px;
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  position: absolute;
  z-index: 5;
}

.hover_item:hover .tooltiptext {
  visibility: visible;
  transform: scale(1.05);
  transition: 0.2s ease-in-out;
}

h1 {
  color: white;
  font-size: 2em;
  font-weight: 800;
  margin-bottom: 0px;
  text-shadow: 2px 2px 2px black;
}

.overview {
  color: white;
  font-size: 16px;
  text-shadow: 2px 2px 2px black;
}

.main_col {
  width: 100%;
  padding-left: 5%;
  padding-right: 3%;
  text-align: left;
  animation: title-animation 1s ease;
  min-height: 300px;
  cursor: pointer;
}

.featured_community {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 50vh;
  animation: 0.3s ease-in-out;
}

.featured_community:hover {
  transform: scale(1.01);
  transition: 0.3s ease-in-out;
}

.featured_community:not(hover) {
  transition: 0.3s ease-in-out;
}

.left {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 40%;
  border-radius: 8px;
  animation: featured-anim 20s ease-in infinite;
  overflow: hidden;
  cursor: pointer;
}

.right {
  display: flex;
  flex-direction: column;
  width: 60%;
  border-radius: 12px;
}

.featured_title {
  background-color: #f8b632;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  left: 10px;
  height: 30px;
  width: 120px;
  border-radius: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

@keyframes title-animation {
  from {
    transform: translateY(-500px);
  }

  to {
    transform: translateY(-0px);
  }
}

@keyframes movie-info-anim {
  from {
    transform: translateY(20px);
  }

  to {
    transform: translateY(-10px);
  }
}

.community_statistic {
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  width: 70%;
  border-radius: 6px;
  gap: 5px;
}

.info_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10%;
}

.ic {
  width: 28px;
}


@media (max-width: 35em) {
  @keyframes anim-in {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  @keyframes anim-out {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(100%);
    }
  }
}

@keyframes featured-anim {

  0%,
  100% {
    background-position: center;
  }

  50% {
    background-position: top;
  }
}

@media screen and (max-width: 768px) {

  .featured_community {
    flex-direction: column;
    width: 100%;
  }

  .left {
    width: 100%;
    min-height: 250px;
    border-radius: 0px;
    align-items: center;
    justify-content: center;
  }

  .right {
    width: 100%;
  }

  .main_col {
    padding: 0.4rem 0.4rem;
    min-height: auto;
  }

  .community_statistic {
    padding: 0rem;
    width: 95%;
    border-radius: 2px;
    gap: 5px;
  }

}
</style>
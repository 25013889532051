<template>
    <div class="select-menu" @click="toggleDropdown">
        <div class="select-btn">
            <span class="sBtn-text">{{ selectedItem }}</span>
            <div class="arrow_drop" :class="{ 'up': isDropdownOpen, 'down': !isDropdownOpen }"></div>
        </div>
        <ul @mouseleave="isDropdownOpen = false" class="options" v-show="isDropdownOpen"
            :class="{ active: isDropdownOpen }">
            <li v-for="(item, index) in options" :key="index" class="option" @click="setFunction($event, item)">
                <span class="option-text">{{ item.option }}</span>
            </li>
        </ul>
    </div>
</template>
    
<script>
export default {
    props: {
        selectedItem: String,
        options: Array,
        refFunction: Function
    },
    data() {
        return {
            isDropdownOpen: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        setFunction(event, item) {
            event.stopPropagation();
            this.isDropdownOpen = false;
            this.refFunction(item);
        },
    },
};
</script>
  
<style scoped>
.sBtn-text {
    color: #f8b632;
    font-size: 1.8vh;
    font-weight: 600;
}

.select-menu {
    width: 200px;
}

.select-menu .select-btn {
    display: flex;
    height: 44px;
    background: black;
    padding: 20px;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border: 1px #f8b632 solid;
    box-shadow: 0 0 10px #f8b632;
    font-size: 1.8vh;
    font-weight: 500;
    color: #333;
}

.select-menu .options {
    position: absolute;
    padding: 5px;
    width: 200px;
    margin-top: 5px;
    border-radius: 4px;
    background: black;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    transition: transform 0.3s ease-in-out;
}

.select-menu.active .options {
    display: block;
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    box-shadow: 0 0 3px rgba(173, 77, 77, 0.5);
}

.options .option {
    display: flex;
    height: 40px;
    cursor: pointer;
    padding: 0 16px;
    border-radius: 4px;
    align-items: center;
    background: black;
}

.options .option:hover {
    background-color: #f8b632;
}

.options .option:hover .option-text {
    color: white;
}


.option img {
    height: 25px;
    width: 25px;
}

.option .option-text {
    font-size: 1.8vh;
    font-weight: 600;
    color: rgb(248, 182, 50);
}

.arrow_drop {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px #f8b632 solid;
    border-left: 2px #f8b632 solid;
    transition: transform 0.3s ease-in-out;
}

.arrow_drop.down {
    transform: rotate(-135deg);
}

.arrow_drop.up {
    transform: rotate(45deg);
}

@media (max-width: 599px) {
    .select-menu {
        width: 100%;
    }

    .select-menu .select-btn {
        display: flex;
        height: 35px;
    }

    .option .option-text {
        font-size: 12px;
        font-weight: 500;
        color: rgb(248, 182, 50);
    }

    .sBtn-text {
        color: #f8b632;
        font-size: 1.7vh;
    }

    .select-menu .options {
        width: 130px;
    }

    .select-menu .select-btn {
        padding: 18px;
    }
}
</style>
    
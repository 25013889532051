import { apiInstance } from '../services/ApiService.js'

const API_URL = '/movie'

const getMovieById = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + '/id', {
      params: {
        movie_id: req.movie_id,
        user_id: req.user_id,
      }
    })
    if (!response.data.success) return []
    return response.data.movie
  } catch (error) {
    return null
  }
}

const getMovies = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + '/all', {
      params: {
        limit: req.limit,
        order_by: req.order_by,
        order_dir: req.order_dir
      }
    })
    if (!response.data.success) return []
    return response.data.movies
  } catch (error) {
    return []
  }
}

const getSimilarMovies = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + '/similar', {
      params: {
        movie_id:req.movie_id,
        limit: req.limit,
        order_by: req.order_by,
        order_dir: req.order_dir,
        genres: req.genres
      }
    })
    if (!response.data.success) return []
    return response.data.movies
  } catch (error) {
    return []
  }
}

async function getUserInteresMovies(){
  try {
    const response = await apiInstance.get(API_URL + '/interest', {
    })
    if (!response.data.success) return []
    return response.data.movies
  } catch (error) {
    return []
  }
}

async function getUserInterestCategories(){
  try {
    const response = await apiInstance.get(API_URL + '/category', {
    })
    if (!response.data.success) return []
    return response.data.genres
  } catch (error) {
    return []
  }
}

const getLikedMovies = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + '/liked', {
      params: {
        limit: req.limit,
        order_by: req.order_by,
        order_dir: req.order_dir,
        user_id: req.user_id
      }
    })
    if (!response.data.success) return []
    return response.data
  } catch (error) {
    return []
  }
}

const getMoviesAdvanced = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + '/get', {
      params: {
        limit: req.limit,
        page: req.page,
        order_by: req.order_by,
        order_dir: req.order_dir,
        q: req.search,
        genres: req.genres,
        imdb: req.imdb,
        meta: req.meta,
        category: req.category,
        language: req.language
      }
    })
    if (!response.data.success) return []
    return response.data.movies
  } catch (error) {
    return []
  }
}

const likeMovie = async (req) => {
  try {
    const response = await apiInstance.get(API_URL + '/like', {
      params: {
        movie_id: req.movie_id,
        user_id: req.user_id,
      }
    })
    if (!response.data.success) return false
    return response.data.success
  } catch (error) {
    return false;
  }
}

const watchedMovie = async (req) => {
  try {
    const response = await apiInstance.post(API_URL + '/watched', {
      movie_id: req.movie_id,
      user_id: req.user_id,
    })
    if (!response.data.success) return false
    return response.data.success
  } catch (error) {
    return false;
  }
}

const getWatchedMovies = async (req) => {

  try {

    const response = await apiInstance.get(API_URL + '/watched', {
      params: {
        user_id: req.user_id,
        order_dir: req.order_dir,
        limit: req.limit
      }
    })
    if (!response.data.success) return false
    return response.data;
  } catch (error) {
    return false;
  }
}


const getMovieUserInfo = async (req) => {

  try {

    const response = await apiInstance.get(API_URL + '/user', {
      params: {
        movie_id: req.movie_id,
      }
    })
    if (!response.data.success) return false
    return response.data;
  } catch (error) {
    return false;
  }
}


const shareComment = async (req) => {

  try {

    const response = await apiInstance.post(API_URL + '/comment', {
      movie_id: req.movie_id,
      user_id: req.user_id,
      content: req.content,
    });

    return response.data.success;
  } catch (error) {
    return false
  }
}

const MovieService = {
  getMoviesAdvanced,
  getMovies,
  getMovieById,
  likeMovie,
  getLikedMovies,
  watchedMovie,
  getWatchedMovies,
  shareComment,
  getMovieUserInfo,
  getSimilarMovies,
  getUserInteresMovies,
  getUserInterestCategories
}

export default MovieService

<template>
    <div class="user_movie_element" @click="this.$emit('goMovie', item.movie_id)">
        <img class="prevent-user-img bg_poster" :src="getImageUrl(item.movie.poster)" />
        <div class="movie-info">
            <div class="f align-center" style="justify-content: center">
                <span class="small-title-white">{{ item.title }}</span>
            </div>
            <div class="f justify-center">
                <span class="yellow_title">{{ item.movie.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showedCount: 0,
        }
    },
    methods: {
        getImageUrl(imageUrl) {
            let str = imageUrl;
            if (imageUrl.includes('@.')) {
                const startIndex = imageUrl.indexOf('@.')
                str = imageUrl.slice(0, startIndex + 1)
                str += '.jpg';
            }

            return str
        },
        deleteMovie() {
            return this.$emit('deleteMovie', this.item.movie_id);
        },
        goRoute() {
            return this.$emit('goMovie', this.item.movie_id);
        },
    },
    mounted() { },
    created() { },
    components: {}
}
</script>

<style scoped>
.small-icon {
    width: 28px;
}

.user_movie_element {
    height: 100%;
    width: 100%;
    background-color: white;
    position: relative;
    border-radius: 3px;
}

.user_movie_element:hover {
    transform: scale(1.05);
    transition: 0.2s ease-in-out;
    z-index: 1;
}

.user_movie_element:not(:hover) {
    transform: scale(1.0);
    transition: 0.2s ease-in-out;
    z-index: 1;
}

.user_movie_element:hover img {
    transition: 0.2s ease-in-out;
    border-radius: 0px;
}

.movie-info {
    position: absolute;
    bottom: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
    font-size: 20px;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    border-radius: 0;
}

.bg_poster {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.small-font-white {
    font-size: 12px;
    color: white;
}

.yellow_title {
    font-size: 14px;
    font-weight: 600;
    color: #f8b632;
}


@media screen and (max-width: 500px) {
    .yellow_title {
        font-size: 11.5px;
    }

    .user_movie_element {
        height: 100%;
        width: 100%;
        background-color: white;
        position: relative;
        border-radius: 6px;
    }
}
</style>
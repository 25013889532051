import { apiInstance } from './ApiService.js'

const API_URL = '/post-comment'

const getComments = async (req) => {
    try {
        const response = await apiInstance.get(API_URL + '/all', {
            params: {
                limit: req.limit,
                order_by: req.order_by,
                order_dir: req.order_dir,
                community_id: req.community_id,
                post_id: req.post_id
            }
        })
        if (!response.data.success) return []
        return response.data.comments
    } catch (error) {
        return [];
    }
}

const getCommentsByUserId = async (req) => {

    try {
        
        const response = await apiInstance.get(API_URL + '/user/all', {
            params: {
                limit: req.limit,
                order_by: req.order_by,
                order_dir: req.order_dir,
                user_id: req.user_id
            }
        })
        if (!response.data.success) return []
        return response.data
    } catch (error) {
        return [];
    }
}

const createComment = async (req) => {

    try {

        const response = await apiInstance.post(API_URL + '/', {
            community_id: req.community_id,
            user_id: req.user_id,
            post_id: req.post_id,
            content: req.content
        });

        return response.data.success;
    } catch (error) {
        return false;
    }
}

const voteComment = async (req) => {

    try {

        const response = await apiInstance.get(API_URL + '/vote', {
            params: {
                community_id: req.community_id,
                user_id: req.user_id,
                post_id: req.post_id,
                comment_id: req.comment_id,
                vote: req.vote
            }
        });

        return response.data.success;
    } catch (error) {
        return false;
    }
}

const PostCommentService = {
    getComments,
    getCommentsByUserId,
    createComment,
    voteComment
}

export default PostCommentService
<template>
    <div class="profile-personal-info-wrapper">
        <div class="profile-personal-info">
            <Chair :item="item.username" />
            <div class="user-rank">
                <div class="f align-center g5">
                    <div class="rank-name">{{ item.rank.name }}</div>
                    <img v-if="item.rank.badge" style="height: 30px; width: 30px" :src="item.rank.badge" />
                </div>
                <div class="rank-bar"></div>
            </div>
            <div @click="this.$router.push('settings')" class="f align-center g5">
                <a class="profile-settings">Profil Ayarları</a>
                <VueIcon icon="material-symbols:settings-outline" width="25px" style="color: white"></VueIcon>
            </div>
        </div>
    </div>
</template>

<script>
import { getFormattedDate } from '../../utils/DateUtils.js';
import Chair from '../../components/User/UserChair.vue'

export default {
    data() {
        return {
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        getDate() {
            return getFormattedDate(this.item.created_time)
        }
    },
    async created() { },
    components: { Chair }
}
</script>

<style scoped>
.profile-personal-info-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    background: black;
    width: 100%;
    margin-top: 50px;
}

.profile-personal-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1A1A1B;
    width: 100%;
    color: white;
    border-radius: 4px;
    padding: 1rem 1rem;
}

.profile-settings {
    cursor: pointer;
    font-size: 15px;
}

.profile-settings:hover {
    transition: 0.2s;
    color: #f8b632;
}

.user-rank {
    position: relative;
    bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 30%;
    width: 80%;
}

.rank-name {
    font-size: 1.2rem;
}

.rank-bar {
    border-radius: 4px;
    width: 100%;
    height: 10px;
    margin-top: 5px;
    background: linear-gradient(to right, #f8b632, rgb(255, 132, 2));
    animation: rank-bar-animation 2s ease-in-out infinite;

}

@keyframes rank-bar-animation {

    0%,
    100% {
        background-size: 150% 100%;
        background-position: 0 0;
    }

    50% {
        background-size: 150% 100%;
        background-position: 80% 0;
    }
}




.user_card {
    background-color: #1A1A1B;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1.5rem;
    border: 1px rgb(146, 146, 146) solid;
    border-radius: 16px;
    display: flex;
    gap: 20px;
    width: 100%;
}

.user_card p {
    color: white;
    text-align: left;
}

.user_card h3,
h4 {
    font-weight: 700;
    color: rgb(170, 165, 165);
}

.bottom_info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.cicle_avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: white;
}

.cicle_avatar img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: white;
}


@media screen and (max-width: 768px) {
    .profile-personal-info-wrapper {
        margin-top: 0;
    }
}
</style>
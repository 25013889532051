<template>
    <div class="create_post">
        <div class="post_avatar">
            <img v-if="avatar" class="post_avatar" :src="avatar" />
            <img v-else class="post_avatar" src="../../assets/images/avatar.png" />
        </div>
        <div class="text_div">
            <textarea class="2" style="font-size: 12pt" v-model="newComment" rows="15" required cols="2" placeholder="Yorumunu paylaş." />
            <div class="end">
                <div>
                    <button @click="submit" class="custom_button">Paylaş</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newComment: ''
        }
    },
    props: {
        avatar: {
            type: String,
            required: true
        }
    },
    methods: {
        submit() {
            if (this.newComment === "") return;
            return this.$emit('comment-submitted', this.newComment)
        }
    },
}
</script>

<style scoped>
.custom_button {
    align-items: center;
    appearance: none;
    background-color: #f8b632;
    background-image: none;
    background-size:none;
    border-radius: 4px;
    border-width: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: inline-flex;
    font-family: CircularStd, sans-serif;
    font-size: 1rem;
    height: auto;
    justify-content: center;
    line-height: 1.5;
    padding: 6px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color .2s, background-position .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
}

.custom_button:active,
.custom_button:focus {
    outline: none;
}

.custom_button:hover {
    background-position: -20px -20px;
}

.custom_button:focus:not(:active) {
    box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

textarea {
    resize: none;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.text_div {
    display: flex;
    flex-direction: column;
    gap: 4%;
    align-items: flex-start;
    width: 100%;
    height: 150px;
    padding: 0.6rem;
    border-radius: 4px;
}

.create_post {
    display: flex;
    align-items: center;
    gap: 1%;
    width: 100%;
    height: 180px;
    padding: 1rem;
    background-color: #1a1a1b;
    border: 1px rgb(58 58 58) solid;
    border-radius: 6px;
}

.end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}

.post_section {
    border: 1px rgb(146, 146, 146) solid;
    width: 90%;
    height: 100%;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-left: 1%;
    cursor: pointer;
    color: white;
    background-color: #1a1a1b;
}

.post_avatar {
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: white;
}

@media screen and (max-width: 920px) {
    .post_avatar{
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: white;
}
}
</style>
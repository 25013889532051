<template>
    <div class="shimmer-container">
        <div class="shimmer-img">
        </div>
        <div class="second">
            <div class="shimmer-mid"></div>
        </div>
    </div>
</template>
  
<style scoped>
.shimmer-container {
    border-radius: 3px;
    height: 100%;
    background: #21242c;
    padding: 0.6rem 0.6rem;
    position: relative;
    transition: 0.3s;
    overflow: hidden;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.shimmer-img {
    border-radius: 3px;
    height: 90%;
    width: 100%;
    background: linear-gradient(90deg, transparent, #616060, transparent);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}


.shimmer-mid {
    border-radius: 3px;
    height: 30px;
    width: 100%;
    background: linear-gradient(90deg, transparent, #5c5c5c, transparent);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

@media only screen and (max-width:745px) {
    .second {
        display: none;
    }


    .shimmer-img {
        height: 60px;
        width: 60px;
    }

}

@keyframes shimmer {
    to {
        background-position: 200% 0;
    }
}
</style>
  